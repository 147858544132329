import React, { FC, memo, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs/operators';
import { Locale } from '@mydse/typings';
import { languageList, userService } from '@services';

import styles from './LanguageToggle.styl';

const LanguageToggle: FC = (): null | ReactElement => {
  const { t, i18n } = useTranslation();

  const [ languageCode, setLanguageCode ] = useState('');
  const [ flag, setFlag ] = useState('');
  const delimiter = '-';
  const codeFlagMap = {
    en: 'gb',
    de: 'de'
  };

  useEffect(() => {
    let currentLanguageCode = i18n.language;
    if (currentLanguageCode && currentLanguageCode.indexOf(delimiter) !== -1) {
      currentLanguageCode = currentLanguageCode.split(delimiter)[0];
    }
    const code = languageList.filter((item: string) => item !== currentLanguageCode)[0] as Locale;
    setLanguageCode(code);
    setFlag(codeFlagMap[code]);
  }, [ t, i18n.language ]);

  const changeLanguage = (): void => {
    if (languageCode) {
      userService.updateLanguage(languageCode)
        .pipe(take(1))
        .subscribe();
    }
  };

  return (
    <div className={styles.toggle} onClick={changeLanguage}>
      <span className={`${ styles.flag } country-flag flag-icon flag-icon-${ flag }`} />
      <span className={styles.text}>{t(`language.${ languageCode }`)}</span>
    </div>
  );
};

export default memo(LanguageToggle);
