import { useEffect } from 'react';
import { Subject } from 'rxjs';
import { modalService } from '@mydse/react-ui';
import { useCurrentCompany, useIsMultiProduct, useIsMultitenancy } from '@hooks';
import { openModalParameterName, RouteState } from '@router';
import { productService } from '@services';
import { useProductSwitcherModalOptions } from '@layout/AppLayout/components/ProductSwitcherButton/hooks';

export type UseOpenModalParameterHandlingOptions = {
  route: RouteState;
};

export const useOpenModalParameterHandling = (options: UseOpenModalParameterHandlingOptions): void => {
  const { route } = options;
  const unsubscribe$ = new Subject<void>();

  const { modalOptions } = useProductSwitcherModalOptions({
    id: 'productSwitcherModal',
    currentCompany: useCurrentCompany(unsubscribe$),
    isMultiProduct: useIsMultiProduct(unsubscribe$),
    isMultitenancy: useIsMultitenancy(unsubscribe$)
  });
  useEffect(
    (): void => {
      if (!route) {
        return;
      }
      const parameters = route?.params || {};
      const openModal = parameters[openModalParameterName];
      if (openModal === 'productSelection' && productService.getIsMultiProduct()) {
        modalService.show(modalOptions);
        delete route.params.openModal;
      }
    },
    [ route?.params ]
  );
};
