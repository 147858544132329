import React, { FC, HTMLAttributes, memo, ReactElement } from 'react';
import { Link } from 'react-router5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { classNameArrayToString, Ellipsis, useDataAttributes } from '@mydse/design-system';
import { useLobbyRedirect } from '@hooks';
import { isExternalRoute } from '@router';
import { FullRouteListType } from '@router/configuration';

import styles from './HorizontalMenuItem.styl';

interface IOwnProps {
  icon?: IconDefinition;
  name: string;
  routeName: FullRouteListType;
  disabled?: boolean;
  isActive?: boolean;
  className?: string;
}

export type HorizontalMenuItemData = IOwnProps & HTMLAttributes<HTMLDivElement>;

const HorizontalMenuItem: FC<HorizontalMenuItemData> = ({
  icon,
  name,
  routeName,
  isActive,
  disabled,
  className,
  ...properties
}): null | ReactElement => {
  const { dataAttributes } = useDataAttributes({
    test: { 'horizontal-menu': routeName },
    guide: { 'horizontal-menu': routeName }
  });

  const itemIcon = icon
    ? <FontAwesomeIcon icon={ icon } />
    : null;

  const nameElement = (
    <Ellipsis
      className={ styles.text }
      singleLine={ true }
    >
      { name }
    </Ellipsis>
  );

  const classNameString = classNameArrayToString([
    'no-select',
    styles.menuItem,
    ...[ isActive && styles.active ],
    ...[ disabled && styles.disabled ],
    className
  ]);

  const linkContent = (
    <span className={ styles.item }>
      { itemIcon }
      { nameElement }
    </span>
  );

  const { redirect } = useLobbyRedirect();
  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    redirect({ route: routeName });
  };

  const linkItem = isExternalRoute(routeName)
    ? (
      <a
        className={ styles.link }
        data-link
        onClick={ onClick }
        { ...dataAttributes }
      >
        { linkContent }
      </a>
    )
    : (
      <Link
        className={ styles.link }
        data-link
        routeName={ routeName }
        { ...dataAttributes }
      >
        { linkContent }
      </Link>
    );

  return (
    <nav
      className={ classNameString }
      { ...properties }
    >
      { linkItem }
    </nav>
  );
};

export default memo(HorizontalMenuItem);
