import React from 'react';
import { CellProps } from 'react-table';
import { TFunction } from 'react-i18next';
import { Ellipsis } from '@mydse/design-system';
import { BusinessPremise } from '@services';

import styles from './BusinessPremiseNameCell.styl';

export interface IBusinessPremiseNameOptions {
  t: TFunction;
  className?: string;
  singleLine?: boolean;
  maxLength?: number;
}

export const BusinessPremiseNameCell = (options: IBusinessPremiseNameOptions) => (
  props: CellProps<BusinessPremise>
) => {
  const { t, className, singleLine, maxLength } = options;
  const businessPremiseMainElement = props.cell.row.original.main
    ? <span className={styles.main}>{t('table.heading.BUSINESS_PREMISE.mainFacility')}</span>
    : null;
  return <Ellipsis
    className={className}
    singleLine={singleLine}
    maxLength={maxLength}
  >
    {props.cell.value} {businessPremiseMainElement}
  </Ellipsis>;
};
