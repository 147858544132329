import { loadingIndicatorService, QueryStringValue } from '@mydse/design-system';
import { getLobbyUrl } from '@router';
import { userService } from '@services';

export type RedirectToLobbyOptions = {
  route?: string;
  parameters?: Record<string, QueryStringValue>;
};

const redirect = (lobbyUrl: string) => {
  window.location.href = lobbyUrl;
};

export const redirectToLobby = (options: RedirectToLobbyOptions = {}): void => {
  const lobbyUrl = getLobbyUrl(options);
  const { route } = options;
  loadingIndicatorService.show();
  if (!route || route === 'login') {
    userService
      .logout()
      .subscribe(
        () => redirect(lobbyUrl),
        () => redirect(lobbyUrl)
      );
  } else {
    redirect(lobbyUrl);
  }
};
