import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterValue, Row } from 'react-table';
import { User } from '@mydse/typings';
import {
  EnumDropdown,
  Calendar,
  CalendarOptions,
  DateRangePickerValue,
  ReturnValue
} from '@mydse/design-system';
import {
  companyService,
  IActivity,
  SystemEvent
} from '@services';
import { UserDropdown } from '@shared/components';
import { templateSelection } from './templateSelection';
import { templateResult } from './templateResult';

import styles from './ManualFilter.styl';

export interface IActivitiesGlobalFilter {
  systemEvent?: SystemEvent;
  userId?: number;
  affectedUserId?: number;
  from?: null | number | string;
  to?: null | number | string;
}

interface IOwnProps {
  globalFilter: FilterValue;
  setGlobalFilter: (filterValue: FilterValue) => void;
  preGlobalFilteredRows?: Array<Row<IActivity>>;
}

type Props = IOwnProps;

const ManualFilter: FC<Props> = ({
  globalFilter,
  setGlobalFilter
}) => {
  const namespace = 'analytics';
  const { t } = useTranslation(namespace);

  const minimalDate = 1577836800000;
  const fromDate = new Date(companyService.company$.value?.createdAt || minimalDate);
  fromDate.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(23, 59, 59, 999);

  const datePickerSettings: CalendarOptions = {
    singleDate: false,
    utc: true,
    allowSingleDate: true,
    shortMode: true,
    showResetButton: true,
    minDate: fromDate,
    maxDate: today,
    returnFormat: 'YYYY-MM-DDTHH:mm:ss',
    defaultTime: {
      from: '00:00:00',
      to: '23:59:59'
    }
  };

  const settings = {
    minimumResultsForSearch: 1,
    templateSelection
  };

  const initialState: IActivitiesGlobalFilter = {
    systemEvent: (globalFilter && globalFilter.systemEvent) || undefined,
    userId: (globalFilter && globalFilter.userId) || undefined,
    affectedUserId: (globalFilter && globalFilter.affectedUserId) || undefined,
    from: null,
    to: null
  };

  const state = useRef<IActivitiesGlobalFilter>(initialState);
  const userDate = useRef<number>(minimalDate);
  const affectedUserDate = useRef<number>(fromDate.getTime());

  const [ dateSettings, setDateSettings ] = useState<CalendarOptions>(datePickerSettings);

  const updateFilter = (value: Partial<IActivitiesGlobalFilter>) => {
    state.current = {
      ...state.current,
      ...value
    };
    setGlobalFilter(state.current);
  };

  const updateDateSettings = () => {
    const minDateTimestamp = Math.max(
      fromDate.getTime(),
      userDate.current,
      affectedUserDate.current
    );
    const minDate = new Date(minDateTimestamp).setHours(0, 0, 0, 0);
    if (dateSettings.minDate !== minDate) {
      setDateSettings({
        ...datePickerSettings,
        minDate
      });
    }
  };

  const onChangeSystemEvent = (event: ChangeEvent<HTMLSelectElement>) => {
    const systemEvent = (event.target.value as SystemEvent) || undefined;
    updateFilter({ systemEvent });
  };
  const onChangeUserId = (user?: User): void => {
    const userId = user?.id;
    userDate.current =
      user && user.createdAt
        ? new Date(user.createdAt).getTime()
        : minimalDate;
    updateDateSettings();
    updateFilter({ userId });
  };
  const onChangeAffectedUserId = (user?: User) => {
    const affectedUserId = user?.id;
    affectedUserDate.current =
      user && user.createdAt
        ? new Date(user.createdAt).getTime()
        : minimalDate;
    updateDateSettings();
    updateFilter({ affectedUserId });
  };

  const onChangeDate = (date: ReturnValue) => {
    updateFilter(date as DateRangePickerValue<null | number | string>);
  };

  const dateValue = {
    from: state.current.from || null,
    to: state.current.to || null
  };

  return (
    <tr className={styles.filterRow}>
      <td>
        <EnumDropdown
          name="actionTypes"
          t={t}
          dataAttributesDictionary={{
            test: { activities: 'activity' },
            guide: { activities: 'activity' }
          }}
          label={t('table.filter.systemEvent.label')}
          translationKey="systemEvent"
          placeholder={t('table.filter.systemEvent.placeholder')}
          allowClear={true}
          useFormGroup={false}
          enumData={SystemEvent}
          settings={settings}
          value={state.current.systemEvent}
          useTemplateResult={templateResult}
          onChange={onChangeSystemEvent}
        />
      </td>
      <td>
        <UserDropdown
          namespace={namespace}
          name="user"
          label={t('table.filter.userId.label')}
          placeholder="table.filter.userId.placeholder"
          value={state.current.userId}
          dataAttributesDictionary={{
            test: { activities: 'user' },
            guide: { activities: 'user' }
          }}
          allowSelectBlockedUser={true}
          onChangeObject={onChangeUserId}
        />
      </td>
      <td>
        <UserDropdown
          namespace={namespace}
          name="affectedUser"
          label={t('table.filter.affectedUserId.label')}
          placeholder="table.filter.affectedUserId.placeholder"
          value={state.current.affectedUserId}
          dataAttributesDictionary={{
            test: { activities: 'affectedUser' },
            guide: { activities: 'affectedUser' }
          }}
          allowSelectBlockedUser={true}
          onChangeObject={onChangeAffectedUserId}
        />
      </td>
      <td>
        <Calendar
          name="date"
          label={t('table.filter.date.label')}
          placeholder={t('table.filter.date.placeholder')}
          useFormGroup={false}
          settings={dateSettings}
          dataAttributesDictionary={{
            test: { activities: 'calendar' },
            guide: { activities: 'calendar' }
          }}
          isSingle={false}
          value={dateValue}
          onChange={onChangeDate}
        />
      </td>
    </tr>
  );
};

export default ManualFilter;
