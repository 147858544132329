import { Collection } from '@mydse/typings';
import { IDictionaryResponse } from '@interfaces';
import { IQuestionDropdown } from '@shared/surveyJs/widgets/Dropdown/dropdown.widget';

const processResults = (question: IQuestionDropdown) => (response: Collection<IDictionaryResponse<string>>) => {
  if (typeof question.cachedChoices === 'undefined') {
    question.cachedChoices = {};
  }
  const results = response.elements
    .map((item: IDictionaryResponse<string>) => {
      const { id, title: text } = item;
      question.cachedChoices[id] = text;
      return {
        id,
        text
      };
    });
  return {
    results,
    pagination: {
      more: response.currentPage < Math.ceil(response.totalResult / response.pageSize)
    }
  };
};

export default processResults;
