import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivationFn, State } from 'router5';
import { useRoute } from 'react-router5';
import { Button } from '@mydse/design-system';
import { modalHandler, modalService } from '@mydse/react-ui';
import { redirect } from '@router';
import { userService } from '@services';
import { useClearLeavePageHandler } from '@hooks/CancelSurvey/useClearLeavePageHandler';
import { CancelSurveyModal } from './components';
import { defaultUseHandlerFn } from './constants';
import { IUseCancelSurveyOptions } from './typings';

export const useCancelSurvey = (options: IUseCancelSurveyOptions) => {
  const {
    redirectName,
    redirectParams,
    isReadonly = false,
    useHandlerFn = defaultUseHandlerFn
  } = options;

  const { t } = useTranslation();
  const { router } = useRoute();
  const routeName = router.getState().name;

  const canDeactivateHandler = (): ActivationFn => (toState: State): boolean => {
    if (!useHandlerFn()) {
      return true;
    }
    const showModal = cancelModalFactory(() => {
      clearLeavePageHandler();
      const callback = toState.meta?.params.callback;
      typeof callback === 'undefined'
        ? redirect(toState.name, toState.params)
        : callback();
    });
    showModal();
    return false;
  };

  const setLeavePageHandler = (): void => {
    if (!isReadonly) {
      router.canDeactivate(routeName, canDeactivateHandler);
    }
  };

  const { clearLeavePageHandler } = useClearLeavePageHandler(isReadonly);

  const redirectFunction = (): void => {
    clearLeavePageHandler();
    redirect(redirectName, redirectParams);
  };

  const cancelModalFactory = (redirectFn: () => void) => (): void => {
    const isLoggedIn = userService.isLoggedInSubject$.value;
    if (!isLoggedIn) {
      redirectFn();
      return;
    }
    modalService.show({
      id: 'surveyCancel',
      heading: {
        title: t('survey.cancel')
      },
      content: {
        contentElement: <CancelSurveyModal/>
      },
      footer: {
        actionsElement: (
          <>
            <Button
              preset="default"
              dataAttributesDictionary={ {
                test: { 'button-modal': 'stay' },
                guide: { 'survey': 'stayButton' }
              } }
              text={ t('common:survey.button.stay') }
              onClick={ modalHandler() }
            />
            <Button
              preset="primary"
              dataAttributesDictionary={ {
                test: { 'button-modal': 'leave' },
                guide: { 'survey': 'leaveButton' }
              } }
              text={ t('common:survey.button.leave') }
              onClick={ modalHandler(redirectFn) }
            />
          </>
        )
      }
    });
  };

  const cancelCallback = isReadonly
    ? redirectFunction
    : cancelModalFactory(redirectFunction);

  setLeavePageHandler();

  return { cancelCallback, setLeavePageHandler, clearLeavePageHandler };
};
