import { Observable, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ICollection } from '@mydse/design-system';
import DropdownWidget, { IQuestionDropdown } from './dropdown.widget';
import {
  allowClearProperty,
  dropdownAutoWidthProperty,
  labelProperty,
  maxSelectedChoicesProperty,
  minimumResultsForSearchProperty,
  optionsCaptionProperty,
  select2ConfigProperty,
  selectAllTextProperty,
  showPlaceholderProperty,
  apiUrlProperty,
  pageSizeProperty
} from './properties';
import {
  apiListMapper as dictionaryDataMapper,
  getApiSelect2Options as getSelect2Config
} from './utilities';
import { dictionaryService, SystemsEntity } from '../compatibility';
import { IProperty, IWidgetConfiguration } from '../interfaces';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';

import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { faCogs } from '@fortawesome/pro-regular-svg-icons';
import './styles/api.styl';

const ApiDropdown = ($: JQueryStatic): void => {
  const singleName: string = 'apidropdown';
  const multipleName: string = 'apidropdownmultiple';
  const customPropertiesList: IProperty[] = [
    showPlaceholderProperty,
    optionsCaptionProperty,
    allowClearProperty,
    labelProperty,
    apiUrlProperty,
    pageSizeProperty,
    select2ConfigProperty,
    dropdownAutoWidthProperty
  ];
  const singleCustomPropertiesList: IProperty[] = [
    minimumResultsForSearchProperty
  ];
  const multipleCustomPropertiesList: IProperty[] = [
    selectAllTextProperty,
    maxSelectedChoicesProperty
  ];
  const hidePropertyNameList: string[] = [
    'autoComplete',
    'allowAddNewTag',
    'select2Config',
    'choicesByUrl',
    'choices',
    'url',
    'choicesMin',
    'choicesMax',
    'choicesStep',
    'choicesOrder',
    'choicesFromQuestion',
    'hasOther',
    'otherText',
    'otherPlaceHolder',
    'hasNone',
    'noneText',
    'hasSelectAll',
    'selectAllText',
    'otherErrorText'
  ];
  const singleHidePropertyNameList: string[] = [
    ...hidePropertyNameList
  ];
  const multipleHidePropertyNameList: string[] = [
    ...hidePropertyNameList,
    'colCount'
  ];
  const classNameDictionary = {
    wrap: 'p360-api-dropdown-wrap',
    select: 'p360Select',
    textarea: 'p360Textarea',
    textareaWrap: 'question-other-label-wrap',
    searchField: 'select2-search__field'
  };
  const editorLocalization = {
    p: {
      apiUrl: {
        en: 'API URL',
        de: 'URL-API'
      }
    },
    pe: {
      optionsCaption: {
        en: 'Placeholder',
        de: 'Platzhalter'
      },
      showPlaceholder: {
        en: 'Show placeholder',
        de: 'Platzhalter anzeigen'
      },
      dropdownAutoWidth: {
        en: 'Dropdown auto width',
        de: 'Automatische Dropdown-Breite'
      },
      minimumResultsForSearch: {
        en: 'Minimum results for search',
        de: 'Minimale Ergebnisse für die Suche'
      }
    }
  };
  const singleEditorLocalization = {
    qt: {
      [singleName]: {
        en: 'API dropdown single',
        de: 'API-Dropdown-Liste Single'
      }
    }
  };
  const multipleEditorLocalization = {
    qt: {
      [multipleName]: {
        en: 'API dropdown multiple',
        de: 'API-Dropdown-Liste Multiple'
      }
    }
  };
  const dictionaryDataSource = (
    question: IQuestionDropdown
  ): Observable<SystemsEntity[]> => {
    if (question.survey.isDesignMode) {
      return of([]);
    }
    const url: undefined | string = typeof question?.settings?.ajax?.url === 'function'
      ? question?.settings?.ajax?.url({})
      : question?.settings?.ajax?.url;
    const value = Array.isArray(question.value)
      ? question.value
      : [ question.value ];
    return url
      ? dictionaryService
        .getApiData(
          url,
          {
            pageSize: value.length || 1,
            ids: value
          }
        )
        .pipe(
          take(1),
          map((response: ICollection<SystemsEntity>) => response.elements)
        )
      :
      of([] as SystemsEntity[]);
  };
  const singleDefaultJSON = {
    type: singleName,
    choices: []
  };
  const multipleDefaultJSON = {
    type: multipleName,
    choices: []
  };
  const singleConfiguration: IWidgetConfiguration = {
    name: singleName,
    iconName: singleName,
    icon: faCog,
    customPropertiesList: [ ...customPropertiesList, ...singleCustomPropertiesList ],
    hidePropertyNameList: singleHidePropertyNameList,
    classNameDictionary,
    editorLocalization: { ...singleEditorLocalization, ...editorLocalization },
    getSelect2Config,
    dictionaryDataSource,
    dictionaryDataMapper,
    defaultJSON: singleDefaultJSON,
    isMultiple: false,
    inheritClass: 'dropdownbase'
  };
  const multipleConfiguration: IWidgetConfiguration = {
    name: multipleName,
    iconName: multipleName,
    icon: faCogs,
    customPropertiesList: [ ...customPropertiesList, ...multipleCustomPropertiesList ],
    hidePropertyNameList: multipleHidePropertyNameList,
    classNameDictionary,
    editorLocalization: { ...multipleEditorLocalization, ...editorLocalization },
    getSelect2Config,
    dictionaryDataSource,
    dictionaryDataMapper,
    defaultJSON: multipleDefaultJSON,
    isMultiple: true,
    inheritClass: 'checkbox'
  };
  const singleWidget = new DropdownWidget($, singleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    singleWidget,
    singleWidget.activatedBy
  );
  const multipleWidget = new DropdownWidget($, multipleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    multipleWidget,
    multipleWidget.activatedBy
  );
};

export default ApiDropdown;
