import React, { FC, memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProperties, Ellipsis } from '@mydse/design-system';
import { modalService } from '@mydse/react-ui';
import { Company } from '@services';
import { useCompanySwitcherModalOptions } from './hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt, faRepeat } from '@fortawesome/pro-light-svg-icons';
import styles from './CompanySwitcherButton.styl';

interface OwnProps {
  currentCompany: null | Company;
  isMultiProduct: null | boolean;
  isMultitenancy: null | boolean;
  isModalActionButton?: boolean;
}

type Props = OwnProps & Omit<ButtonProperties, 'text' | 'disabled' | 'preset' | 'onClick' | 'className'>;

const CompanySwitcherButton: FC<Props> = ({
  currentCompany,
  isMultiProduct,
  isMultitenancy,
  isModalActionButton = false,
  ...properties
}): null | ReactElement => {
  const { t } = useTranslation();

  const { modalOptions } = useCompanySwitcherModalOptions({
    id: 'companySwitcherModal',
    currentCompany,
    isMultiProduct,
    isMultitenancy
  });

  if (!currentCompany) {
    return null;
  }

  const companyName = <Ellipsis singleLine={true}>{currentCompany.name}</Ellipsis>;

  const buttonContent = (
    <>
      {companyName}
      {currentCompany.draftMode && <FontAwesomeIcon icon={faPenAlt} />}
      {isMultitenancy && <FontAwesomeIcon icon={faRepeat} />}
    </>
  );

  const clickHandler = (): void => {
    modalService.show(modalOptions);
  };

  const title = t('companies.modalTitle');
  const dataTestValue = isModalActionButton
    ? { toggleModal: 'company-switcher' }
    : { companySwitcher: 'modal' };
  return (
    <Button
      {...properties}
      className={isModalActionButton ? styles.button : styles.modalButton}
      preset="default"
      text={buttonContent}
      title={title}
      useEllipsis={false}
      disabled={!isMultitenancy}
      onClick={clickHandler}
      dataAttributesDictionary={{
        test: dataTestValue,
        guide: dataTestValue
      }}
    />
  );
};

export default memo(CompanySwitcherButton);
