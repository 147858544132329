import { MouseEvent, useState } from 'react';
import { SortIndicatorDirection } from '@mydse/design-system';
import { SortItem, UseSorting, UseSortingOptions, UseSortingReturn } from './useSorting.typings';

export const useSorting: UseSorting = <T extends {}>(options: UseSortingOptions<T>): UseSortingReturn<T> => {
  const {
    useMultiSort = false,
    initialSorting,
    callback
  } = options;

  const [ sorting, setSorting ] = useState<SortItem<T>[]>(initialSorting);

  const getSortDirection = (sortField: keyof T): null | SortIndicatorDirection => {
    const sortingItem = sorting.find((item: SortItem<T>) => item.sortField === sortField);
    return sortingItem
      ? sortingItem.sortDirection
      : null;
  };

  const getNextSortDirection = (value: null | SortIndicatorDirection): null | SortIndicatorDirection => value === null
    ? 'asc'
    : value === 'asc'
      ? 'desc'
      : null;

  const applySorting = (value: SortItem<T>[]): void => {
    setSorting(value);
    callback(value);
  };

  const updateSorting = (sortField: keyof T) => (event: MouseEvent): void => {
    const currentSortDirection = getSortDirection(sortField);
    const sortDirection: null | SortIndicatorDirection = getNextSortDirection(currentSortDirection);
    const isMetaKey = event.metaKey || event.ctrlKey || event.altKey || event.shiftKey;
    const isMultiple = useMultiSort && isMetaKey;
    if (isMultiple) {
      const newSorting: SortItem<T>[] = sorting
        .filter((item: SortItem<T>): boolean => item.sortField !== sortField);
      if (sortDirection === null) {
        applySorting(newSorting.length
          ? newSorting
          : [ {
            sortField,
            sortDirection: getNextSortDirection(sortDirection) as SortIndicatorDirection
          } ]
        );
      } else {
        applySorting([
          ...newSorting,
          {
            sortField,
            sortDirection
          }
        ]);
      }
    } else {
      if (sortDirection === null) {
        applySorting([ {
          sortField,
          sortDirection: getNextSortDirection(sortDirection) as SortIndicatorDirection
        } ]);
      } else {
        applySorting([ { sortField, sortDirection } ]);
      }
    }
  };

  return {
    sorting,
    getSortDirection,
    updateSorting
  };
};
