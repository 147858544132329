import { Observable } from 'rxjs';
import { ICollection, IDictionaryResponse, ITableState, Translation } from '@mydse/design-system';
import { CollectionParameters } from '@mydse/typings';
import { ISurveyData } from '@services';

export enum RiskType {
  DPIA = 'DPIA',
  PRE_ASSESSMENT = 'PRE_ASSESSMENT',
  PRE_CHECK = 'PRE_CHECK',
  ASSESSMENT_DPO = 'ASSESSMENT_DPO',
  ASSESSMENT_DPM = 'ASSESSMENT_DPM',
  DPIA_DPO = 'DPIA_DPO',
  DPIA_DPM = 'DPIA_DPM',
  PRELIMINARY_CONSULTATION = 'PRELIMINARY_CONSULTATION'
}

export enum RiskState {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED'
}

export enum RiskStatus {
  OPEN = 'OPEN',
  FINISHED = 'FINISHED'
}

export interface IRiskCase {
  id: string;
  title: string;
  description: string;
  type: RiskType;
  state: RiskState;
  overallStatus: RiskStatus;
  readonly: boolean;
  dpmApproved?: boolean;
  dpoApproved?: boolean;
  steps: IRiskStep[];
  createdAt: Date;
  closedAt?: Date;
}

export interface IRiskStep {
  id: string;
  step: RiskType;
  status: RiskStatus;
  readonly: boolean;
  dpoStep: boolean;
  result?: IDictionaryResponse<Translation<string>>;
}

export enum ExportRiskFormat {
  DOCX = 'DOCX',
  PDF = 'PDF'
}

export interface IRiskService {
  getRiskInitialState: (type: RiskState) => Partial<ITableState<IRiskCase>>;
  getActiveRiskCount: () => Observable<number>;
  getRiskCollection: (parameters: CollectionParameters) => Observable<ICollection<IRiskCase>>;
  getRisk: (type: RiskType, parameters?: Record<string, boolean | string>) => Observable<ISurveyData>;
  updateRiskState: (id: string, status: RiskState) => Observable<Observable<ISurveyData>>;
  deleteRisk: (id: string) => Observable<void>;
  exportRisk: (id: string, format: ExportRiskFormat, locale: string) => Observable<void>;
}
