import { FullRouteListType } from '../configuration';
import { ProductType } from '@mydse/typings';
import { permissionService } from '@services';

export const defaultRoute = (product: ProductType | ProductType[] = [ 'academy', 'privacy' ]): FullRouteListType => {
  const productList: ProductType[] = Array.isArray(product)
    ? product
    : [ product ];
  const pagePermission = permissionService.pagePermissionSubject$.value;
  switch (true) {
    case (!!pagePermission?.onboarding):
      return 'onboarding';
    case (!!pagePermission?.dashboard && productList.some((item: string) => [ 'privacy', 'proliance' ].includes(item))):
      return 'dashboard';
    case (!!pagePermission?.risk && productList.includes('privacy')):
      return 'risk';
    case (!!pagePermission?.dataBreach && productList.includes('privacy')):
      return 'dataBreach';
    case (!!pagePermission?.courseManagement && productList.includes('academy')):
      return 'courseManagement';
    case (!!pagePermission?.myCourses && productList.includes('academy')):
      return 'myCourses';
    case (!!pagePermission?.products):
      return 'products';
    case (!!pagePermission?.profile):
      return 'profile';
    default:
      return 'login';
  }
};
