import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Callback
} from '@mydse/design-system';
import {
  CloseModalButton,
  ModalOptions,
  modalService
} from '@mydse/react-ui';
import { CustomerRequestModalContent } from '../components';

export type UseCustomerRequestModalReturn = { showModal: Callback };
export type UseShowCustomerRequestModal = () => UseCustomerRequestModalReturn;

export const useShowCustomerRequestModal: UseShowCustomerRequestModal = (): UseCustomerRequestModalReturn => {
  const id = 'customerRequest';

  const { t } = useTranslation('customerRequest');
  const [ form, setForm ] = useState<null | HTMLFormElement>(null);

  const getActionsElement = () => {
    const submit = (): void => {
      if (form !== null) {
        form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    };
    return (
      <>
        <CloseModalButton
          dataAttributesDictionary={ {
            test: { 'customer-request': 'close' },
            guide: { 'customer-request': 'close' }
          } }
        />
        <Button
          dataAttributesDictionary={ {
            test: { 'customer-request': 'submit' },
            guide: { 'customer-request': 'submit' }
          } }
          preset="primary"
          text={ t('button.submit') }
          onClick={ submit }
        />
      </>
    );
  };

  useEffect(
    (): void => {
      if (form) {
        modalService
          .patchModalOptions({
            id,
            footer: {
              actionsElement: getActionsElement()
            }
          });
      }
    },
    [ form ]
  );

  const showModal = (): void => {
    const modalOptions: ModalOptions = {
      id,
      size: 'large',
      dataAttributesDictionary: {
        test: { 'customer-request': 'modal' },
        guide: { 'customer-request': 'modal' }
      },
      heading: {
        title: t('header')
      },
      content: {
        contentElement: (
          <CustomerRequestModalContent
            id={ id }
            setForm={ setForm }
          />
        ),
        usePadding: false
      },
      footer: {
        actionsElement: getActionsElement()
      }
    };
    modalService.show(modalOptions);
  };

  return { showModal };
};
