import { AssetTypeKey } from '@services';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { faDatabase, faDesktop, faHandshake } from '@fortawesome/pro-regular-svg-icons';

export const iconMapping: Record<AssetTypeKey, IconDefinition> = {
  DataStorage: faDatabase,
  Software: faDesktop,
  ThirdParty: faHandshake
};
