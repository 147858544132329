import { BehaviorSubject } from 'rxjs';
import { CurrentUser } from '@mydse/typings';
import { deviceService } from '@services';
import { pendoApiService } from '@services/api';
import { IPendoService, IResolution } from './pendo.typings';

const apiKey: string = '7c46ef93-ef39-4812-409b-3ad6e33ad6f4';

const isInitializedSubject$ = new BehaviorSubject<boolean>(false);
const isUploadedSubject$ = new BehaviorSubject<boolean>(false);

const isEnabled = (): boolean => !!JSON.parse((window as any).env.enablePendo || 'false');

const getResolution = (): IResolution => {
  const {
    width: screenWidth,
    height: screenHeight
  } = deviceService.getScreenDimensions();
  const {
    width: windowWidth,
    height: windowHeight
  } = deviceService.getWindowDimensions();
  return {
    windowX: windowWidth,
    windowY: windowHeight,
    screenX: screenWidth,
    screenY: screenHeight
  };
};

const setGuidesDisabled = (value: boolean = true): void => {
  (window as any).pendo?.setGuidesDisabled(value);
};

const loadGuides = (): void => {
  (window as any).pendo.loadGuides();
};

const setUser = (user: CurrentUser): void => {
  if (!isEnabled()) {
    return;
  }
  if (!isUploadedSubject$.value) {
    if (user.analyticsAllowed !== false) {
      initialize(user);
    }
    return;
  } else {
    const {
      id: userId,
      currentCompany,
      analyticsAllowed
    } = user;
    const companyId = currentCompany?.id || '';
    const isConsentAnswered = typeof analyticsAllowed !== 'undefined';
    const options = {
      visitor: {
        id: `${ userId }-${ companyId }`
      },
      account: {
        id: companyId
      }
    };
    if (!isInitializedSubject$.value) {
      (window as any).pendo
        .initialize({
          disableGuides: !isConsentAnswered
        });
      isInitializedSubject$.next(true);
    } else {
      if (!isConsentAnswered) {
        setGuidesDisabled();
      } else if (analyticsAllowed) {
        loadGuides();
      }
    }
    (window as any).pendo.identify(options);
    (window as any).pendo.track('Resolution', getResolution());
  }
};

const createActionFunction = (
  action: string,
  index: number,
  initializeOptions: { disableGuides: boolean },
  pendo: any
) => {
  return (...params: any[]) => {
    const queueOperation = !!index
      ? 'unshift'
      : 'push';
    const args = !!index
      ? params
      : [ initializeOptions ];
    pendo._q[queueOperation]([ action, ...args ]);
  };
};

const initialize = (user: CurrentUser): void => {
  if (!isEnabled() || user.analyticsAllowed === false) {
    return;
  }
  if (isUploadedSubject$.value) {
    setUser(user);
    return;
  }
  const pendo = (window as any).pendo = (window as any).pendo || {};
  pendo._q = pendo._q || [];
  const actionList = [ 'initialize', 'identify', 'updateOptions', 'pageLoad', 'track' ];
  actionList
    .map((action: string, index: number) => {
      const initializeOptions = {
        disableGuides: typeof user?.analyticsAllowed === 'undefined'
      };
      pendo[action] = pendo[action] || createActionFunction(action, index, initializeOptions, pendo);
    });

  const script = document.createElement('script');
  script.src = `https://cdn.eu.pendo.io/agent/static/${ apiKey }/pendo.js`;
  script.async = true;
  script.onload = () => {
    // pendo.enableDebugging();
    isUploadedSubject$.next(true);
    setUser(user);
  };

  const firstScript = document.getElementsByTagName('script')[0];
  if (firstScript !== null && firstScript.parentNode !== null) {
    firstScript.parentNode.insertBefore(script, firstScript);
  }
};

export const pendoService: IPendoService = {
  isEnabled,
  initialize,
  setUser,
  setGuidesDisabled,
  loadGuides,
  updateAnalyticsAllowed: pendoApiService.updateAnalyticsAllowed.bind(pendoApiService)
};
