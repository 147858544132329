import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { componentFeatureMapping, ComponentListType, ComponentPermissionData, IPermission, pageComponentMapping, PermissionData } from '@mydse/typings';
import { RouteListType } from '@router/configuration';
import { PagePermissionData } from '@services';
import { permissionApiService } from '@services/api';

class PermissionService {
  public static getInstance(): PermissionService {
    return this.instance || (this.instance = new this());
  }
  private static instance: PermissionService;

  public permissionDataSubject$ = new BehaviorSubject<null | PermissionData>(null);
  public componentPermissionSubject$ = new BehaviorSubject<null | ComponentPermissionData>(null);
  public pagePermissionSubject$ = new BehaviorSubject<null | PagePermissionData>(null);

  private permissionApiService = permissionApiService;

  public getComponentsPermission(route: RouteListType): ComponentPermissionData {
    const routeMapping = pageComponentMapping[route];
    return routeMapping
      ? routeMapping
        .reduce((result: ComponentPermissionData, component: ComponentListType) => {
          if (this.componentPermissionSubject$.value) {
            result[component] = this.componentPermissionSubject$.value[component];
          }
          return result;
        }, {})
      : {};
  }

  public assignPermissionData(suppressDefaultErrorHandler?: boolean | number[]): Observable<void> {
    return this.permissionApiService.getPermissionData(suppressDefaultErrorHandler)
      .pipe(
        take(1),
        map((permissionData: PermissionData) => {
          this.permissionDataSubject$.next(permissionData);
          this.setComponentPermission(permissionData);
          this.setPagePermission();
        })
      );
  }

  public resetPermissionData(): void {
    this.permissionDataSubject$.next(null);
    this.componentPermissionSubject$.next(null);
    this.pagePermissionSubject$.next(null);
  }

  public isPageAvailable(page: RouteListType): boolean {
    const pagePermissionDictionary = this.pagePermissionSubject$.value;
    return !!(pagePermissionDictionary && pagePermissionDictionary[page]);
  }

  public isComponentAvailable(component: ComponentListType): boolean {
    const componentPermissionDictionary = this.componentPermissionSubject$.value;
    return !!(componentPermissionDictionary && componentPermissionDictionary[component]?.read);
  }

  private setComponentPermission(permissionData: PermissionData): void {
    const componentPermission = (Object.keys(componentFeatureMapping) as ComponentListType[])
      .reduce(
        (result: ComponentPermissionData, key: ComponentListType) => ({
          ...result,
          ...(permissionData[componentFeatureMapping[key]] && { [key]: permissionData[componentFeatureMapping[key]] })
        }),
        {}
      );
    this.componentPermissionSubject$.next(componentPermission);
  }

  private calculatePagePermission(route: RouteListType): undefined | boolean {
    const pageComponentList: undefined | ComponentListType[] = pageComponentMapping[route];
    const pageComponentsPermission =
      typeof pageComponentList === 'undefined'
        ? []
        : pageComponentList.reduce((result: boolean[], component: ComponentListType) => {
          const componentPermission: undefined | IPermission = this.componentPermissionSubject$.value![component];
          if (componentPermission) {
            result.push(componentPermission.read);
          }
          return result;
        }, []);
    return pageComponentsPermission.length
      ? pageComponentsPermission
        .some((value: boolean) => value)
      : undefined;
  }

  private setPagePermission(): void {
    const pagePermission = (Object.keys(pageComponentMapping) as RouteListType[]).reduce(
      (result: PagePermissionData, route: RouteListType) => {
        const permission = this.calculatePagePermission(route);
        if (typeof permission !== 'undefined') {
          result[route] = permission;
        }
        return result;
      },
      {}
    );
    this.pagePermissionSubject$.next(pagePermission);
  }
}

export const permissionService = PermissionService.getInstance();
