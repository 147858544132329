import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FunctionalArea } from '@mydse/typings';
import { Cluster, ActionChoice, ITaskCount, ITomCompanyData, TaskCountDictionary } from '@services';
import { Api } from '@services/api';

const apiUrl = '/api/survey/company-data';

class CompanyDataApiService extends Api {
  public getProcesses(functionalArea: FunctionalArea, showOnlyAssigned: boolean = false): Observable<null | Cluster[]> {
    return this.get<null | Cluster[]>({
      url: 'processes',
      parameters: { functionalArea, showOnlyAssigned }
    });
  }

  public getTom(showOnlyAssigned: boolean = false): Observable<ITomCompanyData[]> {
    return this.get<ITomCompanyData[]>({
      url: 'tom',
      parameters: { showOnlyAssigned }
    });
  }

  public getCount(): Observable<TaskCountDictionary<ITaskCount>> {
    return this.get<TaskCountDictionary<ITaskCount>>({ url: 'count' });
  }

  public getSelectableFunctionalArea(): Observable<FunctionalArea[]> {
    return this.get<FunctionalArea[]>({ url: 'selectable-fa' });
  }

  public deleteCustomProcess(id: string): Observable<boolean> {
    return this.delete<boolean>({ url: `custom-process/${ id }` }).pipe(take(1));
  }

  public companyAction = (id: string, actionChoice: ActionChoice): Observable<void> => {
    return this.post({
      url: `action/${ id }/quick-completion`,
      parameters: {
        actionChoice
      }
    });
  };
}

export const companyDataApiService = new CompanyDataApiService(apiUrl);
