import { TranslationPlural } from '@mydse/typings';
import { IFileUploadQuestion } from '../fileUpload.typings';
import { createElement } from '../../common';
import { createDropZoneInfoHtml } from './dropZoneInfo';
import { dropZoneInputFile } from './dropZoneInputFile';
import { useIcon } from './useIcon';

export const createDropZoneHtml = (
  question: IFileUploadQuestion,
  classNameDictionary: Record<string, string>,
  getCurrentLocale: (question: IFileUploadQuestion) => string,
  getLocalization: (question: IFileUploadQuestion, key: string) => string,
  getPluralLocalization: (question: IFileUploadQuestion, key: string) => TranslationPlural
): HTMLElement => {
  const id = `p360-file-upload-${ question.id }`;

  const iconElement = createElement({
    classNames: classNameDictionary.dropZoneIcon
  });

  const textBrowseElement = createElement({
    tagName: 'span',
    classNames: classNameDictionary.dropZoneBrowseText,
    content: getLocalization(question, 'browse')
  });

  const textElement = createElement({
    tagName: 'p',
    classNames: classNameDictionary.dropZoneText,
    content: [
      getLocalization(question, 'drop'),
      textBrowseElement
    ]
  });

  const dropZoneContentElement = createElement({
    classNames: classNameDictionary.dropZoneContent,
    content: [
      iconElement,
      textElement
    ]
  });

  const dropZoneInfoElement = createDropZoneInfoHtml(
    question,
    classNameDictionary,
    getCurrentLocale,
    getLocalization,
    getPluralLocalization
  );

  const icon = createElement({
    tagName: 'span',
    classNames: classNameDictionary.icon
  });
  useIcon(icon, 'exclamation');
  const errorMessage = createElement({
    tagName: 'span',
    classNames: classNameDictionary.errorMessage
  });
  const dropZoneErrorElement = createElement({
    classNames: [ classNameDictionary.errorPanel, classNameDictionary.hidden ],
    content: [
      icon,
      errorMessage
    ]
  });

  return createElement<HTMLLabelElement>({
    tagName: 'label',
    classNames: classNameDictionary.dropZone,
    attributes: { htmlFor: id },
    content: [
      dropZoneInputFile(id, question, classNameDictionary),
      dropZoneContentElement,
      dropZoneInfoElement,
      dropZoneErrorElement
    ]
  });
};
