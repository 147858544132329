import React, { FC, lazy } from 'react';
import { FunctionalArea } from '@mydse/typings';
import { defaultRedirect as redirectCallback } from '@router';
import { ISurveyData } from '@services';

const SurveyView = lazy(() => import('@shared/surveyJs/SurveyView'));

interface IOwnProps {
  functionalArea: FunctionalArea;
  survey: ISurveyData;
  clearLeavePageHandler: () => void;
}

type Props = IOwnProps;

const ProcessSelection: FC<Props> = ({ functionalArea, survey, clearLeavePageHandler }) => {
  const paramsString: string = JSON.stringify({ functionalArea });
  const params: Record<string, string> = { params: paramsString };

  const { template, result } = survey;

  const onComplete = () => {
    clearLeavePageHandler();
    redirectCallback();
  };

  return (
    <div className="survey">
      <SurveyView
        template={template}
        result={result}
        params={params}
        complete={onComplete}
      />
    </div>
  );
};

export default ProcessSelection;
