import { TranslationInfo, Locale } from '@mydse/typings';
import { IDictionaryResponse } from '@interfaces';
import { DataFormat } from 'select2';

const dictionaryListMapper = (
  dataList: Array<IDictionaryResponse<TranslationInfo>> = [],
  locale: Locale
): DataFormat[] => dataList
  .map(
    (data: IDictionaryResponse<TranslationInfo>) => ({
      id: data.id,
      text: data.title[locale] ?? ''
    })
  );

export default dictionaryListMapper;
