import { storageService } from '@mydse/react-ui';

export const getFilter = <F>(key?: string, id?: number | string, defaultFilter?: F): undefined | F => {
  if (typeof key === 'undefined' || typeof id === 'undefined') {
    return typeof defaultFilter === 'undefined'
      ? defaultFilter
      : { ...defaultFilter };
  }
  const storedFilterDictionary: Record<string, F> | null = storageService.get(key);
  if (!storedFilterDictionary || !storedFilterDictionary[id]) {
    return typeof defaultFilter === 'undefined'
      ? defaultFilter
      : { ...defaultFilter };
  }
  return storedFilterDictionary[id];
};
