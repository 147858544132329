import { useMemo } from 'react';
import { Column } from 'react-table';
import { TFunction } from 'react-i18next';
import { IActivity } from '@services';
import {
  Header,
  NameTranslationsCell,
  ValueCell,
  DateCell
} from '@mydse/design-system';
import { AuthorCell } from './Renderers';

export const getColumns = (t: TFunction): Array<Column<IActivity>> =>
  useMemo(
    () => [
      {
        id: 'activity',
        width: 235,
        accessor: (originalRow: IActivity) => originalRow.nameTranslations,
        disableSortBy: true,
        disableFilters: true,
        Header: Header({ t }),
        Cell: NameTranslationsCell({
          t,
          className: 'nameTranslations',
          singleLine: true
        })
      },
      {
        id: 'author',
        accessor: (originalRow: IActivity) => originalRow.author,
        disableSortBy: true,
        disableFilters: true,
        Header: Header({ t }),
        Cell: AuthorCell({ t })
      },
      {
        id: 'affectedUser',
        accessor: (originalRow: IActivity) => originalRow.affectedUserName,
        disableSortBy: true,
        disableFilters: true,
        Header: Header({ t }),
        Cell: ValueCell({ singleLine: true })
      },
      {
        accessor: 'createdAt',
        disableFilters: true,
        Header: Header({ t }),
        Cell: DateCell()
      }
    ],
    []
  );
