import { ITableParameters } from '@interfaces';
import { defaultPageSize } from '@mydse/design-system';

const defaultTableParameters = {
  page: 0,
  pageSize: defaultPageSize,
  sortBy: [],
  filters: []
};

export function getDefaultTableParameters<T extends  {}>(
  options: Partial<ITableParameters<T>> = {}
): ITableParameters<T> {
  return {
    ...defaultTableParameters,
    ...options
  };
}
