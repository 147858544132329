import { Observable } from 'rxjs';
import { Collection } from '@mydse/typings';
import { IActivity } from '@services';
import { Api } from '@services/api';
import { QueryStringValue } from '@mydse/design-system';

const apiUrl = '/api/activity';

class ActivityApiService extends Api {
  public getActivity(parameters: Record<string, QueryStringValue> = {}): Observable<Collection<IActivity>> {
    return this.get<Collection<IActivity>>({ parameters });
  }
}

export const activityApiService = new ActivityApiService(apiUrl);
