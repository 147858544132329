import React, { FC, memo, ReactElement } from 'react';
import { Namespace, useTranslation } from 'react-i18next';
import { Accordion, AccordionItem, useAccordionState } from '@mydse/design-system';
import { getKey } from '@router';
import { default as InfoAccordionPanel } from './InfoAccordionPanel';

import styles from './InfoAccordion.styl';

interface OwnProps {
  namespace: Namespace;
  name: string;
  panelTranslationKey?: string;
  textTranslationKey?: string;
  linkTranslationKey?: string;
}

type Props = OwnProps;

const InfoAccordion: FC<Props> = ({
  namespace,
  name,
  panelTranslationKey = 'accordion.info.panel',
  textTranslationKey = 'accordion.info.text',
  linkTranslationKey = 'accordion.info.link'
}): ReactElement => {
  const { i18n, t } = useTranslation(namespace);

  const accordionKey = `${ name }AccordionState`;
  const token = getKey(accordionKey);
  const { accordionState, accordionChangeHandler } = useAccordionState(token);

  const panel = <InfoAccordionPanel namespace={ namespace } translationKey={ panelTranslationKey }/>;
  const textTranslation: string[] = t(textTranslationKey, { returnObjects: true, defaultValue: [] }) || [];
  const text = textTranslation
    .map((__html: string, index: number) => (
      <p
        key={ index }
        dangerouslySetInnerHTML={ { __html } }
      />
    ));

  const getLink = (): undefined | ReactElement => {
    if (!i18n.exists(`${ linkTranslationKey }.href`) || !i18n.exists(`${ linkTranslationKey }.text`)) {
      return undefined;
    }
    const linkHref = t(`${ linkTranslationKey }.href`);
    const linkText = t(`${ linkTranslationKey }.text`);
    return (
      <a
        className={ styles.link }
        target="_blank"
        href={ linkHref }
      >
        { linkText }
      </a>
    );
  };
  const link = getLink();
  const content = (
    <div className={ styles.content }>
      { text }
      { link }
    </div>
  );

  const dataAttributesValue = { accordion: 'info' };
  const dataAttributesDictionary = {
    test: dataAttributesValue,
    guide: dataAttributesValue
  };

  return (
    <Accordion
      className={ styles.accordion }
      dataAttributesDictionary={ dataAttributesDictionary }
      allowAllCollapsed
      expandedList={ accordionState }
      onChange={ accordionChangeHandler }
    >
      <AccordionItem
        key={ name }
        id={ name }
        summaryClassName={ styles.panel }
        panel={ panel }
        content={ content }
      />
    </Accordion>
  );
};

export default memo(InfoAccordion);
