import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslationInfo } from '@mydse/typings';
import { IDictionaryResponse } from '@interfaces';
import DropdownWidget, { IQuestionDropdown } from './dropdown.widget';
import {
  allowClearProperty,
  dictionaryProperty,
  dropdownAutoWidthProperty,
  labelProperty,
  maxSelectedChoicesProperty,
  minimumResultsForSearchProperty,
  optionsCaptionProperty,
  select2ConfigProperty,
  selectAllTextProperty,
  showPlaceholderProperty
} from './properties';
import { IProperty, IWidgetConfiguration } from '../interfaces';
import { dictionaryService, DictionaryType } from '../compatibility';
import { CustomWidgetCollection } from '@shared/surveyJs/reexport';
import {
  dictionaryListMapper as dictionaryDataMapper,
  getDictionarySelect2Options as getSelect2Config
} from './utilities';

import { faBookAlt } from '@fortawesome/pro-regular-svg-icons';
import { faBookMedical } from '@fortawesome/pro-regular-svg-icons';
import './styles/dictionary.styl';

const Dictionary = ($: JQueryStatic): void => {
  const singleName: string = 'dictionary';
  const multipleName: string = 'dictionarymultiple';
  const singleAlternativeNameList: string[] = [ 'dsedictionarydropdown' ];
  const multipleAlternativeNameList: string[] = [];
  const customPropertiesList: IProperty[] = [
    allowClearProperty,
    labelProperty,
    showPlaceholderProperty,
    optionsCaptionProperty,
    select2ConfigProperty,
    dropdownAutoWidthProperty
  ];
  const singleCustomPropertiesList: IProperty[] = [
    dictionaryProperty,
    minimumResultsForSearchProperty
  ];
  const multipleCustomPropertiesList: IProperty[] = [
    dictionaryProperty,
    selectAllTextProperty,
    maxSelectedChoicesProperty
  ];
  const hidePropertyNameList: string[] = [
    'allowAddNewTag',
    'select2Config',
    'choicesByUrl',
    'choices',
    'url',
    'choicesMin',
    'choicesMax',
    'choicesStep',
    'hasOther',
    'otherText',
    'otherPlaceHolder',
    'hasNone',
    'noneText',
    'hasSelectAll',
    'selectAllText',
    'otherErrorText'
  ];
  const singleHidePropertyNameList: string[] = [
    ...hidePropertyNameList
  ];
  const multipleHidePropertyNameList: string[] = [
    ...hidePropertyNameList,
    'colCount'
  ];
  const classNameDictionary = {
    wrap: 'p360-dictionary-dropdown-wrap',
    select: 'p360Select',
    textarea: 'p360Textarea',
    textareaWrap: 'question-other-label-wrap',
    searchField: 'select2-search__field'
  };
  const editorLocalization = {
    p: {
      dictionary: {
        en: 'Dictionary',
        de: 'Wörterbuch'
      }
    },
    pe: {
      optionsCaption: {
        en: 'Placeholder',
        de: 'Platzhalter'
      },
      showPlaceholder: {
        en: 'Show placeholder',
        de: 'Platzhalter anzeigen'
      },
      dropdownAutoWidth: {
        en: 'Dropdown auto width',
        de: 'Automatische Dropdown-Breite'
      },
      minimumResultsForSearch: {
        en: 'Minimum results for search',
        de: 'Minimale Ergebnisse für die Suche'
      }
    },
    pv: {
      legalForm: {
        en: 'Legal form',
        de: 'Rechtsform'
      },
      industry: {
        en: 'Industry',
        de: 'Branche'
      },
      state: {
        en: 'State',
        de: 'Bundesland'
      },
      responsibilityTypes: {
        en: 'Responsibility type',
        de: 'Verantwortungsart'
      }
    }
  };
  const singleEditorLocalization = {
    qt: {
      [singleName]: {
        en: 'Dictionary single',
        de: 'Wörterbuch Single'
      }
    }
  };
  const multipleEditorLocalization = {
    qt: {
      [multipleName]: {
        en: 'Dictionary multiselect',
        de: 'Wörterbuch Multiselect'
      }
    }
  };
  const dictionaryData: Record<string, null | Array<IDictionaryResponse<TranslationInfo>>> = {};
  const dataObservableDictionary: Record<string, null |  Observable<Array<IDictionaryResponse<TranslationInfo>>>> = {};
  const dictionaryDataSource = (
    question: IQuestionDropdown
  ): Observable<Array<IDictionaryResponse<TranslationInfo>>> => {
    if (!question.dictionary) {
      question.dictionary = dictionaryProperty.default as DictionaryType;
    }
    if (question.dictionary === '') {
      return of([]);
    } else if (!!dictionaryData[question.dictionary]) {
      return of(dictionaryData[question.dictionary]!);
    } else if (!dataObservableDictionary[question.dictionary]) {
      dataObservableDictionary[question.dictionary] = dictionaryService.getData(question.dictionary)
        .pipe(
          tap(
            (data: Array<IDictionaryResponse<TranslationInfo>>) => {
              dictionaryData[question.dictionary] = data;
            })
        );
    }
    return dataObservableDictionary[question.dictionary]!;
  };
  const singleDefaultJSON = {
    type: singleName,
    choices: []
  };
  const multipleDefaultJSON = {
    type: multipleName,
    choices: []
  };
  const singleConfiguration: IWidgetConfiguration = {
    name: singleName,
    iconName: singleName,
    icon: faBookAlt,
    alternativeNameList: singleAlternativeNameList,
    customPropertiesList: [ ...customPropertiesList, ...singleCustomPropertiesList ],
    hidePropertyNameList: singleHidePropertyNameList,
    classNameDictionary,
    editorLocalization: { ...singleEditorLocalization, ...editorLocalization },
    dictionaryDataSource,
    dictionaryDataMapper,
    getSelect2Config,
    defaultJSON: singleDefaultJSON,
    isMultiple: false,
    inheritClass: 'dropdownbase'
  };
  const multipleConfiguration: IWidgetConfiguration = {
    name: multipleName,
    iconName: multipleName,
    icon: faBookMedical,
    alternativeNameList: multipleAlternativeNameList,
    customPropertiesList: [ ...customPropertiesList, ...multipleCustomPropertiesList ],
    hidePropertyNameList: multipleHidePropertyNameList,
    classNameDictionary,
    editorLocalization: { ...multipleEditorLocalization, ...editorLocalization },
    dictionaryDataSource,
    dictionaryDataMapper,
    getSelect2Config,
    defaultJSON: multipleDefaultJSON,
    isMultiple: true,
    inheritClass: 'checkbox'
  };
  const singleWidget = new DropdownWidget($, singleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    singleWidget,
    singleWidget.activatedBy
  );
  const multipleWidget = new DropdownWidget($, multipleConfiguration);
  CustomWidgetCollection.Instance.addCustomWidget(
    multipleWidget,
    multipleWidget.activatedBy
  );
};

export default Dictionary;
