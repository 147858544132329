import { ThirdPartyFormKey } from '@services';

export const thirdPartySteps: ThirdPartyFormKey[] = [
  'type',
  'legalStatuses',
  'dataTypes',
  'transmissionCountries',
  'documents',
  'description',
  'contractualDeadlines',
  'contact',
  'privacyContact',
  'services',
  'accesses',
  'rpaAsController',
  'rpaAsProcessor'
];
