import { State } from 'router5';
import { DoneFn } from 'router5/dist/types/base';
import { sidebarService } from '@mydse/react-ui';

export const sidebarMiddleware = () => (
  _toState: State,
  _fromState: State,
  done: DoneFn
) => {
  if (sidebarService.isOpen.value) {
    sidebarService.hide();
  }
  return done();
};
