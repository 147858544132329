import { ComponentListType, ComponentPermissionData } from '@mydse/typings';
import { snakeCaseToCamelCase } from '@mydse/utilities';
import { checkPermission } from '@router';
import { routeTabMapping, TabsRouteList } from '@router/data';

const getAcceptedRouteTabList = <T extends string>(
  name: TabsRouteList,
  permission?: ComponentPermissionData
): T[] => {
  const { routeList, mapper } = routeTabMapping[name];
  return (routeList as T[])
    .filter((item: T) => checkPermission(
      mapper
        ? mapper[item]
        : snakeCaseToCamelCase(item) as ComponentListType,
      permission
    ));
};

export default getAcceptedRouteTabList;
