import { BehaviorSubject, Observable } from 'rxjs';
import { Collection, Feature, IDictionaryResponse, Locale, TranslationInfo, User } from '@mydse/typings';
import { SortItem } from '@hooks';
import { Company } from '@services';
import { ChipPreset } from '@mydse/react-ui';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

export enum AssetManagementTabsKey {
  SOFTWARE = 'SOFTWARE',
  THIRD_PARTIES = 'THIRD_PARTIES',
  DATA_STORAGES = 'DATA_STORAGES'
}

export enum Priority {
  IMPORTANT = 'IMPORTANT',
  RECOMMENDED = 'RECOMMENDED',
  OPTIONAL = 'OPTIONAL'
}

export enum Cooperation {
  ACTIVE = 'ACTIVE',
  IN_PLANNING = 'IN_PLANNING',
  INACTIVE = 'INACTIVE'
}

export enum DpoReview {
  NOT_REQUESTED = 'NOT_REQUESTED',
  REQUESTED = 'REQUESTED',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_NOTE = 'COMPLETED_WITH_NOTE',
  NOTE = 'NOTE'
}

export enum EditingStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  IN_DPO_REVIEW = 'IN_DPO_REVIEW',
  DONE = 'DONE'
}

export interface Asset {
  id: string;
  name: string;
  description: string;
  cooperation: Cooperation;
  dpoReview: DpoReview;
  editingStatus: EditingStatus;
  company: Company;
  assignee: User;
}

export enum PrivacyContactType {
  DPO = 'DPO',
  EU_REPRESENTATIVE = 'EU_REPRESENTATIVE',
  NONE = 'NONE'
}

export enum LegalStatus {
  DATA_PROCESSOR = 'DATA_PROCESSOR',
  JOINT_CONTROLLER = 'JOINT_CONTROLLER',
  DATA_CONTROLLER = 'DATA_CONTROLLER',
  FREELANCER_INVOLVED_LIKE_EMPLOYEE = 'FREELANCER_INVOLVED_LIKE_EMPLOYEE',
  NO_PROCESSING_OF_PERSONAL_DATA = 'NO_PROCESSING_OF_PERSONAL_DATA'
}

export enum DataType {
  NORMAL_PERSONAL_DATA = 'NORMAL_PERSONAL_DATA',
  SENSITIVE_PERSONAL_DATA = 'SENSITIVE_PERSONAL_DATA',
  SPECIAL_CATEGORIES_OF_PERSONAL_DATA = 'SPECIAL_CATEGORIES_OF_PERSONAL_DATA',
  ONLY_LOGIN_DATA = 'ONLY_LOGIN_DATA',
  NO_PERSONAL_DATA = 'NO_PERSONAL_DATA'
}

export interface CustomType {
  id: string;
  name: string;
  company: Company;
}

export interface CustomDataType extends CustomType {
  thirdParties: ThirdParty[];
  software: Software[];
  dataStorages: DataStorage[];
}

export enum Instrument {
  EU_EEA = 'EU_EEA',
  ADEQUACY_DECISION = 'ADEQUACY_DECISION',
  SDPC_SCC_C = 'SDPC_SCC_C',
  DPF = 'DPF',
  PIPEDA = 'PIPEDA',
  SUPPLEMENTARY_MEASURES = 'SUPPLEMENTARY_MEASURES',
  COC = 'COC',
  BCR = 'BCR',
  CONSENT = 'CONSENT',
  CONTRACT_B = 'CONTRACT_B',
  CONTRACT_C = 'CONTRACT_C',
  LIMITED_TRANSFER = 'LIMITED_TRANSFER',
  DOCUMENT_AUTHORITIES = 'DOCUMENT_AUTHORITIES',
  SDPC_SCC_D = 'SDPC_SCC_D',
  CERTIFICATION = 'CERTIFICATION',
  AUTHORISED_CLAUSES = 'AUTHORISED_CLAUSES',
  ADMINISTRATIVE_ARRANGEMENTS = 'ADMINISTRATIVE_ARRANGEMENTS',
  PUBLIC_INTEREST = 'PUBLIC_INTEREST',
  LEGAL_CLAIMS = 'LEGAL_CLAIMS',
  INCAPABILITY_TO_CONSENT = 'INCAPABILITY_TO_CONSENT',
  REGISTER = 'REGISTER'
}

export interface TransmissionCountries {
  [country: string]: Instrument[];
}

export enum DocumentStatus {
  AVAILABLE = 'AVAILABLE',
  HIDDEN = 'HIDDEN',
}

export interface AuditingEntity {
  createdAt: number;
  updatedAt: number;
}

export interface DocumentTopic extends AuditingEntity {
  id: string;
  name: string;
  translation: TranslationInfo;
  documents: Document[];
  features: Feature[];
}

export enum FileType {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
  OTHER = 'application/octet-stream'
}

export interface Dictionary extends AuditingEntity {
  id: number;
  name: string;
}

export interface DictionaryValue extends AuditingEntity {
  id: number;
  dictionary: Dictionary;
  key: string;
  salesforceApiName: string;
  title: TranslationInfo;
  additionalInfo: Document;
}

export interface ThirdPartyDocument {
  id: number;
  thirdParty: ThirdParty;
  document: Document;
  evidences: Evidence[];
}

export interface Evidence {
  id: number;
  thirdParty: ThirdParty;
  evidenceType: EvidenceType;
  documents: ThirdPartyDocument[];
}

export interface ContractualDeadline {
  thirdParty: ThirdParty;
  type: ContractualDeadlinesType;
  date: Date;
}

export enum ThirdPartyType {
  SERVICE_PROVIDER_EXTERNAL_COMPANY = 'SERVICE_PROVIDER_EXTERNAL_COMPANY',
  SERVICE_PROVIDER_ASSOCIATED_COMPANY = 'SERVICE_PROVIDER_ASSOCIATED_COMPANY',
  FREELANCE_EMPLOYEE = 'FREELANCE_EMPLOYEE',
  PUBLIC_AUTHORITY = 'PUBLIC_AUTHORITY',
}

export enum EvidenceType {
  DPA = 'DPA',
  JOINT_RESPONSIBILITY_AGREEMENT = 'JOINT_RESPONSIBILITY_AGREEMENT',
  CONFIDENTIALITY_AND_PURPOSE_LIMITATION_AGREEMENT = 'CONFIDENTIALITY_AND_PURPOSE_LIMITATION_AGREEMENT',
  DATA_PROTECTION_OBLIGATIONS_FREELANCERS = 'DATA_PROTECTION_OBLIGATIONS_FREELANCERS'
}

export enum ContractualDeadlinesType {
  CONTRACT_START = 'CONTRACT_START',
}

export interface Contact {
  id: number;
  companyName: string;
  contactPerson: string;
  email: string;
  countryDialCode: string;
  phoneNumber: string;
  contactForm: string;
  country: string;
  state: string;
  city: string;
  street: string;
  zip: string;
}

export interface AssetManagementItem {
  id: string;
  name: string;
  legalStatuses: LegalStatus[];
  cooperation: Cooperation;
  dpoReview: DpoReview;
  editingStatus: EditingStatus;
  assignee: User;
}

export interface ThirdPartyData {
  name: string;
  contractualRelationship: string;
  cooperation: Cooperation;
}

export enum CompanyDataStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED'
}

// export interface ProcessV2 extends AuditingEntity {
//   id: number;
//   functionalArea: FunctionalArea;
//   processCluster: ProcessCluster;
//   anchorProcess: ProcessV2;
//   deleted: boolean;
//   stable: ProcessRevision;
//   draft: ProcessRevision;
//   revisions: ProcessRevision[];
//   breakingChangePublishDate: Date;
// }
// export interface CompanyProcess extends AuditingEntity {
//   id: string;
//   company: Company;
//   processV2: ProcessV2;
//   processRevision: ProcessRevision;
//   basisDataItems: ProcessContextBasisData[];
//   contextTaskId: string;
//   status: CompanyDataStatus;
//   lastContextSurveyResultId: string;
//   parameters: Document;
// }

export interface ProcessContextBasisData {
  id: number;
  // companyProcess: CompanyProcess;
  // basisDataType: Systems;
  basisDataId: string;
}

export interface AbstractSystem extends AuditingEntity {
  id: string;
  company: Company;
  deletedAt: Date;
}

export interface Software extends Asset {
// export interface Software extends AbstractSystem {
  name: string;
  manufacturer: string;
}

export interface SoftwareData {
  name: string;
}

export enum DataStorageType {
  SELF_HOSTED = 'SELF_HOSTED',
  THIRD_PARTY_HOSTED = 'THIRD_PARTY_HOSTED',
  CLOUD_HOSTED = 'CLOUD_HOSTED'
}

export interface DataStorage extends Asset {
  type: DataStorageType;
  dataTypes: DataType[];
  customDataTypes: CustomDataType[];
  operators: ThirdParty[];
  accessors: ThirdParty[];
  rpaAsController: ProcessContextBasisData[];
  rpaAsProcessor: ProcessContextBasisData[];
}

export interface DataStorageData {
  name: string;
  type: DataStorageType;
}

export interface ThirdParty extends Asset {
  contractualRelationship: string;
  type: ThirdPartyType;
  legalStatuses: LegalStatus[];
  dataTypes: DataType[];
  customDataTypes: CustomDataType[];
  transmissionCountries: TransmissionCountries;
  documents: ThirdPartyDocument[];
  evidences: Evidence[];
  contractualDeadlines: ContractualDeadline[];
  contact: Contact;
  privacyContactType: PrivacyContactType;
  privacyContact: Contact;
  servicesSoftware: Software[];
  servicesStorages: DataStorage[];
  accessesSoftware: Software[];
  accessesStorages: DataStorage[];
  rpaAsController: ProcessContextBasisData[];
  rpaAsProcessor: ProcessContextBasisData[];
}

export type AssetType = ThirdParty | Software | DataStorage;
export type AssetTypeKey = 'DataStorage' | 'Software' | 'ThirdParty';

export interface AssetManagementFilterData {
  assignee?: number;
  legalStatuses?: LegalStatus[];
  cooperation?: Cooperation[];
  dpoReview?: DpoReview[];
  editingStatus?: EditingStatus[];
  type?: ThirdPartyType[];
  dataTypes?: DataType[];
} // TODO use generic

export interface AssetManagementFetchData<T extends Asset> {
  sort?: SortItem<T>[];
  filter?: Partial<AssetManagementFilterData>;
}

export type DataStorageKey = keyof DataStorage;
export type SoftwareKey = keyof Software;
export type ThirdPartyKey = keyof ThirdParty;
export type AssetKey = ThirdPartyKey | SoftwareKey | DataStorageKey;

export type DataStorageFormKey = 'name';
export type SoftwareFormKey = 'name';
export type ThirdPartyFormKey =
  'type'
  | 'legalStatuses'
  | 'dataTypes'
  | 'transmissionCountries'
  | 'documents'
  | 'description'
  | 'contractualDeadlines'
  | 'contact'
  | 'privacyContact'
  | 'services'
  | 'accesses'
  | 'rpaAsController'
  | 'rpaAsProcessor';
export type AssetFormKey = DataStorageFormKey | SoftwareFormKey | ThirdPartyFormKey;

export type GetPriority = {
  (type: 'DataStorage', key: DataStorageKey): Priority;
  (type: 'Software', key: SoftwareKey): Priority;
  (type: 'ThirdParty', key: ThirdPartyKey): Priority;
};
export type GetNextStep = {
  (type: 'DataStorage', currentStep: DataStorageFormKey): null | DataStorageFormKey;
  (type: 'Software', currentStep: SoftwareFormKey): null | SoftwareFormKey;
  (type: 'ThirdParty', currentStep: ThirdPartyFormKey): null | ThirdPartyFormKey;
};

export type AssetManagementSidebarData = {
  type: AssetFormKey;
  asset: Asset;
};

export type FetchAssetManagementData<T extends Asset> = (parameters?: AssetManagementFetchData<T>) => Promise<null | Collection<T>>;

export enum Relevance {
  TOP = 'TOP',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  NONE = 'NONE'
}

export interface InstrumentData {
  instrument: Instrument;
  relevance: Relevance;
  order: number;
  automatic: boolean;
}

export interface GetCountryTitleOptions {
  country: string;
  locale: Locale;
  dictionaryData: Array<IDictionaryResponse<TranslationInfo>>;
}

export interface AssetManagementService {
  sidebar: BehaviorSubject<undefined | AssetManagementSidebarData>;
  sidebar$: Observable<undefined | AssetManagementSidebarData>;
  openSidebar: (data: AssetManagementSidebarData) => void;
  closeSidebar: () => void;

  showNext: BehaviorSubject<undefined | boolean>;

  getCountryTitle: (country: string) => Promise<string>;

  getIcon: (type: AssetTypeKey) => IconDefinition;
  getNextStep: GetNextStep;
  getPriority: GetPriority;
  getPriorityPreset: (priority: Priority) => ChipPreset;

  getThirdPartyCollection: (parameters?: Record<string, any>) => Observable<Collection<ThirdParty>>;
  getThirdParty: (id: string) => Observable<ThirdParty>;
  createThirdParty: (body: ThirdPartyData) => Observable<ThirdParty>;
  updateThirdParty: (id: string, body: Partial<ThirdParty>) => Observable<ThirdParty>;
  getInstrumentDataList: (country: string) => Observable<InstrumentData[]>;

  getSoftwareCollection: (parameters?: Record<string, any>) => Observable<Collection<Software>>;
  getSoftware: (id: string) => Observable<Software>;
  createSoftware: (body: SoftwareData) => Observable<Software>;
  updateSoftware: (id: string, body: Partial<Software>) => Observable<Software>;

  getDataStorageCollection: (parameters?: Record<string, any>) => Observable<Collection<DataStorage>>;
  getDataStorage: (id: string) => Observable<DataStorage>;
  createDataStorage: (body: DataStorageData) => Observable<DataStorage>;
  updateDataStorage: (id: string, body: Partial<DataStorage>) => Observable<DataStorage>;
}
