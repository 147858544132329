import { storageService } from '@mydse/react-ui';

export const getSorting = <S>(key?: string, id?: number | string, defaultSorting?: S[]): undefined | S[] => {
  if (typeof key === 'undefined' || typeof id === 'undefined') {
    return typeof defaultSorting === 'undefined'
      ? defaultSorting
      : [ ...defaultSorting ];
  }
  const storedSortingDictionary: Record<string, S[]> | null = storageService.get(key);
  if (!storedSortingDictionary || !storedSortingDictionary[`${ id }`]) {
    return typeof defaultSorting === 'undefined'
      ? defaultSorting
      : [ ...defaultSorting ];
  }
  return storedSortingDictionary[`${ id }`];
};
