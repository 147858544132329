import { BehaviorSubject, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { Collection } from '@mydse/typings';
import { ITableParameters } from '@interfaces';
import { Company, CompanyService, permissionService, productService, streamService, userService } from '@services';
import { userApiService } from '@services/api';

const isInitializedSubject$ = new BehaviorSubject<boolean>(false);
const isMultitenancy$ = new BehaviorSubject<null | boolean>(null);
const company$ = new BehaviorSubject<null | Company>(null);
const companyId$ = new BehaviorSubject<null | number>(null);

const getIsMultitenancy = (): null | boolean => isMultitenancy$.value;

const getCurrentCompany = (): Observable<null | Company> => of(company$.value);

const resetCompany = (): void => {
  company$.next(null);
  companyId$.next(null);
};

const getAvailableCompanyCollection = (
  parameters: Partial<ITableParameters<Company>> = {}
): Observable<Collection<Company>> => {
  return userApiService.getAvailableCompanyCollection(parameters);
};

const updateCurrentCompany = (suppressDefaultErrorHandler?: boolean | number[]): Observable<null | Company> => {
  return userApiService.getAvailableCompanyCollection({}, suppressDefaultErrorHandler)
    .pipe(
      take(1),
      map((companyCollection: Collection<Company>) => {
        const company: null | Company = companyCollection.elements[0] || null;
        updateCompanyData(company, companyCollection.elements.length > 1);
        return company;
      })
    );
};

const selectCurrentCompany = (companyId: null | number): Observable<null | Company> => {
  companyId$.next(companyId);
  if (companyId === null) {
    return of(null);
  }
  return setCurrentCompany(companyId)
    .pipe(
      catchError(() => of(null)),
      mergeMap((company: null | Company) => {
        setCompany(company);
        return forkJoin([
          userService.updateUser(),
          productService.getProductsData(),
          permissionService.assignPermissionData()
        ])
          .pipe(map(() => {
            return company;
          }));
      })
    );
};

const updateCompanyData = (company: null | Company, isMultitenancy: boolean): void => {
  setCompany(company);
  if (company) {
    isMultitenancy$.next(isMultitenancy);
  }
};

const setCompany = (company: null | Company): null | Company => {
  company$.next(company);
  companyId$.next(company ? company.id : null);
  isInitializedSubject$.value
    ? streamService.reconnect()
    : streamService.connect();
  isInitializedSubject$.next(true);
  return company;
};

const setCurrentCompany = (companyId: number): Observable<Company> => {
  return userApiService.setCurrentCompany(companyId);
};

export const companyService: CompanyService = {
  isInitializedSubject$,
  isMultitenancy$,
  company$,
  companyId$,
  getIsMultitenancy,
  getCurrentCompany,
  resetCompany,
  getAvailableCompanyCollection,
  updateCurrentCompany,
  selectCurrentCompany,
  updateCompanyData,
  setCompany,
  setCurrentCompany
};
