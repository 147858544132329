import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, redirectUrnParameterName } from '@router';
import { IPermission } from '@mydse/typings';
import { SystemsType } from '@services';
import { Button } from '@mydse/design-system';

interface IOwnProps {
  namespace: string | string[];
  permission?: IPermission;
  url: string;
  systemsType: SystemsType;
}

type Props = IOwnProps;

const ActionButtons: FC<Props> = ({
  namespace,
  permission,
  url,
  systemsType
}) => {
  const { t } = useTranslation(namespace);

  const navigateBack = () => redirect('systems');
  const backButton = (
    <Button
      preset="default"
      dataAttributesDictionary={{
        test: { 'button-back': 'top' },
        guide: { 'button-back': 'top' }
      }}
      text={t('common:button.back')}
      onClick={navigateBack}
    />
  );

  const redirectUrl = `systems/${ url }`;
  const navigateAdd = () => redirect('systemsAdd', { url, [redirectUrnParameterName]: redirectUrl });
  const addButton = permission && permission.write ? (
    <Button
      preset="primary"
      dataAttributesDictionary={{
        test: { 'button-add': 'top' },
        guide: { 'button-add': 'top' }
      }}
      text={t(`button.add.${ systemsType }`)}
      onClick={navigateAdd}
    />
  ) : null;

  return (
    <>
      {addButton}
      {backButton}
    </>
  );
};

export default ActionButtons;
