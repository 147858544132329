import { TranslationPlural } from '@mydse/typings';
import { IFileUploadQuestion } from '../fileUpload.typings';
import { createElement } from '../../common';
import { pluralize } from '../utilities';
import { acceptedTypesString } from '../data';
import { createDropZoneInfoItemHtml } from './dropZoneInfoItem';

export const createDropZoneInfoHtml = (
  question: IFileUploadQuestion,
  classNameDictionary: Record<string, string>,
  getCurrentLocale: (question: IFileUploadQuestion) => string,
  getLocalization: (question: IFileUploadQuestion, key: string) => string,
  getPluralLocalization: (question: IFileUploadQuestion, key: string) => TranslationPlural
): HTMLDivElement => createElement({
  classNames: classNameDictionary.dropZoneInfo,
  content: [
    ...(question.maxFileSize
      ? [ createDropZoneInfoItemHtml(classNameDictionary, getLocalization(question, 'maxFileSize'), `${ question.maxFileSize } ${ getLocalization(question, 'mb') }`) ]
      : []
    ),
    ...(question.maxSize && !(question.maxFileSize && question.maxFileCount === 1)
      ? [ createDropZoneInfoItemHtml(classNameDictionary, getLocalization(question, 'maxSize'), `${ question.maxSize } ${ getLocalization(question, 'mb') }`) ]
      : []
    ),
    ...(question.minFileCount && !(question.minFileCount === question.maxFileCount)
      ? [ createDropZoneInfoItemHtml(
        classNameDictionary,
        getLocalization(question, 'minFileCount'),
        `${ question.minFileCount } ${ pluralize(
          question.minFileCount,
          'file',
          question,
          getCurrentLocale,
          getPluralLocalization
        ) }`
      ) ]
      : []
    ),
    ...(question.maxFileCount && !(question.minFileCount === question.maxFileCount)
      ? [ createDropZoneInfoItemHtml(
        classNameDictionary,
        getLocalization(question, 'maxFileCount'),
        `${ question.maxFileCount } ${ pluralize(
          question.maxFileCount,
          'file',
          question,
          getCurrentLocale,
          getPluralLocalization
        ) }`
      ) ]
      : []
    ),
    ...(question.minFileCount && question.maxFileCount && question.minFileCount === question.maxFileCount
      ? [ createDropZoneInfoItemHtml(
        classNameDictionary,
        getLocalization(question, 'exactlyFileCount'),
        `${ question.maxFileCount } ${ pluralize(
          question.maxFileCount,
          'file',
          question,
          getCurrentLocale,
          getLocalization as (question: IFileUploadQuestion, key: string) => TranslationPlural
        ) }`
      ) ]
      : []
    ),
    ...[
      createDropZoneInfoItemHtml(
        classNameDictionary,
        getLocalization(question, 'acceptedTypes'),
        question.acceptedTypes || acceptedTypesString
      )
    ]
  ]
});
