import {
  ForwardRefExoticComponent,
  MutableRefObject,
  PropsWithoutRef,
  RefAttributes
} from 'react';
import { IdTextPair, OptGroupData, OptionData } from 'select2';
import { Filters, SortingRule } from 'react-table';
import { RouteListType } from '@router/configuration';
import { ComponentListType } from '@mydse/typings';

export enum TaskType {
  ACADEMY = 'ACADEMY',
  ACADEMY_INVITE_EMPLOYEE = 'ACADEMY_INVITE_EMPLOYEE',
  DPO_NOMINATION = 'DPO_NOMINATION',
  DPO_PRIVACY_POLICY = 'DPO_PRIVACY_POLICY',
  DPO_NOTIFICATION = 'DPO_NOTIFICATION',
  DEFINE_TEAM = 'DEFINE_TEAM',
  PROCESS_SELECTION = 'PROCESS_SELECTION',
  ADDITIONAL_PROCESS_SELECTION = 'ADDITIONAL_PROCESS_SELECTION',
  PROCESS_CONTEXT = 'PROCESS_CONTEXT',
  AUDIT_CONCEPT = 'AUDIT_CONCEPT',
  PROCESS_DETAIL = 'PROCESS_DETAIL,',
  ACTION = 'ACTION',
}

export type TaskTypeType = keyof typeof TaskType;

export enum TaskTypeEncode {
  DPO_NOMINATION = 'dpoNomination',
  DPO_PRIVACY_POLICY = 'dpoPrivacyPolicy',
  DPO_NOTIFICATION = 'dpoNotification',
  DEFINE_TEAM = 'defineTeam',
  PROCESS_SELECTION = 'processSelection',
  ADDITIONAL_PROCESS_SELECTION = 'additionalProcessSelection',
  PROCESS_CONTEXT = 'processContext',
  AUDIT_CONCEPT = 'auditConcept',
  ACTION = 'action',
  PROCESS_DETAIL = 'processDetail',
  ACADEMY = 'academy',
  ACADEMY_INVITE_EMPLOYEE = 'academyInviteEmployee',
}
export enum TaskTypeDecode {
  dpoNomination = 'DPO_NOMINATION',
  dpoPrivacyPolicy = 'DPO_PRIVACY_POLICY',
  dpoNotification = 'DPO_NOTIFICATION',
  defineTeam = 'DEFINE_TEAM',
  processSelection = 'PROCESS_SELECTION',
  additionalProcessSelection = 'ADDITIONAL_PROCESS_SELECTION',
  processContext = 'PROCESS_CONTEXT',
  auditConcept = 'AUDIT_CONCEPT',
  action = 'ACTION',
  processDetail = 'PROCESS_DETAIL',
  academy = 'ACADEMY',
  academyInviteEmployee = 'ACADEMY_INVITE_EMPLOYEE',
}

export enum TaskTypeGroup {
  ACTION = 'ACTION',
  AUDIT = 'AUDIT',
  PROCESS_SELECTION = 'PROCESS_SELECTION',
  PROCESS_CONTEXT = 'PROCESS_CONTEXT',
  GENERAL = 'GENERAL',
  TOM = 'TOM',
  ACADEMY = 'ACADEMY',
  ACADEMY_INVITE_EMPLOYEE = 'ACADEMY_INVITE_EMPLOYEE',
}

export type RouteDictionary<T> = {
  [key in RouteListType]?: T;
};

export type SortDirection = 'asc' | 'desc';

export interface IDictionaryResponse<T> {
  id: string;
  title: T;
}

export interface IFilter {
  id: string;
  value: any;
  pivotId?: string;
}

export interface ITableParameters<T extends {}> {
  page?: number;
  pageSize?: number;
  currentPage?: number;
  sortBy: Array<SortingRule<T>>;
  filters: Filters<T>;
}

export interface IErrorStreams extends Event {
  reconnectDelay: number;
}

export type OptionDataType = IdTextPair | OptGroupData | OptionData;

export interface IErrorResponse {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

export type RefType<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

export type FREC<P, E> = ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<E>>;

export type Namespace = string | string[];
export type NamespaceData = Namespace | Record<string, Namespace>;

export interface IComponentDictionary {
  [key: string]: ComponentListType | ComponentListType[];
}

export enum Errors {
  required = 'required',
  minLength = 'minLength',
  maxLength = 'maxLength',
  hasNoSymbol = 'hasNoSymbol',
  hasSymbol = 'hasSymbol',
  hasDigit = 'hasDigit',
  hasLowerCase = 'hasLowerCase',
  hasUpperCase = 'hasUpperCase',
  isEqual = 'isEqual',
  email = 'email',
  phone = 'phone',
  isExists = 'isExists',
  dpmRole = 'dpmRole',
  tpp = 'tpp',
}

export interface IFieldValidationResult {
  error: Errors;
  parameters?: Record<string, string | number>;
}

export type ErrorStatus
  = 400
  | 401
  | 403
  | 404
  | 500
  | 502
  | 'unknown';

export type Mapper<T, R> = (data: T) => R;
