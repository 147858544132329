import { TranslationInfo } from '@mydse/typings';
import { IDictionaryResponse } from '@interfaces';
import { dictionaryService } from '../../compatibility';
import { ICustomFunctionContext } from './index';

export const isInDictionary = (
  context: ICustomFunctionContext,
  dictionary: string,
  value: string | string[],
  every: boolean
): void => {
  dictionaryService
    .getData(dictionary)
    .subscribe((dictionaryData: Array<IDictionaryResponse<TranslationInfo>>) => {
      const dictionaryValueList = dictionaryData
        .map((item: IDictionaryResponse<TranslationInfo>) => item.id);
      const isInDictionaryValues = Array.isArray(value)
        ? every
          ? value.every((item: string) => dictionaryValueList.includes(item))
          : value.some((item: string) => dictionaryValueList.includes(item))
        : dictionaryValueList.includes(value.toString());
      context.returnResult(isInDictionaryValues);
    });
};
