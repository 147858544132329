import {
  DataBreachType,
  DataBreach,
  IDataBreachService
} from '@services';
import { dataBreachApiService, surveyApiService } from '@services/api';
import { defaultPageSize, ITableState } from '@mydse/design-system';

export const getDataBreachInitialState = (type: DataBreachType): Partial<ITableState<DataBreach>> => ({
  hiddenColumns: [
    'awarenessFrom',
    'awarenessTo',
    'createdFrom',
    'createdTo',
    'closedFrom',
    'closedTo'
  ],
  pageIndex: 0,
  pageSize: defaultPageSize,
  sortBy: [
    {
      id: 'awarenessAt',
      desc: true
    }
  ],
  filters: [ {
    id: 'status',
    value: type
  } ]
});

export const dataBreachService: IDataBreachService = {
  getDataBreachInitialState,
  getActiveDataBreachCount: dataBreachApiService.getActiveDataBreachCount.bind(dataBreachApiService),
  getDataBreachCollection: dataBreachApiService.getDataBreachCollection.bind(dataBreachApiService),
  getDataBreach: surveyApiService.getDataBreach.bind(surveyApiService),
  updateDataBreachStatus: dataBreachApiService.updateDataBreachStatus.bind(dataBreachApiService),
  deleteDataBreach: dataBreachApiService.deleteDataBreach.bind(dataBreachApiService)
};
