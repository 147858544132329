import { TFunction } from 'react-i18next';
import { Column } from 'react-table';
import { TranslationInfo, Locale } from '@mydse/typings';
import { IDictionaryResponse } from '@interfaces';
import { i18n, AssociatedCompany, SystemsType } from '@services';
import { Header, ValueCell } from '@mydse/design-system';
import { MergedCell } from '@mydse/design-system';
import { CountryCell } from '../Renderers';

const getAssociatedCompaniesColumnList = (
  t: TFunction,
  systemsType: SystemsType,
  countryList: Array<IDictionaryResponse<TranslationInfo>>
): Array<Column<AssociatedCompany>> => [
  {
    accessor: 'name',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: ValueCell()
  },
  {
    id: 'streetAddress',
    accessor: 'street',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: MergedCell({ columnNameList: [ 'street', 'houseNumber' ] })
  },
  {
    accessor: 'zip',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'city',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    accessor: 'country',
    width: 'auto' as unknown as number,
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: CountryCell(countryList, i18n.language as Locale)
  }
];

export default getAssociatedCompaniesColumnList;
