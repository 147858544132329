import { TranslationInfo } from '@mydse/typings';
import { IDictionaryResponse } from '@interfaces';
import { Question, SurveyError, PageModel } from '@shared/surveyJs/reexport';

type DragEventHandler = (event: DragEvent) => void;

interface IHandlerDictionary {
  dragenter: DragEventHandler;
  dragleave: DragEventHandler;
  dragover: DragEventHandler;
  drop: DragEventHandler;
}
export interface IFileUploadQuestion extends Question {
  acceptedTypes?: string;
  maxSize?: string;
  maxFileSize?: string;
  minFileCount?: number;
  maxFileCount?: number;
  totalSize: number;
  fileCount: number;
  stateItemList: IStateItem[];
  value: IFileUploadQuestionValue[];
  errorList: FileUploadWidgetError[];
  isUploadingFiles: boolean;
  handlerDictionary: IHandlerDictionary;
  findElement: <T extends HTMLElement>(name: string) => null | T;
  showError: (text: string) => void;
  hideError: () => void;
  tags: string[];
}
export interface IFileUploadQuestionValue {
  name: string;
  type: string;
  size: number;
  content: null | string;
  tags: string[];
}
export interface IOnSettingQuestionErrors<T extends Question> {
  question: T;
  errors: SurveyError[];
}
export interface IOnSettingQuestionErrors<T extends Question> {
  question: T;
  errors: SurveyError[];
}

export interface IOnValidatedErrorsOnCurrentPage {
  errors: SurveyError[];
  page: PageModel;
  questions: Question[];
}

export enum Status {
  ERROR,
  WAITING,
  LOADING,
  UPLOADED,
  PRELOADED,
}
export enum FileError {
  TYPE = 'TYPE',
  OVERSIZE = 'OVERSIZE',
  DUPLICATE = 'DUPLICATE',
  UPLOAD = 'UPLOAD'
}

export enum FileUploadWidgetError {
  REQUIRED = 'REQUIRED',
  MIN = 'MIN',
  MAX = 'MAX',
  OVERSIZE = 'OVERSIZE',
  HAS_ERRORS = 'HAS_ERRORS'
}

export enum DocumentFileType {
  PDF = 'PDF',
  DOC = 'DOC',
  DOCX = 'DOCX',
  XLS = 'XLS',
  XLSX = 'XLSX',
  OTHER = 'OTHER',
}

export interface IAttachment {
  id: string;
  type: DocumentType;
  fileName: string;
  fileType: DocumentFileType;
  url: string;
  tags: IDictionaryResponse<TranslationInfo>;
  demo: boolean;
  createdAt: string;
}

export interface IStateItemData {
  element: HTMLLIElement;
  fileName: string;
  name: string;
  extension: string;
  type: string;
  size: number;
  loadingPercent: number;
  request: null | number | XMLHttpRequest;
  status: Status;
  errorList: FileError[];
}

export interface IStateItem {
  data: IStateItemData;
  file: null | File;
  attachment: null | IAttachment;
  value: null | IFileUploadQuestionValue;
}
