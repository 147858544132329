import { Observable } from 'rxjs';
import {
  DataStorage,
  DataStorageData,
  InstrumentData,
  Software,
  SoftwareData,
  ThirdParty,
  ThirdPartyData
} from '@services';
import { Api } from '@services/api';
import { Collection } from '@mydse/typings';

const apiUrl = '/api/survey/asset';

class AssetManagementApiService extends Api {
  public getThirdPartyCollection(parameters?: Record<string, string | boolean>): Observable<Collection<ThirdParty>> {
    return this.get<Collection<ThirdParty>>({
      url: 'third-party',
      parameters: { pageSize: 1000, ...parameters }
    });
  }
  public getThirdParty(id: string): Observable<ThirdParty> {
    return this.get<ThirdParty>({
      url: `third-party/${ id }`
    });
  }
  public createThirdParty(body: ThirdPartyData): Observable<ThirdParty> {
    return this.post<ThirdParty>({
      url: 'third-party',
      body
    });
  }
  public updateThirdParty(id: string, body: Partial<ThirdParty>): Observable<ThirdParty> {
    return this.patch<ThirdParty>({
      url: `third-party/${ id }`,
      body
    });
  }
  public getInstrumentDataList(country: string): Observable<InstrumentData[]> {
    return this.get({
      url: `third-party/instruments?country=${ country }`
    });
  }

  public getSoftwareCollection(parameters?: Record<string, string | boolean>): Observable<Collection<Software>> {
    return this.get<Collection<Software>>({
      url: 'software',
      parameters
    });
  }
  public getSoftware(id: string): Observable<Software> {
    return this.get<Software>({
      url: `software/${ id }`
    });
  }
  public createSoftware(body: SoftwareData): Observable<Software> {
    return this.post<Software>({
      url: 'software',
      body
    });
  }
  public updateSoftware(id: string, body: Partial<Software>): Observable<Software> {
    return this.post<Software>({
      url: `software/${ id }`,
      body
    });
  }

  public getDataStorageCollection(parameters?: Record<string, string | boolean>): Observable<Collection<DataStorage>> {
    return this.get<Collection<DataStorage>>({
      url: 'data-storage',
      parameters
    });
  }
  public getDataStorage(id: string): Observable<DataStorage> {
    return this.get<DataStorage>({
      url: `data-storage/${ id }`
    });
  }
  public createDataStorage(body: DataStorageData): Observable<DataStorage> {
    return this.post<DataStorage>({
      url: 'data-storage',
      body
    });
  }
  public updateDataStorage(id: string, body: Partial<DataStorage>): Observable<DataStorage> {
    return this.post<DataStorage>({
      url: `data-storage/${ id }`,
      body
    });
  }
}

export const assetManagementApiService = new AssetManagementApiService(apiUrl);
