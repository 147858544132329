import i18n from 'i18next';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { sidebarService } from '@mydse/react-ui';
import { Locale, TranslationInfo } from '@mydse/typings';
import { IDictionaryResponse } from '@mydse/design-system';
import { SortItem } from '@hooks';
import { dictionaryService } from '@services';
import { assetManagementApiService } from '@services/api';
import { Asset, AssetManagementService, AssetManagementSidebarData, GetCountryTitleOptions } from './assetManagement.typings';
import { getIcon, getNextStep, getPriority, getPriorityPreset } from './utilities';

export const assetManagementInitialState: SortItem<Asset>[] = [ {
  sortField: 'name',
  sortDirection: 'asc'
} ];

const sidebar = new BehaviorSubject<undefined | AssetManagementSidebarData>(undefined);
const sidebar$ = sidebar.asObservable();

const showNext = new BehaviorSubject<undefined | boolean>(undefined);

const openSidebar = (data: AssetManagementSidebarData): void => {
  if (sidebar.value?.type === data.type && sidebar.value?.asset.id === data.asset.id) {
    return;
  }
  showNext.next(undefined);
  sidebar.next(data);
};

const closeSidebar = (): void => {
  if (typeof sidebar.value !== 'undefined') {
    sidebar.next(undefined);
  }
};
sidebarService.isOpen$
  .subscribe((isOpen: boolean): void => {
    if (!isOpen && !!sidebar.value) {
      closeSidebar();
    }
  });

let dictionaryCache: Array<IDictionaryResponse<TranslationInfo>>;
const getCountryTitleFromDictionaryData = ({
  country,
  locale,
  dictionaryData
}: GetCountryTitleOptions): string => dictionaryData
  .find((item: IDictionaryResponse<TranslationInfo>): boolean => item.id === country)?.title[locale] || '';

const getCountryTitle = (country: string): Promise<string> => new Promise((resolve) => {
  if (!dictionaryCache) {
    dictionaryService
      .getData('country')
      .pipe(take(1))
      .subscribe((dictionaryData: Array<IDictionaryResponse<TranslationInfo>>) => {
        dictionaryCache = dictionaryData;
        resolve(getCountryTitleFromDictionaryData({ country, locale: i18n.language as Locale, dictionaryData }));
      });
  } else {
    resolve(getCountryTitleFromDictionaryData({ country, locale: i18n.language as Locale, dictionaryData: dictionaryCache }));
  }
});

export const assetManagementService: AssetManagementService = {
  sidebar,
  sidebar$,
  openSidebar,
  closeSidebar,

  showNext,

  getCountryTitle,

  getIcon,
  getNextStep,

  getPriority,
  getPriorityPreset,

  getThirdPartyCollection: assetManagementApiService.getThirdPartyCollection.bind(assetManagementApiService),
  getThirdParty: assetManagementApiService.getThirdParty.bind(assetManagementApiService),
  createThirdParty: assetManagementApiService.createThirdParty.bind(assetManagementApiService),
  updateThirdParty: assetManagementApiService.updateThirdParty.bind(assetManagementApiService),
  getInstrumentDataList: assetManagementApiService.getInstrumentDataList.bind(assetManagementApiService),

  getSoftwareCollection: assetManagementApiService.getSoftwareCollection.bind(assetManagementApiService),
  getSoftware: assetManagementApiService.getSoftware.bind(assetManagementApiService),
  createSoftware: assetManagementApiService.createSoftware.bind(assetManagementApiService),
  updateSoftware: assetManagementApiService.updateSoftware.bind(assetManagementApiService),

  getDataStorageCollection: assetManagementApiService.getDataStorageCollection.bind(assetManagementApiService),
  getDataStorage: assetManagementApiService.getDataStorage.bind(assetManagementApiService),
  createDataStorage: assetManagementApiService.createDataStorage.bind(assetManagementApiService),
  updateDataStorage: assetManagementApiService.updateDataStorage.bind(assetManagementApiService)
};
