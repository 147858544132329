import { QueryOptions } from 'select2';
import { defaultDropdownPageSize } from '@mydse/design-system';

export interface IAjaxData {
  page: number;
  pageSize: number;
  search?: string;
}

const ajaxData = (pageSize?: number) => (params: QueryOptions): IAjaxData => {
  return {
    page: params.page || 1,
    pageSize: pageSize || defaultDropdownPageSize,
    search: params.term
  };
};

export default ajaxData;
