import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  TableInstance,
  Column,
  UseSortByInstanceProps,
  UseSortByOptions,
  UseTableOptions,
  UseFiltersOptions,
  UsePaginationOptions,
  UseTableInstanceProps,
  UsePaginationInstanceProps,
  UseFiltersInstanceProps,
  TableState,
  UseSortByState,
  UseFiltersState,
  UsePaginationState,
  useGlobalFilter,
  UseGlobalFiltersState,
  UseGlobalFiltersOptions,
  UseGlobalFiltersInstanceProps
} from 'react-table';
import { IActivity } from '@services';
import { isEmpty } from '@utilities';
import {
  TableHead,
  TableBody,
  TablePagination,
  debounce,
  tableStyles,
  classNameArrayToString, useDataAttributes, DataAttributesType
} from '@mydse/design-system';
import { isEqual } from '@mydse/utilities';
import ManualFilter from './Renderers/ManualFilter';

const styles = tableStyles.default;

interface ITableOptions<D extends object = {}>
  extends UseTableOptions<D>,
  UseGlobalFiltersOptions<D>,
  UseFiltersOptions<D>,
  UsePaginationOptions<D>,
  UseSortByOptions<D> {}

type AnalyticsTableOptions = ITableOptions<IActivity>;

interface ITableInstance<D extends object = {}>
  extends TableInstance<D>,
  UseTableInstanceProps<D>,
  UseFiltersInstanceProps<D>,
  UseGlobalFiltersInstanceProps<D>,
  UsePaginationInstanceProps<D>,
  UseSortByInstanceProps<D> {}

export interface ITableState<D extends object = {}>
  extends TableState<D>,
  UseGlobalFiltersState<D>,
  UseFiltersState<D>,
  UsePaginationState<D>,
  UseSortByState<D> {}

interface IOwnProps {
  columns: Array<Column<IActivity>>;
  data: IActivity[];
  pageCount: number;
  initialState: any;
  loading: boolean;
  fetchData: (state: Partial<ITableState<IActivity>>) => void;
  emptyText: string;
  dataAttributesDictionary?: DataAttributesType;
  total?: number;
}

type Props = IOwnProps;

const Table: FC<Props> = ({
  columns,
  data,
  pageCount: controlledPageCount,
  loading,
  fetchData,
  initialState,
  emptyText,
  dataAttributesDictionary = {},
  total
}) => {
  const { i18n } = useTranslation();
  const {
    test = { table: '' },
    guide = { table: '' }
  } = dataAttributesDictionary;
  const { dataAttributes } = useDataAttributes({ test, guide });
  const tableOptions: AnalyticsTableOptions = {
    columns,
    initialState,
    data,
    pageCount: controlledPageCount,
    manualFilters: true,
    manualSortBy: true,
    manualPagination: true,
    disableMultiSort: true,
    disableSortRemove: true,
    manualGlobalFilter: true
  };

  const tableInstance = useTable(tableOptions, useGlobalFilter, useFilters, useSortBy, usePagination);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    state,
    pageCount,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter
  } = tableInstance as ITableInstance<IActivity>;

  const { filters, pageIndex, pageSize, sortBy, globalFilter } = state as ITableState;
  const fetchDataDebounced = useCallback(debounce(fetchData, 200), []);
  useEffect(() => {
    fetchDataDebounced({ pageIndex, pageSize, sortBy, globalFilter });
  }, [ filters, pageIndex, pageSize, sortBy, globalFilter, i18n.language ]);

  const manualFilterElement = (
    <ManualFilter
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
    />
  );

  const classNameString = classNameArrayToString([
    styles.wrap,
    styles.inner
  ]);

  return (
    <div className={classNameString}>
      <table
        className={styles.table}
        {...dataAttributes}
        {...getTableProps()}
      >
        <TableHead
          headerGroups={headerGroups}
          manualFilterElement={manualFilterElement}
          hideFilters={data.length < 2 && isEqual(filters, initialState.filters) && isEmpty(globalFilter)}
          hideSorting={ data.length < 2 && isEqual(sortBy, initialState.sortBy) }
        />
        <TableBody
          data={data}
          loading={loading}
          page={page}
          prepareRow={prepareRow}
          emptyText={emptyText}
          columns={columns}
        />
      </table>
      <div className={styles.footer}>
        <TablePagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          pageSize={pageSize}
          total={total}
        />
      </div>
    </div>
  );
};

export default Table;
