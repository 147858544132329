import React, {
  Children,
  cloneElement,
  ComponentProps,
  MutableRefObject,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { DataAttributesType, useDataAttributes } from '@mydse/design-system';
import { classNameArrayToString } from '@mydse/utilities';
import { Tab, TabContent } from './index';

import './Tabs.styl';

export enum TabsPreset {
  default = 'default',
  material = 'material',
}

interface IOwnProps {
  children: ReactElement[];
  preset?: TabsPreset;
  title?: string | ReactElement;
  activeTab?: MutableRefObject<string>;
  disabledTabList?: string[];
  actions?: ReactElement | ReactElement[];
  onChange?: (tab: string) => void;
  tabFormatter?: (key: string) => string;
  dataAttributesDictionary?: DataAttributesType;
}

type Props = IOwnProps & ComponentProps<any>;

const Tabs = ({
  children,
  preset = TabsPreset.material,
  title,
  activeTab,
  disabledTabList = [],
  actions,
  onChange,
  tabFormatter,
  dataAttributesDictionary = {},
  ...props
}: Props): ReactElement => {
  const {
    test = { tabs: '' },
    guide = { tabs: '' }
  } = dataAttributesDictionary;
  const { dataAttributes } = useDataAttributes({ test, guide });
  const tabListDataAttributesValue = { tabList: '' };
  const { dataAttributes: tabListDataAttributes } = useDataAttributes({
    test: tabListDataAttributesValue,
    guide: tabListDataAttributesValue
  });

  const getEnabledTab = children
    .reduce(
      (result: string, child: ReactElement) => {
        if (result) {
          return result;
        }
        if (!child.props.disabled) {
          return child.props['data-key'];
        }
        return result;
      },
      ''
    );

  const getTabValue = (active?: string) =>
    typeof active !== 'undefined'
      ? (
        disabledTabList
          .includes(active)
          ? getEnabledTab
          : active
      )
      : getEnabledTab;

  const [ tab, setTab ] = useState<string>(getTabValue(activeTab));

  useEffect(() => {
    setTab(getTabValue(activeTab));
  }, [ activeTab ]);

  const clickHandler = (key: string) => {
    if (key === tab) {
      return;
    }
    setTab(key);
    if (typeof onChange !== 'undefined') {
      onChange(key);
    }
  };

  const titleElement = title
    ? (
      <li key="title" className="tabsTitle">
        { title }
      </li>
    )
    : null;

  const actionsElement = actions
    ? (
      <li key="title" className="tabsActions">
        { actions }
      </li>
    )
    : null;

  const labelElementList = children.map(
    (child: ReactElement): ReactElement => {
      const key = typeof tabFormatter === 'undefined'
        ? child.props['data-key']
        : tabFormatter(child.props['data-key']);
      const label = child.props['data-label'];
      const classNameValue = child.props['data-className'];
      const disabled = disabledTabList.includes(key);
      return (
        <Tab
          key={ key }
          data-key={ key }
          label={ label }
          active={ tab }
          disabled={ disabled }
          className={ classNameValue }
          clickHandler={ clickHandler }
        />
      );
    }
  );

  const childrenAddPermissionProperties = (
    children: ReactElement[],
    demo: boolean = false,
    permissionClassName?: string
  ): ReactElement[] =>
    Children.map(children, (item: ReactElement) => {
      const className = [ item.props?.className, permissionClassName ]
        .filter(Boolean)
        .join('');
      return cloneElement(item, { ...item.props, className, demo: demo.toString() });
    });

  const items = children
    .map((child: ReactElement): ReactElement | null => {
      const {
        'data-key': key,
        'data-reload': reload,
        demo,
        className: permissionClassName
      } = child.props;
      const tabKey = typeof tabFormatter === 'undefined'
        ? key
        : tabFormatter(key);
      const childrenElements: ReactElement[] = child.props.children;
      if (tabKey === tab) {
        return (
          <TabContent hidden={ false } key={ tabKey }>
            { childrenAddPermissionProperties(childrenElements, demo, permissionClassName) }
          </TabContent>
        );
      }
      if (reload) {
        return null;
      }
      return (
        <TabContent hidden={ true } key={ tabKey }>
          { childrenAddPermissionProperties(childrenElements, demo, permissionClassName) }
        </TabContent>
      );
    });

  const className = classNameArrayToString([
    props.className,
    preset,
    'tabContainer'
  ]);

  return (
    <div
      { ...props }
      { ...dataAttributes }
      className={ className }
    >
      <ul
        className="tabList"
        { ...tabListDataAttributes }
      >
        { [ titleElement, ...labelElementList, actionsElement ] }
      </ul>
      { items }
    </div>
  );
};

export default Tabs;
