import { ComponentList, FunctionalArea, pageComponentMapping } from '@mydse/typings';
import { camelCaseToSnakeCase, Case } from '@mydse/utilities';
import { permissionService } from '@services';

export const getAvailableFunctionalAreaList = (): FunctionalArea[] => {
  const notFunctionalAreaComponentList = [ ComponentList.rpa, ComponentList.customProcesses ];
  const permission = permissionService.getComponentsPermission('processes');
  return (pageComponentMapping.processes as ComponentList[])
    .filter((item: ComponentList) => !notFunctionalAreaComponentList.includes(item))
    .reduce((functionalAreaList: FunctionalArea[], item: ComponentList) => {
      const permissionValue = permission[item];
      if (typeof permissionValue !== 'undefined') {
        functionalAreaList.push(camelCaseToSnakeCase(item, Case.upper) as FunctionalArea);
      }
      return functionalAreaList;
    }, []);
};
