import React, { FC, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { detect } from 'detect-browser';
import { Locale } from '@mydse/typings';
import { Modal } from '@mydse/design-system';
import { INewsItem } from '@services';
import { Footer, LanguageSwitcher } from '@layout/components';
import { Carousel, NewsItem } from '@shared/components';
import { Logo, UpgradeBrowser } from './components';

import styles from './AuthLayout.styl';

interface IOwnProps {
  children: ReactNode | ReactElement;
  newsList?: INewsItem[];
}

type Props = IOwnProps;

const browser = detect();
const AuthLayout: FC<Props> = ({ children, newsList = [] }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language as Locale;
  const titleList = newsList.map((item: INewsItem) => item.title[locale]);

  const itemList: ReactElement[] = newsList
    .map((item: INewsItem) => (
      <NewsItem
        key={item.id}
        item={item}
        locale={locale}
        className={styles.newsItem}
      />
    ));
  const news = newsList?.length
    ? (
      <div className={styles.news}>
        <Carousel
          className={styles.carousel}
          dotsClassName="login-dots"
          titleList={titleList}
          newsList={newsList}
          autoRotationTimeout={5000}
        >
          {itemList}
        </Carousel>
      </div>
    )
    : null;
  const browserNotSupport = browser?.name === 'ie';
  const upgradeBrowser = browserNotSupport
    ? <UpgradeBrowser />
    : null;

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.content}>
          {news}
          <div className={styles.language}>
            <LanguageSwitcher />
          </div>
          <div className={`${ styles.formWrap } ie-authLayout`}>
            {upgradeBrowser}
            <div className={styles.form}>
              <Logo />
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Modal />
    </>
  );
};

export default AuthLayout;
