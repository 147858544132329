import { AjaxError } from 'rxjs/ajax';
import { ErrorStatus } from '@interfaces';
import { notificationService } from '@mydse/react-ui';

const errorStatusDictionary: Partial<Record<ErrorStatus, string>> = {
  400: 'error.somethingWrong',
  401: 'error.sessionExpired',
  502: 'error.updating',
  unknown: 'error.unknown'
};

const javaErrorHandler = (error: AjaxError): void => {
  const response = error.response;
  const options = {
    toastId: response.code
  };
  const dataAttributesDictionary = {
    test: { notificationError: `errorInterceptor-java-${ response.code }` },
    guide: { notificationError: `errorInterceptor-java-${ response.code }` }
  };
  notificationService.error({
    titleTranslationKey: `error.${ response.code }`,
    textTranslationKey: 'error.message',
    textTranslationParams: response,
    options,
    dataAttributesDictionary
  });
};

const defaultErrorHandler = ({ status }: AjaxError): void => {
  const titleTranslationKey = status in errorStatusDictionary
    ? errorStatusDictionary[status as ErrorStatus]
    : errorStatusDictionary.unknown;
  const options = {
    toastId: status
  };
  const dataAttributesDictionary = {
    test: { notificationError: `errorInterceptor-default-${ status }` },
    guide: { notificationError: `errorInterceptor-default-${ status }` }
  };
  notificationService.error({
    titleTranslationKey,
    options,
    dataAttributesDictionary
  });
};

export const interceptorErrorHandler = (error: AjaxError): void => {
  if (error.response) {
    if (typeof error.response.message !== 'undefined') { // TODO add isErrorResponse function
      return javaErrorHandler(error);
    }
  }
  return defaultErrorHandler(error);
};
// TODO error typings
