import React, { FC, ReactElement, Suspense, useEffect, useState } from 'react';
import { Namespace, useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentPermissionData, Locale, ProductType } from '@mydse/typings';
import { classNameArrayToString, LoadingSuspend } from '@mydse/design-system';
import { Case, snakeCaseToKebabCase } from '@mydse/utilities';
import { getDefaultTab, replaceHistoryState } from '@router';
import {
  ActivityMessage,
  ComplianceScoreData,
  FunctionalAreaScoreData,
  IActivity,
  INewsItem,
  ITask,
  ITaskCount,
  productService,
  streamService,
  TaskCount,
  TaskCountDictionary,
  TaskTabDictionary
} from '@services';
import { Carousel, NewsItem, PermissionGuard, Widget } from '@shared/components';
import {
  ActivitiesWidget,
  AreaChartWidget,
  ComplianceScoreWidget,
  TasksWidget
} from '..';

import styles from './Dashboard.styl';

const Error = React.lazy(() => import(/* webpackChunkName: "Error" */ '@pages/Error/Error/Error'));

interface OwnProps {
  namespace: Namespace;
  permission: ComponentPermissionData;
  routeTab: null | TaskCount;
  taskCountData: TaskTabDictionary<number>;
  basicTaskList: ITask[];
  assessmentTaskData: TaskCountDictionary<ITaskCount>;
  complianceScore: ComplianceScoreData;
  activityList: IActivity[];
  functionalAreaScore: FunctionalAreaScoreData;
  newsList: INewsItem[];
}

type Props = OwnProps;

const Dashboard: FC<Props> = ({
  namespace,
  permission,
  routeTab,
  taskCountData,
  basicTaskList,
  assessmentTaskData,
  complianceScore,
  activityList,
  functionalAreaScore,
  newsList
}) => {
  const { i18n, t } = useTranslation(namespace);
  const locale = i18n.language as Locale;
  const titleList = newsList.map((item: INewsItem) => item.title[locale]);

  const defaultTab: TaskCount = getDefaultTab<TaskCount>('dashboard', permission);
  const routeTabValue = routeTab || null;
  const [ tab, setTab ] = useState<TaskCount>(routeTabValue || defaultTab);
  if (tab === TaskCount.ASSESSMENT) {
    if (typeof permission.assessmentTasksWidget === 'undefined') {
      return (
        <Suspense fallback={<LoadingSuspend />}>
          <Error errorCode={404} />
        </Suspense>
      );
    } else if (!permission.assessmentTasksWidget.read) {
      return (
        <Suspense fallback={<LoadingSuspend />}>
          <Error errorCode={403} />
        </Suspense>
      );
    }
  }

  const changeTabHandler = (value: TaskCount): void => {
    setTimeout(() => setTab(value));
    if (value === defaultTab) {
      replaceHistoryState('dashboard');
    } else {
      replaceHistoryState('dashboardTab', { routeTab: snakeCaseToKebabCase(value, Case.lower) });
    }
  };

  const [ activity, setActivity ] = useState<IActivity>();
  const [ activities, setActivities ] = useState<IActivity[]>(activityList);

  const [ product, setProduct ] = useState<ProductType>(productService.getCurrentProduct()!);

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    if (activity) {
      setActivities([ activity, ...activities ].slice(0, 10));
    }
  }, [ activity ]);
  useEffect(() => {
    productService
      .currentProduct$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((payload: null | ProductType): void => {
        if (payload) {
          setProduct(payload);
        }
      });
    streamService
      .subscribeActivityMessage()
      .pipe(takeUntil(unsubscribe$))
      .subscribe((message: ActivityMessage): void => {
        setActivity(message.payload);
      });
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  const tasksElement = (
    <TasksWidget
      namespace={namespace}
      permission={permission}
      product={product}
      taskCountData={taskCountData}
      initialBasicTaskList={basicTaskList}
      initialAssessmentTaskData={assessmentTaskData}
      tab={tab}
      changeTabHandler={changeTabHandler}
    />
  );

  const isShowAreaWidget: boolean = !!permission && !!permission.complianceScoreWidget?.demo;
  const areaWidgetPermission = isShowAreaWidget
    ? permission
    : { read: false, write: false, demo: false };
  const areaElement = (
    <PermissionGuard permission={areaWidgetPermission} componentName="areaWidget">
      <Widget title={t('areaWidget.title')} name="areaWidget">
        <AreaChartWidget
          className={styles.areaScoreWidget}
          functionalAreaScore={functionalAreaScore}
        />
      </Widget>
    </PermissionGuard>
  );

  const itemList: ReactElement[] = newsList
    .map((item: INewsItem) => (
      <NewsItem
        key={item.id}
        item={item}
        locale={locale}
        preset="horizontal"
      />
    ));
  const newsElement = newsList?.length && !isShowAreaWidget
    ? (
      <Widget name="newsWidget" headless={true}>
        <Carousel
          className={classNameArrayToString([ styles.carousel, newsList?.length === 1 && styles.single ])}
          dotsClassName={styles.dots}
          titleList={titleList}
          newsList={newsList}
        >
          {itemList}
        </Carousel>
      </Widget>
    )
    : null;

  const contentElement = (
    <div className={styles.content}>
      <div className={styles.main}>
        {tasksElement}
        {areaElement}
        {newsElement}
      </div>
    </div>
  );

  const complianceScoreElement = (
    <PermissionGuard permission={permission} componentName="complianceScoreWidget">
      <Widget
        navigateTo={{ routeName: 'analytics', permissionFor: 'analytics' }}
        title={t('complianceScoreWidget.title')}
        name="complianceScoreWidget"
      >
        <ComplianceScoreWidget value={complianceScore} />
      </Widget>
    </PermissionGuard>
  );

  const navigateParameters = {
    routeName: 'analyticsTab',
    permissionFor: 'analytics',
    params: {
      routeTab: 'activities'
    }
  };
  const activitiesElement = (
    <PermissionGuard permission={permission} componentName="activitiesWidget">
      <Widget
        navigateTo={navigateParameters}
        title={t('activitiesWidget.title')}
        name="activitiesWidget"
        className={styles.activitiesWidget}
      >
        <ActivitiesWidget data={activities} />
      </Widget>
    </PermissionGuard>
  );

  const sidebarElement = product === 'privacy'
    ? (
      <div className={styles.sideBar}>
        {complianceScoreElement}
        {activitiesElement}
      </div>
    )
    : null;

  return (
    <div className={styles.wrap}>
      {contentElement}
      {sidebarElement}
    </div>
  );
};

export default Dashboard;
