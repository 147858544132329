import { isValidNumber } from 'libphonenumber-js';
import { IPhoneData } from '@mydse/design-system';
import type { Model } from '@shared/surveyJs/reexport';

const isEmpty = (value: any): boolean => {
  if (typeof value === 'undefined') {
    return true;
  }
  if (value === null) {
    return true;
  }
  if (value === '') {
    return true;
  }
  if (Array.isArray(value) && !value.length) {
    return true;
  }
  if (typeof value === 'object' && !Object.keys(value).length) {
    return true;
  }
  return false;
};

const isPhoneData = (value: any): boolean => {
  return typeof value.countryDialCode !== 'undefined' && typeof value.phoneNumber !== 'undefined';
};

const isValidPhone = (value: IPhoneData): boolean => {
  return isValidNumber(`+${ value.countryDialCode }${ value.phoneNumber }`);
};

const parseArray = (array: any[]): any[] => {
  return array
    .reduce(
      (result: any[], value: any) => {
        if (Array.isArray(value)) {
          const valueArray = parseArray(value);
          if (valueArray.length) {
            result.push(valueArray);
          }
          return result;
        }
        if (value !== null && typeof value === 'object') {
          const valueObject = parseObject(value);
          if (Object.keys(valueObject).length) {
            result.push(valueObject);
          }
          return result;
        }
        if (!isEmpty(value)) {
          result.push(value);
        }
        return result;
      },
      []
    );
};

const parseObject = (object: object): object => {
  if (!Object.keys(object).length) {
    return object;
  }
  return (Object.keys(object) as Array<keyof typeof object>)
    .reduce(
      (result: { [index: string]: any }, key: keyof typeof object) => {
        const value = object[key];
        if (Array.isArray(value)) {
          const valueArray = parseArray(value);
          if (valueArray.length) {
            result[key] = valueArray;
          }
          return result;
        }
        if (value !== null && typeof value === 'object') {
          if (isPhoneData(value)) {
            if (isValidPhone(value)) {
              result[key] = value;
            }
            return result;
          }
          const valueObject = parseObject(value);
          if (Object.keys(valueObject).length) {
            result[key] = valueObject;
          }
          return result;
        }
        if (!isEmpty(value)) {
          result[key] = value;
        }
        return result;
      },
      {}
    );
};

const getSurveyData = (survey: Model): object => {
  const surveyData = survey
    .getValuesKeys()
    .reduce(
      (result: Record<string, any>, key: string) => ({
        ...result,
        ...{ [key]: survey.getDataValueCore(survey.valuesHash, key) }
      }),
      {}
    );
  return parseObject(surveyData);
};

export default getSurveyData;

export const clone = <T>(value: T): T => typeof value === 'undefined'
  ? value
  : JSON.parse(JSON.stringify(value));
