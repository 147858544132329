import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState
} from 'react';
import { Subject } from 'rxjs';
import {
  take,
  takeUntil
} from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import {
  ITableState,
  Input,
  Loading,
  debounce
} from '@mydse/design-system';
import { Scrollbar } from '@mydse/react-ui';
import { companyService, Company, streamService } from '@services';
import { Collection } from '@mydse/typings';
import { CompanySwitcherItem } from '../index';

import styles from './CompanySwitcherModalContent.styl';

interface OwnProps {
  action: (companyId: number) => () => void;
}

type Props = OwnProps;

const CompanySwitcherModalContent: FC<Props> = ({ action }) => {
  const { t } = useTranslation();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ search, setSearch ] = useState<string>('');
  const [ update, setUpdate ] = useState<never[]>();
  const [ availableCompanies, setAvailableCompanies ] = useState<Company[]>([]);
  const [ currentCompany, setCurrentCompany ] = useState<null | Company>(null);

  const unsubscribe$ = new Subject<void>();
  useEffect(() => {
    companyService.company$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((company: Company | null) => setCurrentCompany(company));
    streamService.updateCompanyList$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(() => setUpdate([]));
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  const fetchData = (parameters: Partial<ITableState<Company>> = {}) => {
    setIsLoading(true);
    companyService.getAvailableCompanyCollection(parameters)
      .pipe(
        take(1),
        takeUntil(unsubscribe$)
      )
      .subscribe((data: Collection<Company>) => {
        setAvailableCompanies(data.elements);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData({
      filters: [ {
        id: 'name',
        value: search
      } ]
    });
  }, [ search, update ]);

  const debounceSearchOnChangeHandler = debounce(
    (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
    300
  );

  const searchElement = (
    <Input
      className={ styles.search }
      name="search"
      label={ t('companies.searchLabel') }
      placeholder={ t('companies.searchPlaceholder') }
      useFormGroup={ false }
      dataAttributesDictionary={ {
        test: { 'company-switcher': 'search' },
        guide: { 'company-switcher': 'search' }
      } }
      defaultValue={ search }
      onChange={ debounceSearchOnChangeHandler }
    />
  );

  if (!currentCompany) {
    return null;
  }

  const listItemElement = availableCompanies.map(
    (item: Company, key: number) => (
      <CompanySwitcherItem
        key={ key }
        item={ item }
        currentCompany={ currentCompany }
        onClick={ action(item.id) }
      />
    )
  );

  const emptyElement = availableCompanies.length
    ? null
    : <div className={ styles.emptyText }>
      { t('companies.noResults') }
    </div>;

  const loadingElement = isLoading
    ? <Loading size={ 32 }/>
    : null;

  const listElement = (
    <Scrollbar
      id="companySwitcher"
      usePadding={ false }
      scrollYOffset={ {
        top: 8,
        bottom: 8
      } }
      minHeight={ 120 }
    >
      <div className={ styles.listItem }>
        { listItemElement }
        { emptyElement }
        { loadingElement }
      </div>
    </Scrollbar>
  );

  return (
    <div className={ styles.content }>
      { searchElement }
      <div className={ styles.divider }></div>
      { listElement }
    </div>
  );
};

export default CompanySwitcherModalContent;
