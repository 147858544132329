import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Collection, CollectionParameters, DpoUser } from '@mydse/typings';
import { defaultPageSize } from '@mydse/design-system';
import { TeamService } from '@services';
import { teamApiService } from '@services/api';

const getDpoCollection = (parameters: CollectionParameters = {}): Observable<Collection<DpoUser>> => teamApiService
  .getDpo()
  .pipe(
    map((user: null | DpoUser): Collection<DpoUser> => {
      const elements = user ? [ user ] : [];
      const totalResult = elements.length;
      return {
        elements,
        totalResult,
        pageSize: parameters.pageSize || defaultPageSize,
        currentPage: parameters.page || 1,
        sortField: parameters.sortField || 'name',
        sortDirection: parameters.sortDirection || 'asc'
      };
    })
  );

export const teamService: TeamService = {
  getDpo: teamApiService.getDpo.bind(teamApiService),
  getDpoCollection,
  getAvailableUserList: teamApiService.getAvailableUserList.bind(teamApiService),
  getTeam: teamApiService.getTeam.bind(teamApiService),
  inviteTeamMember: teamApiService.inviteTeamMember.bind(teamApiService),
  assignTeamMember: teamApiService.assignTeamMember.bind(teamApiService),
  inviteAcademyUser: teamApiService.inviteAcademyUser.bind(teamApiService),
  inviteAcademyUserGroup: teamApiService.inviteAcademyUserGroup.bind(teamApiService),
  assignAcademyUser: teamApiService.assignAcademyUser.bind(teamApiService),
  withdrawUserInvitation: teamApiService.unAssignAcademyUser.bind(teamApiService),
  checkUserInvitationToken: teamApiService.checkUserInvitationToken.bind(teamApiService),
  getTeamByFunctionalArea: teamApiService.getTeamByFunctionalArea.bind(teamApiService),
  inviteDeputy: teamApiService.inviteDeputy.bind(teamApiService),
  assignDeputy: teamApiService.assignDeputy.bind(teamApiService),
  unAssignDeputy: teamApiService.unAssignDeputy.bind(teamApiService),
  inviteAssistant: teamApiService.inviteAssistant.bind(teamApiService),
  assignAssistant: teamApiService.assignAssistant.bind(teamApiService),
  unAssignAssistant: teamApiService.unAssignAssistant.bind(teamApiService),
  retireUser: teamApiService.retireUser.bind(teamApiService)
};
