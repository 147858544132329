import { Observable } from 'rxjs';
import { CurrentUser } from '@mydse/typings';
import { Api } from '@services/api';

const apiUrl = '/api/survey/user/pendo';

class PendoApiService extends Api {
  public updateAnalyticsAllowed(analyticsAllowed: boolean): Observable<CurrentUser> {
    return this.post<CurrentUser>({ parameters: { analyticsAllowed } });
  }
}

export const pendoApiService = new PendoApiService(apiUrl);
