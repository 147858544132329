import {
  IRiskCase,
  IRiskService,
  RiskState
} from '@services';
import { riskApiService, surveyApiService } from '@services/api';
import { defaultPageSize, ITableState } from '@mydse/design-system';

export const getRiskInitialState = (state: RiskState): Partial<ITableState<IRiskCase>> => ({
  pageIndex: 0,
  pageSize: defaultPageSize,
  sortBy: [
    {
      id: state === RiskState.ACTIVE
        ? 'createdAt'
        : 'closedAt',
      desc: true
    }
  ],
  filters: [ {
    id: 'state',
    value: state
  } ]
});

export const riskService: IRiskService = {
  getRiskInitialState,
  getActiveRiskCount: riskApiService.getActiveRiskCount.bind(riskApiService),
  getRiskCollection: riskApiService.getRiskCollection.bind(riskApiService),
  getRisk: surveyApiService.getRisk.bind(surveyApiService),
  updateRiskState: riskApiService.updateRiskState.bind(riskApiService),
  deleteRisk: riskApiService.deleteRisk.bind(riskApiService),
  exportRisk: riskApiService.exportRisk.bind(riskApiService)
};
