import { getStaticTestDocumentResourceUrl } from '@mydse/utilities';
import {
  FileError,
  FileUploadWidgetError,
  IAttachment,
  IFileUploadQuestion,
  IFileUploadQuestionValue,
  IStateItem,
  IStateItemData,
  Status
} from './fileUpload.typings';
import {
  createDropZoneHtml,
  createDropZoneInfoHtml,
  createFileBlockHtml,
  createFileListItemHtml
} from './markup';
import {
  getConvertedSize,
  megabytesToBytes,
  parseDropEvent,
  parseFileUploadInput,
  validateDragEnter
} from './utilities';
import {
  IProperty,
  IPropertyChangeOption,
  WidgetEditorLocalization,
  WidgetLocalization,
  WidgetPluralLocalization
} from '../interfaces';
import { addClass, createElement, removeClass, setClass } from '../common';
import { notification } from '../compatibility';
import { validateState, validateStateItemList } from '../../validators';
import { WidgetAbstractClass } from '../widget.abstract.class';
import { acceptedTypesString } from './data';

import { faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import './styles.styl';

export const classNameDictionary: Record<string, string> = {
  root: 'p360-file-upload-widget',
  dropZone: 'drop-zone',
  dropZoneInput: 'drop-zone-input',
  dropZoneContent: 'drop-zone-content',
  dropZoneIcon: 'drop-zone-icon',
  dropZoneText: 'drop-zone-text',
  dropZoneBrowseText: 'drop-zone-browse-text',
  dropZoneInfo: 'drop-zone-info',
  dropZoneInfoText: 'drop-zone-info-text',
  dropZoneInfoValue: 'drop-zone-info-value',
  errorPanel: 'error-panel',
  errorMessage: 'error-message',
  disabled: 'disabled',
  dragValid: 'drag-valid',
  dragInvalid: 'drag-invalid',
  valid: 'valid',
  invalid: 'invalid',
  fileBlock: 'file-block',
  hidden: 'hidden',
  panel: 'panel',
  clearButton: 'clear-button',
  statistics: 'statistics',
  statisticsItem: 'statistics-item',
  statisticsValue: 'statistics-value',
  sizeValue: 'size-value',
  counterValue: 'counter-value',
  statisticsLimit: 'statistics-limit',
  sizeLimit: 'size-limit',
  counterLimit: 'counter-limit',
  statisticsLimitValue: 'statistics-limit-value',
  sizeLimitValue: 'size-limit-value',
  counterLimitValue: 'counter-limit-value',
  fileList: 'file-list',
  fileListItem: 'file-list-item',
  error: 'error',
  loading: 'loading',
  uploaded: 'uploaded',
  progressBar: 'progress-bar',
  icon: 'icon',
  attachment: 'attachment',
  iconButton: 'icon-button',
  deleteButton: 'delete-button',
  reloadButton: 'reload-button',
  previewButton: 'preview-button',
  filename: 'filename',
  name: 'name',
  extension: 'extension',
  fileInfo: 'file-info',
  fileSize: 'file-size',
  fileStatus: 'file-status',
  actions: 'actions',
  loadingStatus: 'loading-status',
  loadingStatusValue: 'loading-status-value'
};

export default class FileUploadWidget extends WidgetAbstractClass<IFileUploadQuestion> {
  public inheritClass = 'file';
  public customPropertiesList: IProperty[] = [
    {
      name: 'ignoreHiddenFiles',
      type: 'switch',
      category: 'general',
      default: true,
      visibleIndex: 6
    },
    {
      name: 'maxFileCount',
      type: 'number',
      category: 'general',
      minValue: 0,
      default: 0,
      visibleIndex: 7
    },
    {
      name: 'minFileCount',
      type: 'number',
      category: 'general',
      minValue: 0,
      default: 0,
      visibleIndex: 8
    },
    {
      name: 'maxSize',
      type: 'number',
      category: 'general',
      minValue: 0,
      default: 50,
      maxValue: 50,
      visibleIndex: 10
    },
    {
      name: 'maxFileSize',
      type: 'number',
      category: 'general',
      minValue: 0,
      default: 0,
      maxValue: 50,
      visibleIndex: 11
    },
    {
      name: 'acceptedTypes',
      type: 'string',
      default: acceptedTypesString,
      visibleIndex: 12
    },
    {
      name: 'tags',
      type: 'tags',
      category: 'general',
      visibleIndex: 13
    }
  ];

  public hidePropertyNameList: string[] = [
    'autoUnmask',
    'indent',
    'inputFormat',
    'autocompleteAttr',
    'inputMask',
    'inputType',
    'placeholder',
    'prefix',
    'readOnly',
    'validators',
    'size',
    'valueName',
    'nameAttr',
    'hideNumber',
    'titleLocation',
    'descriptionLocation',
    'valueName',
    'useDisplayValuesInTitle',
    'correctAnswer',
    'allowImagesPreview',
    'imageHeight',
    'imageWidth',
    'showPreview',
    'storeDataAsText',
    'waitForUpload',
    'autocompleteAttr',
    'needConfirmRemoveFile',
    'requiredErrorText',
    'required',
    'allowMultiple'
  ];

  public surveyPluralLocalization: WidgetPluralLocalization = {
    file: {
      en: {
        one: 'file',
        other: 'files'
      },
      de: {
        one: 'Datei',
        other: 'Dateien'
      }
    }
  };

  public surveyLocalization: WidgetLocalization = {
    drop: {
      en: 'Drop your files here or ',
      de: 'Ziehen Sie Ihre Dateien hierher oder '
    },
    browse: {
      en: 'browse',
      de: 'wählen Sie Dateien zum Upload aus'
    },
    acceptedTypes: {
      en: 'Supported file formats:',
      de: 'Unterstützte Dateiformate:'
    },
    maxSize: {
      en: 'Maximum upload size:',
      de: 'Maximale Upload-Größe:'
    },
    maxFileSize: {
      en: 'Maximum file size:',
      de: 'Maximale Dateigröße:'
    },
    mb: {
      en: 'Mb',
      de: 'MB'
    },
    minFileCount: {
      en: 'Minimum',
      de: 'Mindestens'
    },
    maxFileCount: {
      en: 'Maximum',
      de: 'Maximal'
    },
    exactlyFileCount: {
      en: 'Upload exactly',
      de: 'Einzig zulässige Anzahl'
    },
    clearUnsupportedFiles: {
      en: 'Clear unsupported files',
      de: 'Nicht unterstützte Dateien entfernen'
    },
    sizeText: {
      en: 'Total file size:',
      de: 'Gesamtdateigröße:'
    },
    counterText: {
      en: 'Total files:',
      de: 'Gesamtzahl der Dateien:'
    },
    b: {
      en: 'b',
      de: 'B'
    },
    Kb: {
      en: 'Kb',
      de: 'KB'
    },
    Mb: {
      en: 'Mb',
      de: 'MB'
    },
    Gb: {
      en: 'Gb',
      de: 'GB'
    },
    Tb: {
      en: 'Tb',
      de: 'TB'
    },
    error: {
      en: 'Error',
      de: 'Error'
    },
    errorType: {
      en: 'Unsupported type',
      de: 'Nicht unterstützter Typ'
    },
    errorSize: {
      en: 'Max file size',
      de: 'Maximale Dateigröße'
    },
    errorDuplicate: {
      en: 'Duplicate',
      de: 'Duplikat'
    },
    errorUpload: {
      en: 'Upload error',
      de: 'Upload-Fehler'
    },
    loading: {
      en: 'Loading...',
      de: 'Laden...'
    },
    uploaded: {
      en: 'Uploaded',
      de: 'Hochgeladen '
    },
    unknown: {
      en: 'Unknown',
      de: 'Unbekannt'
    },
    required: {
      en: 'This upload is mandatory. Please upload at least one file.',
      de: 'Dieser Upload ist obligatorisch. Bitte laden Sie mindestens eine Datei hoch.'
    },
    minFileCountErrorText: {
      en: 'Please upload at least {0}.',
      de: 'Bitte laden Sie mindestens {0} hoch.'
    },
    maxFileCountErrorText: {
      en: 'Please upload no more then {0}.',
      de: 'Bitte laden sie maximal {0} hoch.'
    },
    maxFileSizeErrorText: {
      en: 'The {0} you provided for uploading are exceeding the file size limit of {1}. Please remove one or more files to proceed.',
      de: 'Die {0}, die Sie zum Hochladen angegeben haben, überschreiten die Dateigrößenbegrenzung von {1}. Bitte entfernen Sie eine oder mehrere Dateien, um fortzufahren.'
    },
    hasErrors: {
      en: 'The upload can not be finished as there are errors. Please resolve the errors before proceeding.',
      de: 'Der Upload kann nicht abgeschlossen werden, da Fehler aufgetreten sind. Bitte beheben Sie die Fehler, bevor Sie fortfahren.'
    },
    filesLoading: {
      en: 'Upload is still in progress. Please wait for it to finish before you proceed.',
      de: 'Der Uploadvorgang läuft noch. Bitte warten Sie bis er abgeschlossen ist, bevor Sie fortfahren.'
    }
  };

  public editorLocalization: WidgetEditorLocalization = {
    qt: {
      [this.name]: {
        en: 'Fileupload',
        de: 'Datei-Upload'
      }
    },
    pe: {
      maxSize: {
        en: 'Maximum upload size in Mb',
        de: 'Maximale Upload-Größe in MB '
      }
    },
    p: {
      acceptedTypes: {
        en: 'Accepted types',
        de: 'Akzeptierte Typen'
      },
      tags: {
        en: 'Tags',
        de: 'Schlagworte'
      },
      maxSize: {
        en: 'Maximum upload size in Mb',
        de: 'Maximale Upload-Größe in MB '
      },
      maxFileSize: {
        en: 'Maximum file size in Mb',
        de: 'Maximale Dateigröße in MB'
      },
      minFileCount: {
        en: 'Minimum files',
        de: 'Mindestanzahl von Dateien'
      },
      maxFileCount: {
        en: 'Maximum files',
        de: 'Maximalanzahl von Dateien'
      },
      ignoreHiddenFiles: {
        en: 'Ignore hidden files',
        de: 'Versteckte Dateien ignorieren'
      }
    },
    pehelp: {
      minFileCount: {
        en: 'Setting min. files > 0 does not make the upload mandatory. This can only by achieved by activating the "Required" property.',
        de: 'Die Einstellung min. files > 0 macht den Upload nicht zu einer Pflichtangabe. Dies kann nur durch das Aktivieren Eigenschaft "Erforderlich" erreicht werden.'
      }
    }
  };

  protected alternativeNameList: string[] = [ 'dseuploadwidget' ];
  protected classNameDictionary: Record<string, string> = classNameDictionary;

  constructor() {
    super({
      name: 'fileupload',
      iconName: 'fileupload',
      icon: faFileUpload
    });
  }

  public renderer(question: IFileUploadQuestion, element: HTMLElement): void {
    element.innerHTML = '';
    addClass(element, this.classNameDictionary.root);
    const getLocalization = (owner: IFileUploadQuestion, key: string) => this.getLocalization(owner, key);
    const getPluralLocalization = (owner: IFileUploadQuestion, key: string) => this.getPluralLocalization(owner, key);
    element.appendChild(
      createDropZoneHtml(
        question,
        this.classNameDictionary,
        this.getCurrentLocale.bind(this),
        getLocalization.bind(this),
        getPluralLocalization.bind(this)
      )
    );
    element.appendChild(createFileBlockHtml(question, this.classNameDictionary, getLocalization.bind(this)));
  }

  public afterRender(
    question: IFileUploadQuestion,
    element: HTMLElement
  ): void {
    super.afterRender(question, element);

    const updateFileCount = (sender: IFileUploadQuestion): void => {
      const { minFileCount, maxFileCount } = sender;
      sender.fileCount = sender.stateItemList.length;
      const min = minFileCount
        ? minFileCount
        : 0;
      const max = maxFileCount
        ? maxFileCount
        : 0;
      const counterValue = sender.findElement('counterValue');
      if (counterValue) {
        counterValue.innerHTML = `${ sender.fileCount }`;
      }
      const counterLimit = sender.findElement('counterLimit');
      const counterLimitValue = sender.findElement('counterLimitValue');
      if (counterLimit) {
        setClass(counterLimit, this.classNameDictionary.hidden, !(min || max) || (sender.fileCount > min && !max));
        if (min || max) {
          if (counterLimitValue) {
            if (sender.fileCount > min && max) {
              counterLimitValue.innerHTML = `${ max }`;
            } else {
              counterLimitValue.innerHTML = `${ min }`;
            }
          }
        } else {
          if (counterLimitValue) {
            counterLimitValue.innerHTML = '0';
          }
        }
      }
    };
    const updateTotalSize = (sender: IFileUploadQuestion): void => {
      const getLocalization = (owner: IFileUploadQuestion, key: string) => this.getLocalization(owner, key);
      const { maxSize } = sender;
      sender.totalSize = sender.stateItemList
        .reduce((result: number, item: IStateItem) => result + item.data.size, 0);
      const sizeValue = sender.findElement('sizeValue');
      if (sizeValue) {
        sizeValue.innerHTML = getConvertedSize(sender.totalSize, sender, getLocalization.bind(this));
      }

      const sizeLimit = sender.findElement('sizeLimit');
      if (sizeLimit) {
        setClass(sizeLimit, this.classNameDictionary.hidden, !maxSize);
      }
      const sizeLimitValue = sender.findElement('sizeLimitValue');
      if (maxSize && sizeLimitValue) {
        sizeLimitValue.innerHTML = getConvertedSize(
          megabytesToBytes(+maxSize),
          sender,
          getLocalization.bind(this)
        );
      }
    };

    const validate = (sender: IFileUploadQuestion): void => {
      validateStateItemList(sender);
      sender.stateItemList = sender.stateItemList.sort((a: IStateItem, b: IStateItem) => {
        const { status: aStatus, name: aName, extension: aExtension, size: aSize } = a.data;
        const { status: bStatus, name: bName, extension: bExtension, size: bSize } = b.data;
        return aStatus - bStatus || aName.localeCompare(bName) || aExtension.localeCompare(bExtension) || aSize - bSize;
      });
      sender.errorList = validateState(sender);
    };

    const removeStateItem = (sender: IFileUploadQuestion, item: IStateItem): void => {
      const index = sender.stateItemList.indexOf(item);
      if (item.data.request !== null) {
        if ((sender.survey as any).surveyId) {
          (item.data.request as XMLHttpRequest).abort();
        } else {
          clearInterval(item.data.request as number);
        }
      }
      sender.stateItemList.splice(index, 1);
    };
    const getErrorFileList = (sender: IFileUploadQuestion) => sender.stateItemList
      .filter((item: IStateItem) => !!item.data.errorList.length);
    const clearUnsupportedFilesFromState = (sender: IFileUploadQuestion) => (): void => {
      let errorFileList = getErrorFileList(sender);
      let index = 0;
      while (errorFileList.length > 0) {
        const currentFile = errorFileList[index];
        if (currentFile.data.errorList.includes(FileError.DUPLICATE) && !!currentFile.value) {
          index++;
        } else {
          removeStateItem(sender, errorFileList[index]);
          validateStateItemList(sender);
          errorFileList = getErrorFileList(sender);
        }
      }
      updateState(sender);
    };
    const updateClearButtonVisibility = (sender: IFileUploadQuestion): void => {
      const clearButtonElement = sender.findElement<HTMLButtonElement>('clearButton');
      if (clearButtonElement) {
        clearButtonElement.disabled = !sender.errorList.includes(FileUploadWidgetError.HAS_ERRORS);
      }
    };

    const updateFileBlockVisibility = (sender: IFileUploadQuestion): void => {
      const fileBlock = sender.findElement('fileBlock');
      if (fileBlock) {
        setClass(fileBlock, this.classNameDictionary.hidden, !sender.fileCount);
      }
    };

    const updateView = (sender: IFileUploadQuestion): void => {
      const dropZoneElement = sender.findElement('dropZone');
      if (dropZoneElement) {
        setClass(
          dropZoneElement,
          this.classNameDictionary.invalid,
          !!sender.fileCount && !!sender.errorList.length
        );
        setClass(
          dropZoneElement,
          this.classNameDictionary.valid,
          !!sender.fileCount && !sender.errorList.length
        );
      }
      const fileListElement = sender.findElement('fileList');
      const getLocalization = (owner: IFileUploadQuestion, key: string) => this.getLocalization(owner, key);
      if (fileListElement) {
        fileListElement.innerHTML = '';
        if (sender.fileCount) {
          sender.stateItemList.map((item: IStateItem) => {
            item.data.element = createFileListItemHtml(
              sender,
              this.classNameDictionary,
              getLocalization.bind(this),
              item
            );
            const deleteButtonElement = this.getElement('deleteButton', item.data.element);
            if (deleteButtonElement) {
              const deleteHandler = (): void => {
                removeStateItem(sender, item);
                item.data.element.remove();
                setQuestionValue(sender);
                updateState(sender);
              };
              deleteButtonElement.addEventListener('click', deleteHandler);
            }
            fileListElement.append(item.data.element);
          });
        }
      }
    };

    const setQuestionValue = (sender: IFileUploadQuestion): void => {
      sender.value = sender.stateItemList
        .filter((item: IStateItem) => [ Status.PRELOADED, Status.UPLOADED ].includes(item.data.status))
        .filter((item: IStateItem) => item.value !== null)
        .map((item: IStateItem) => item.value!);
    };

    const updateIsUploadingFiles = (sender: IFileUploadQuestion): void => {
      sender.isUploadingFiles = !!sender.stateItemList
        .filter((item: IStateItem) => item.data.status === Status.LOADING).length;
    };

    const uploadFile = (sender: IFileUploadQuestion, item: IStateItem): void => {
      const { tags } = sender;
      const { data, file } = item;
      if (file === null) {
        return;
      }
      data.status = Status.LOADING;
      data.errorList = data.errorList.filter((error: FileError) => error !== FileError.UPLOAD);

      const surveyResultId = (sender.survey as any).surveyId;
      if (!surveyResultId) {
        const fileUploadMaxTimeMs = 1000;
        const minUploadTicPercent = 3;
        const maxUploadTicPercent = 13;
        const ticInterval = fileUploadMaxTimeMs / 100 * minUploadTicPercent;
        const tic = () => {
          data.loadingPercent += Math
            .floor(Math.random() * (maxUploadTicPercent - minUploadTicPercent + 1)) + minUploadTicPercent;
          if (data.loadingPercent >= 100) {
            data.loadingPercent = 100;
            clearInterval(timer);
            data.status = Status.UPLOADED;
            const { fileName: name, type, size } = item.data;
            item.value = {
              name,
              type,
              size,
              content: getStaticTestDocumentResourceUrl(),
              tags
            };
            setQuestionValue(sender);
            updateState(sender);
          }
          const progressBarElement = this.getElement('progressBar', data.element);
          if (progressBarElement) {
            progressBarElement.style.width = `${ data.loadingPercent }%`;
          }
          const loadingValueElement = this.getElement('loadingStatusValue', data.element);
          if (loadingValueElement) {
            loadingValueElement.innerHTML = `${ data.loadingPercent }`;
          }
        };
        const timer = setInterval(tic, ticInterval);
        return;
      }
      const url = '/api/survey/document/attachment';
      const method = 'POST';
      const body = new FormData();
      body.append('surveyResultId', surveyResultId);
      if (tags) {
        body.append('tags', tags.join(','));
      }
      body.append('file', file);

      const request = new XMLHttpRequest();
      request.responseType = 'json';
      request.open(method, url);

      const progressHandler = (event: ProgressEvent<XMLHttpRequestEventTarget>): void => {
        data.loadingPercent = Math.round((event.loaded / event.total) * 100);
        const progressBarElement = this.getElement('progressBar', data.element);
        if (progressBarElement) {
          progressBarElement.style.width = `${ data.loadingPercent }%`;
        }
        const loadingValueElement = this.getElement('loadingStatusValue', data.element);
        if (loadingValueElement) {
          loadingValueElement.innerHTML = `${ data.loadingPercent }`;
        }
      };
      request.upload.addEventListener('progress', progressHandler);

      const loadHandler = (event: ProgressEvent<XMLHttpRequestEventTarget>): void => {
        const status = (event.currentTarget as XMLHttpRequest).status;
        if (status !== 200) {
          return errorHandler(event);
        }
        data.status = Status.UPLOADED;
        item.attachment = request.response as IAttachment;
        const { fileName: name, type, size } = item.data;
        const { url: content } = item.attachment;
        item.value = {
          name,
          type,
          size,
          content,
          tags
        };
        setQuestionValue(sender);
        updateState(sender);
      };
      request.addEventListener('load', loadHandler);

      const errorHandler = (event: ProgressEvent<XMLHttpRequestEventTarget>): void => {
        const status = (event.currentTarget as XMLHttpRequest).status;
        const errorStatus = status === 413 ? 413 : 500;
        notification.error(`common:uploadError.${ errorStatus }`);
        data.status = Status.ERROR;
        data.errorList.push(FileError.UPLOAD);
        updateState(sender);

        const reloadButtonElement = this.getElement('reloadButton', data.element);
        if (reloadButtonElement) {
          const reloadHandler = (): void => {
            item.data.loadingPercent = 0;
            uploadFile(sender, item);
            updateState(sender);
          };
          reloadButtonElement.addEventListener('click', reloadHandler);
        }
      };
      request.addEventListener('error', errorHandler);

      request.send(body);
      item.data.request = request;
    };

    const upload = (sender: IFileUploadQuestion): void => {
      if (sender.errorList.includes(FileUploadWidgetError.OVERSIZE)
        || sender.errorList.includes(FileUploadWidgetError.MAX)) {
        return;
      }
      const waitingList = sender.stateItemList.filter((item: IStateItem) => item.data.status === Status.WAITING);
      if (!waitingList.length) {
        return;
      }
      waitingList.map((item: IStateItem) => uploadFile(sender, item));
    };

    const updateState = (sender: IFileUploadQuestion): void => {
      sender.hideError();
      updateFileCount(sender);
      updateTotalSize(sender);
      validate(sender);
      updateClearButtonVisibility(sender);
      updateFileBlockVisibility(sender);
      updateView(sender);
      upload(sender);
      updateIsUploadingFiles(sender);
    };

    const addFiles = (sender: IFileUploadQuestion, fileList: File[]): void => {
      if (!fileList.length) {
        return;
      }
      const stateItemList: IStateItem[] = fileList
        .map((file: File): IStateItem => {
          const { name: fileName, size, type } = file;
          const fileNameArray = fileName.split('.');
          const extension = fileNameArray.pop()!;
          const name = fileNameArray.join('.');
          const data: IStateItemData = {
            element: createElement<HTMLLIElement>({ tagName: 'li' }),
            fileName,
            name,
            extension,
            type,
            size,
            request: null,
            loadingPercent: 0,
            status: Status.WAITING,
            errorList: []
          };
          return {
            data,
            file,
            attachment: null,
            value: null
          };
        });
      sender.stateItemList = [ ...sender.stateItemList, ...stateItemList ];
      updateState(sender);
    };

    const fileUploadInputHandler = (sender: IFileUploadQuestion) => (event: Event): void => {
      const fileList = parseFileUploadInput(event);
      if (question.maxFileCount === 1) {
        sender.stateItemList = [];
      }
      addFiles(sender, fileList);
    };

    const preventDefaults = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const dragEnterHandler = (sender: IFileUploadQuestion) => (event: DragEvent): void => {
      preventDefaults(event);
      const dropZoneElement = sender.findElement('dropZone');
      if (dropZoneElement === null) {
        return;
      }
      const isValid: boolean = validateDragEnter(event, sender);
      isValid
        ? addClass(dropZoneElement, this.classNameDictionary.dragValid)
        : addClass(dropZoneElement, this.classNameDictionary.dragInvalid);
    };

    const clearDragMode = (sender: IFileUploadQuestion): void => {
      const dropZoneElement = sender.findElement('dropZone');
      if (dropZoneElement) {
        removeClass(dropZoneElement, [ this.classNameDictionary.dragValid, this.classNameDictionary.dragInvalid ]);
      }
    };
    const dragLeaveHandler = (sender: IFileUploadQuestion) => (event: DragEvent): void => {
      preventDefaults(event);
      clearDragMode(sender);
    };

    const dropHandler = (sender: IFileUploadQuestion) => (event: DragEvent): void => {
      preventDefaults(event);
      clearDragMode(sender);
      const fileListPromise: Promise<File[]> = parseDropEvent(event, sender);
      fileListPromise.then((fileList: File[]) => {
        if (question.maxFileCount === 1 && fileList.length === 1) {
          sender.stateItemList = [];
        }
        addFiles(sender, fileList);
      });
    };

    const setDropZoneHandlers = (sender: IFileUploadQuestion, htmlElement: HTMLElement): void => {
      htmlElement.addEventListener('dragenter', sender.handlerDictionary.dragenter, false);
      htmlElement.addEventListener('dragleave', sender.handlerDictionary.dragleave, false);
      htmlElement.addEventListener('dragover', sender.handlerDictionary.dragover, false);
      htmlElement.addEventListener('drop', sender.handlerDictionary.drop, false);
    };
    const clearDropZoneHandlers = (sender: IFileUploadQuestion, htmlElement: HTMLElement): void  => {
      htmlElement.removeEventListener('dragenter', sender.handlerDictionary.dragenter);
      htmlElement.removeEventListener('dragleave', sender.handlerDictionary.dragleave);
      htmlElement.removeEventListener('dragleave', sender.handlerDictionary.dragover);
      htmlElement.removeEventListener('drop', sender.handlerDictionary.drop);
    };

    const initialize = (sender: IFileUploadQuestion): void => {
      sender.fileCount = 0;
      sender.totalSize = 0;
      sender.isUploadingFiles = false;
      sender.stateItemList = [];
      sender.errorList = [];

      sender.findElement = <T extends HTMLElement>(name: string): null | T => this.getElement<T>(name, element);
      sender.showError = (errorText: string): void => {
        const errorMessage = sender.findElement('errorMessage');
        if (errorMessage) {
          errorMessage.innerHTML = errorText;
        }
        const errorPanel = sender.findElement('errorPanel');
        if (errorPanel) {
          removeClass(errorPanel, this.classNameDictionary.hidden);
        }
      };
      sender.hideError = (): void => {
        const errorPanel = sender.findElement('errorPanel');
        if (errorPanel) {
          addClass(errorPanel, this.classNameDictionary.hidden);
        }
      };

      const fileUploadInputElement = sender.findElement('dropZoneInput');
      if (fileUploadInputElement === null) {
        console.error('Element "dropZoneInput" not found');
      } else {
        if (sender.isReadOnly) {
          fileUploadInputElement.setAttribute('disabled', 'disabled');
        }
        fileUploadInputElement.addEventListener('change', fileUploadInputHandler(sender), false);
      }

      sender.handlerDictionary = {
        dragenter: dragEnterHandler(sender),
        dragleave: dragLeaveHandler(sender),
        dragover: preventDefaults,
        drop: dropHandler(sender)
      };

      const dropZoneElement = sender.findElement('dropZone');
      if (dropZoneElement === null) {
        console.error('Element "dropZone" not found');
      } else {
        if (sender.isReadOnly) {
          addClass(dropZoneElement, this.classNameDictionary.disabled);
        } else {
          setDropZoneHandlers(sender, dropZoneElement);
        }
      }

      const clearButtonElement = sender.findElement<HTMLButtonElement>('clearButton');
      if (clearButtonElement) {
        clearButtonElement.addEventListener('click', clearUnsupportedFilesFromState(sender));
      }

      const { value: valueArray } = sender;
      if (Array.isArray(valueArray) && valueArray.length) {
        sender.stateItemList = valueArray
          .map((value: IFileUploadQuestionValue): IStateItem => {
            const { name: fileName, type, size } = value;
            const fileNameArray = fileName.split('.');
            const extension = fileNameArray.pop()!;
            const name = fileNameArray.join('.');
            const data: IStateItemData = {
              element: createElement<HTMLLIElement>({ tagName: 'li' }),
              fileName,
              name,
              extension,
              type,
              size,
              request: null,
              loadingPercent: 100,
              status: Status.PRELOADED,
              errorList: []
            };
            return {
              data,
              value,
              file: null,
              attachment: null
            };
          });
      }
      updateState(sender);
      this.addValidation(question);
    };

    initialize(question);

    this.onPropertyChangedHandler = (
      sender: IFileUploadQuestion,
      htmlElement: HTMLElement,
      options: IPropertyChangeOption<any>
    ) => {
      const getLocalization = (owner: IFileUploadQuestion, key: string) => this.getLocalization(owner, key);
      const getPluralLocalization = (owner: IFileUploadQuestion, key: string) => this.getPluralLocalization(owner, key);
      const dropZoneInfoElement = this.getElement('dropZoneInfo', htmlElement);
      const updateDropZoneInfoElement = (data: IFileUploadQuestion) => {
        if (dropZoneInfoElement) {
          dropZoneInfoElement
            .replaceWith(
              createDropZoneInfoHtml(
                data,
                this.classNameDictionary,
                this.getCurrentLocale.bind(this),
                getLocalization.bind(this),
                getPluralLocalization.bind(this)
              )
            );
        }
      };
      switch (options.name) {
        case 'readOnly':
          const panel = this.getElement('errorPanel', htmlElement);
          if (panel) {
            addClass(panel, this.classNameDictionary.hidden);
          }
          const fileUploadInput = this.getElement<HTMLInputElement>('dropZoneInput', htmlElement);
          if (fileUploadInput) {
            fileUploadInput.disabled = options.newValue;
          }
          const dropZone = this.getElement('dropZone', htmlElement);
          if (dropZone) {
            setClass(dropZone, this.classNameDictionary.disabled, options.newValue === true);
            if (options.newValue === true) {
              clearDropZoneHandlers(sender, dropZone);
            } else {
              setDropZoneHandlers(sender, dropZone);
            }
          }
          updateView(sender);
          return;
        case 'isRequired':
          updateState(sender);
          return;
        case 'acceptedTypes':
          updateDropZoneInfoElement(sender);
          const lowerCaseValue = options.newValue.toLowerCase();
          if (options.newValue !== lowerCaseValue) {
            sender.acceptedTypes = lowerCaseValue;
          }
          return;
        case 'maxSize':
          if (!!sender.maxFileSize && !!options.newValue && options.newValue < sender.maxFileSize ) {
            sender.maxSize = sender.maxFileSize;
          }
          updateDropZoneInfoElement(sender);
          return;
        case 'maxFileSize':
          if (!!sender.maxSize && !!options.newValue && options.newValue > sender.maxSize ) {
            sender.maxFileSize = sender.maxSize;
          }
          if (dropZoneInfoElement) {
            dropZoneInfoElement
              .replaceWith(
                createDropZoneInfoHtml(
                  sender,
                  this.classNameDictionary,
                  this.getCurrentLocale.bind(this),
                  getLocalization,
                  getPluralLocalization
                )
              );
          }
          return;
        case 'minFileCount':
          if (!!sender.maxFileCount && !!options.newValue && options.newValue > sender.maxFileCount ) {
            sender.minFileCount = sender.maxFileCount;
          }
          updateDropZoneInfoElement(sender);
          return;
        case 'maxFileCount':
          if (!!sender.minFileCount && !!options.newValue && options.newValue < sender.minFileCount ) {
            sender.maxFileCount = sender.minFileCount;
          }
          updateDropZoneInfoElement(sender);
          return;
      }
    };
  }

  private addValidation(question: IFileUploadQuestion): void {
    this.addValidator(
      question,
      'FileUploadWidgetValidator',
      'fileuploadwidgetvalidator',
      [ question.minFileCount, question.maxFileCount, question.maxSize || question.maxFileSize ]
    );
  }
}
