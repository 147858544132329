import React, { ComponentProps, FC, ReactElement, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { classNameArrayToString } from '@mydse/design-system';
import { FunctionalAreaScoreData, FunctionalAreaTomScoreData, IScoreData } from '@services';
import AreaChart, { IAreaChartData, IAreaChartOptions } from '../AreaChart/AreaChart';

import styles from './AreaChartWidget.styl';

interface IOwnProps {
  functionalAreaScore?: FunctionalAreaScoreData;
}

type Props = IOwnProps & ComponentProps<any>;

const AreaChartWidget: FC<Props> = ({
  className,
  functionalAreaScore = {}
}): ReactElement => {
  const emptyData: FunctionalAreaTomScoreData = {
    HR: { value: 0, total: 0 },
    IT: { value: 0, total: 0 },
    SALES: { value: 0, total: 0 },
    PURCHASE: { value: 0, total: 0 },
    FINANCE: { value: 0, total: 0 },
    SECURITY: { value: 0, total: 0 },
    THIRD_PARTY_PROCESSING: { value: 0, total: 0 },
    TOM: { value: 0, total: 0 }
  };

  const linkDictionary: Record<string, string> = {
    HR: '',
    IT: '',
    SALES: '',
    PURCHASE: '',
    FINANCE: '',
    SECURITY: '',
    THIRD_PARTY_PROCESSING: '',
    TOM: ''
  };

  const ref = useRef<null | HTMLDivElement>(null);
  const [ width, setWidth ] = useState<number | undefined>();

  const options: Partial<IAreaChartOptions> = {
    data: {
      isLoading: !functionalAreaScore,
      sort: false,
      useRelativeValues: true,
      showMarkers: true
    }
  };
  const data: IAreaChartData[] = Object
    .entries({ ...emptyData, ...functionalAreaScore })
    .map(
      ([ key, value ]) => ({
        label: key,
        link: linkDictionary[key],
        value: (value as IScoreData).value,
        total: (value as IScoreData).total
      })
    );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ReadonlyArray<ResizeObserverEntry>): void => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        const entry = entries[0];
        if (!entry) {
          return;
        }
        const inlineSize: number = entry.borderBoxSize[0]?.inlineSize;
        if (!inlineSize) {
          return;
        }
        setWidth(inlineSize);
      });
    });
    if (ref.current !== null) {
      resizeObserver.observe(ref.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  },
  []
  );

  const classNameString = classNameArrayToString([
    styles.wrap,
    className
  ]);
  return (
    <div
      ref={ref}
      className={classNameString}
    >
      <div className={styles.chart}>
        <AreaChart
          data={data}
          options={options}
          size={width}
        />
      </div>
    </div>
  );
};

export default AreaChartWidget;
