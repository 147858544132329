import React, { FC, HTMLAttributes, memo, ReactElement } from 'react';
import { classNameArrayToString } from '@mydse/design-system';
import { HorizontalMenuItem, HorizontalMenuItemData } from '..';

import styles from './HorizontalMenu.styl';
import { useRoute } from 'react-router5';

interface IOwnProps {
  menuData: HorizontalMenuItemData[];
}

type Props = IOwnProps & HTMLAttributes<HTMLDivElement>;

const HorizontalMenu: FC<Props> = ({ menuData, className, ...properties }): null | ReactElement => {
  const { route } = useRoute();

  const itemList = menuData.map((item: HorizontalMenuItemData) => (
    <HorizontalMenuItem
      key={item.routeName}
      {...item}
      isActive={route.name === item.routeName}
    />
  ));

  const classNameString = classNameArrayToString([
    styles.menu,
    className
  ]);

  return (
    <div
      className={classNameString}
      {...properties}
    >
      {itemList}
    </div>
  );
};

export default memo(HorizontalMenu);
