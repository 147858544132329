import React, { Children, cloneElement, ComponentProps, FC, ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router5';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDataAttributes } from '@mydse/design-system';
import { PagePermissionData, permissionService } from '@services';
import { RouteListType } from '@router/configuration';

import styles from './Widget.styl';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IOwnProps {
  title?: string | ReactElement;
  name: string;
  icon?: null | IconDefinition;
  actionInfo?: ReactElement;
  action?: ReactElement;
  children: ReactElement;
  isHighlighting?: boolean;
  readonly?: boolean;
  footer?: null | ReactElement;
  demo?: boolean;
  headless?: boolean;
  headingClassName?: string;
  navigateTo?: {
    routeName: string;
    permissionFor: RouteListType;
    params?: Record<string, any>;
  };
  className?: any;
}

type Props = IOwnProps & ComponentProps<any>;

const Widget: FC<Props> = ({
  title,
  name,
  children,
  icon = null,
  action = null,
  actionInfo = null,
  footer = null,
  isHighlighting = false,
  readonly = false,
  headless = false,
  headingClassName,
  demo = false,
  navigateTo,
  ...props
}) => {
  const classString = isHighlighting ? `${ styles.widget } ${ styles.highlight }` : styles.widget;

  const className =
    typeof props.className === 'undefined'
      ? classString
      : `${ classString } ${ props.className }`;

  const iconElement = icon === null
    ? null
    : <FontAwesomeIcon className={styles.icon} icon={icon} />;

  const actionElement = readonly ? null : action;

  const footerElement = footer === null ? null : <div className={styles.footer}>{footer}</div>;

  const heading = headless ? null : (
    <div className={headingClassName ? `${ styles.header } ${ headingClassName }` : styles.header}>
      <span className={styles.title}>
        {iconElement}
        {title}
      </span>
      <div className={styles.action}>
        {actionInfo}
        {actionElement}
      </div>
    </div>
  );

  const [ permissionAccess, setPermissionAccess ] = useState<boolean>(false);
  const unsubscribe$ = new Subject<void>();

  useEffect(() => {
    if (navigateTo) {
      permissionService.pagePermissionSubject$
        .pipe(takeUntil(unsubscribe$))
        .subscribe(
          (pagePermissionData: null | PagePermissionData) => {
            const route: RouteListType = navigateTo.permissionFor;
            setPermissionAccess(!!pagePermissionData && !!pagePermissionData[route]);
          }
        );
    }
    return () => {
      unsubscribe$.next();
      unsubscribe$.complete();
    };
  }, []);

  const childElement = Children.map(children, (child: ReactElement) => (demo ? cloneElement(child, { demo }) : child));
  const { dataAttributes } = useDataAttributes({
    test: { 'widget-name': name },
    guide: { 'widget-name': name }
  });
  return permissionAccess
    ? (
      <Link
        data-link
        className={styles.link}
        routeName={navigateTo!.routeName}
        routeParams={navigateTo!.params}
      >
        {' '}
        <div
          {...props}
          className={className}
          {...dataAttributes}
        >
          {heading}
          {childElement}
          {footerElement}
        </div>
      </Link>
    )
    : (
      <div
        {...props}
        className={className}
        {...dataAttributes}
      >
        {heading}
        {childElement}
        {footerElement}
      </div>
    );
};

export default Widget;
