import { Column } from 'react-table';
import { TFunction } from 'react-i18next';
import { DataStorageCloudHosted, SystemsType } from '@services';
import { Header, ValueCell } from '@mydse/design-system';

const getDataStorageCloudHostedColumnList = (
  t: TFunction,
  systemsType: SystemsType
): Array<Column<DataStorageCloudHosted>> => [
  {
    accessor: 'name',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    id: 'serviceProvider',
    accessor: (originalRow: DataStorageCloudHosted) => originalRow.serviceProvider.title,
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    Cell: ValueCell()
  }
];

export default getDataStorageCloudHostedColumnList;
