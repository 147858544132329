import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ComponentPermissionData } from '@mydse/typings';
import { loadingIndicatorService } from '@mydse/design-system';
import {
  dashboardService,
  FunctionalAreaScoreData,
  ComplianceScoreData
} from '@services';
import { PermissionGuard, Widget } from '@shared/components';
import { AreaChartWidget, ComplianceScoreWidget } from '@pages/Dashboard/components';

import styles from './Status.styl';

interface IResponseDictionary {
  areaChartData: FunctionalAreaScoreData;
  complianceScoreData: ComplianceScoreData;
}

interface IOwnProps {
  permission: ComponentPermissionData;
}

type Props = IOwnProps;

export const Status: FC<Props> = ({ permission }) => {
  const [ areaChart, setAreaChart ] = useState<FunctionalAreaScoreData>();
  const [ complianceScore, setComplianceScore ] = useState<ComplianceScoreData>();
  const { t } = useTranslation('dashboard');

  useEffect(
    () => {
      loadingIndicatorService.show();
      forkJoin({
        areaChartData: dashboardService.getDetailedComplianceScore(),
        complianceScoreData: dashboardService.getComplianceScore()
      })
        .pipe(take(1))
        .subscribe(({ areaChartData, complianceScoreData }: IResponseDictionary) => {
          setAreaChart(areaChartData);
          setComplianceScore(complianceScoreData);
          loadingIndicatorService.hide();
        });
    },
    []
  );

  return (
    <div>
      <div className={ styles.wrap }>
        <div className={ styles.area }>
          <PermissionGuard permission={ permission } componentName="areaWidget">
            <Widget title={ t('areaWidget.title') } name="areaWidget">
              <AreaChartWidget
                className={ styles.areaChartWidget }
                functionalAreaScore={ areaChart }
              />
            </Widget>
          </PermissionGuard>
        </div>
        <div className={ styles.sideBar }>
          <PermissionGuard permission={ permission } componentName="complianceScoreWidget">
            <Widget
              name="complianceScoreWidget"
              title={ t('complianceScoreWidget.title') }
              className={ styles.complianceScoreWidget }
            >
              <ComplianceScoreWidget value={ complianceScore }/>
            </Widget>
          </PermissionGuard>
        </div>
      </div>
    </div>
  );
};
