import { State } from 'router5';
import { leadingCharacter } from '@mydse/utilities';
import {
  companyIdParameterName,
  IRouteState,
  PageType,
  productParameterName,
  redirectToLobby,
  redirectUrnParameterName
} from '@router';
import { productService, userService } from '@services';

export const emailParameterName = 'email';

export const emailMiddleware = () => (toState: State): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const { error, isLoggedIn, pageType/*, name*/ } = toState as IRouteState;
    if (error || !isLoggedIn || pageType === PageType.public) {
      return resolve(true);
    }
    const emailParameter = toState.params[emailParameterName];
    const email = emailParameter
      ? decodeURIComponent(emailParameter)
      : emailParameter;
    if (!email) {
      return resolve(true);
    }
    if (userService.userSubject$.value?.email !== email) {
      const redirectUrn = leadingCharacter(toState.path, '/', false);
      const companyId = toState.params[companyIdParameterName];
      const product = productService.getCurrentProduct();

      redirectToLobby({
        parameters: {
          ...(email && { [emailParameterName]: encodeURIComponent(email) }),
          [companyIdParameterName]: companyId,
          [productParameterName]: product,
          [redirectUrnParameterName]: redirectUrn
        }
      });
      return reject();
    }
    return resolve(true);
  });
};
