import { IFileUploadQuestion } from '../fileUpload.typings';
import { TranslationPlural, Locale } from '@mydse/typings';

export const pluralize = (
  value: number,
  key: string,
  question: IFileUploadQuestion,
  getCurrentLocale: (question: IFileUploadQuestion) => string,
  getPluralLocalization: (question: IFileUploadQuestion, key: string) => TranslationPlural
): string => {
  const locale: string = getCurrentLocale(question);
  const localeDictionary = {
    en: 'de-DE',
    de: 'de-DE'
  };
  const pluralRules = new Intl.PluralRules(localeDictionary[locale as Locale]);
  const localization = getPluralLocalization(question, key);
  return localization[pluralRules.select(value)] || '';
};
