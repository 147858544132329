import { Priority, ThirdPartyKey } from '../assetManagement.typings';

export const thirdPartyPriorityMapping: Partial<Record<ThirdPartyKey, Priority>> = {
  type: Priority.IMPORTANT,
  legalStatuses: Priority.IMPORTANT,
  dataTypes: Priority.IMPORTANT,
  transmissionCountries: Priority.IMPORTANT,
  documents: Priority.IMPORTANT,
  description: Priority.IMPORTANT,
  contractualDeadlines: Priority.OPTIONAL,
  contact: Priority.RECOMMENDED,
  privacyContact: Priority.RECOMMENDED,
  servicesSoftware: Priority.IMPORTANT,
  servicesStorages: Priority.IMPORTANT,
  accessesSoftware: Priority.OPTIONAL,
  accessesStorages: Priority.OPTIONAL,
  rpaAsController: Priority.OPTIONAL,
  rpaAsProcessor: Priority.OPTIONAL
};
