import { Column } from 'react-table';
import { TFunction } from 'react-i18next';
import { DataStorageSelfHosted, SystemsType } from '@services';
import { Header } from '@mydse/design-system';

const getDataStorageSelfHostedColumnList = (
  t: TFunction,
  systemsType: SystemsType
): Array<Column<DataStorageSelfHosted>> => [
  {
    accessor: 'name',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType })
  },
  {
    id: 'businessPremise',
    width: 'auto',
    disableFilters: true,
    Header: Header({ t, headingKey: systemsType }),
    accessor: (originalRow: DataStorageSelfHosted) => originalRow.businessPremise.name
  }
];

export default getDataStorageSelfHostedColumnList;
