import { BehaviorSubject, concat, forkJoin, Observable, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, take, tap } from 'rxjs/operators';
import { CurrentUser } from '@mydse/typings';
import { tokenService } from '@mydse/design-system';
import {
  authenticationService,
  companyService,
  Company,
  permissionService,
  systemsService,
  i18n,
  pendoService,
  streamService,
  productService
} from '@services';
import { userApiService } from '@services/api';
import { errorResponseInterceptor } from '@services/api/abstract/interceptors';

class UserService {
  public static getInstance(): UserService {
    return this.instance || (this.instance = new this());
  }
  private static instance: UserService;

  public error$ = new BehaviorSubject<null | number>(null);
  public isInitialized$ = new BehaviorSubject<boolean>(false);
  public userSubject$ = new BehaviorSubject<null | CurrentUser>(null);
  public user$ = this.userSubject$.asObservable();
  public isLoggedInSubject$ = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this.isLoggedInSubject$.asObservable();
  public isShowConsentModalSubject$ = new BehaviorSubject<boolean>(false);
  public isShowConsentModal$ = this.isShowConsentModalSubject$.asObservable();

  private userService = userApiService;
  private authenticationService = authenticationService;
  private tokenService = tokenService;
  private companyService = companyService;
  private permissionService = permissionService;
  private productService = productService;

  constructor() {
    systemsService.getCurrentTime();
    this.checkUser();
  }

  private getErrorhandler<T>(returnValue: T) {
    return (error: AjaxError): Observable<T> => {
      if (!this.error$.value || error.status > this.error$.value) {
        this.error$.next(error.status);
      }
      return of(returnValue);
    };
  }

  public checkUser(): void {
    const token = this.tokenService.getToken();
    if (token !== null && this.companyService) {
      const userRequest: Observable<null | CurrentUser> = this.userService.getCurrentUser(token, [ 500, 502 ])
        .pipe(catchError(this.getErrorhandler(null)));
      const companyRequest: Observable<null | Company> = this.companyService.updateCurrentCompany([ 500, 502 ])
        .pipe(catchError(this.getErrorhandler(null)));
      const permissionRequest: Observable<void> = this.permissionService.assignPermissionData([ 500, 502 ])
        .pipe(catchError(this.getErrorhandler(undefined)));
      const productRequest: Observable<void> = this.productService.getProductsData([ 500, 502 ])
        .pipe(catchError(this.getErrorhandler(undefined)));
      forkJoin([
        userRequest,
        companyRequest,
        permissionRequest,
        productRequest
      ])
        .pipe(
          take(1)
        )
        .subscribe((data: [null | CurrentUser, null | Company, void, void]): void => {
          const [ user ] = data;
          if (this.error$.value === null) {
            this.setUser(user);
            this.error$.next(null);
          } else {
            errorResponseInterceptor({ status: this.error$.value } as AjaxError);
            this.isInitialized$.next(true);
          }
        });
    } else {
      this.setUser(null);
    }
  }

  private onUserLogin(user: CurrentUser): void {
    productService.updateProduct();
    const analyticsPermission = permissionService.componentPermissionSubject$.value?.profileAnalytics;
    const { analyticsAllowed, preferredLanguage, timeZone, impersonatedBy } = user;
    const isImpersonatedBy = !!impersonatedBy;
    this.checkUserData(preferredLanguage, timeZone, !!impersonatedBy);
    this.updateIsShowConsentModalState(
      typeof analyticsAllowed === 'undefined'
      && pendoService.isEnabled()
      && !!analyticsPermission?.write
      && !isImpersonatedBy
    );
    if (analyticsAllowed !== false && !isImpersonatedBy) {
      pendoService.initialize(user);
    }
  }

  public clearUserData(): void {
    this.setUser(null);
    this.companyService.resetCompany();
    this.permissionService.resetPermissionData();
  }

  public logout(): Observable<null> {
    const token = this.tokenService.getToken();
    streamService.unsubscribe();
    this.clearUserData();
    this.updateIsShowConsentModalState(false);
    if (typeof (window as any).pendo?.setGuidesDisabled !== 'undefined'
      && typeof (window as any).pendo?.isReady !== 'undefined'
      && (window as any).pendo.isReady()
    ) {
      pendoService.setGuidesDisabled(false);
    }
    if (token !== null) {
      return this.authenticationService
        .logout(token);
    }
    return of(null);
  }

  public updateUser(): Observable<null | CurrentUser> {
    const token = this.tokenService.getToken();
    return token === null
      ? of(this.setUser(null))
      : this.userService
        .getCurrentUser(token)
        .pipe(
          take(1),
          tap((user: null | CurrentUser) => this.setUser(user))
        );
  }

  public updateIsShowConsentModalState(value: boolean): void {
    this.isShowConsentModalSubject$.next(value);
  }

  public updateAnalyticsAllowed(value: boolean): Observable<CurrentUser> {
    this.updateIsShowConsentModalState(false);
    return pendoService.updateAnalyticsAllowed(value)
      .pipe(
        map((user: CurrentUser) => this.setUser(user, false)!)
      );
  }

  public updateLanguage(value: string): Observable<CurrentUser> {
    return userApiService.updateLanguage(value)
      .pipe(tap((user: CurrentUser) => {
        this.setUser(user, false);
        i18n.changeLanguage(user.preferredLanguage);
      }));
  }

  public updateTimeZone(value: string): Observable<CurrentUser> {
    return userApiService.updateTimeZone(value)
      .pipe(tap((user: CurrentUser) => {
        this.setUser(user, false);
        i18n.changeLanguage(user.preferredLanguage);
      }));
  }

  private detectTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  private checkUserData(preferredLanguage?: string, timeZone?: string, isImpersonatedBy: boolean = false): void {
    if (preferredLanguage && !isImpersonatedBy) {
      i18n.changeLanguage(preferredLanguage);
    }
    const timeZoneObservable = timeZone
      ? of()
      : this.updateTimeZone(this.detectTimeZone());
    const preferredLanguageObservable = preferredLanguage
      ? of()
      : this.updateLanguage(i18n.language);
    concat(timeZoneObservable, preferredLanguageObservable)
      .subscribe(); // Serial requests is used to prevent DB simultaneously transactions conflict in user update (mydse/proliance-360-teams/team1#286)
  }

  private setUser(user: null | CurrentUser, updatePendo: boolean = true): null | CurrentUser {
    const isLogin = !this.userSubject$.value;
    this.userSubject$.next(user);
    this.isLoggedInSubject$.next(user !== null);
    if (user === null) {
      this.tokenService.resetToken();
    } else if (isLogin) {
      this.onUserLogin(user);
    } else if (!!user && !user.impersonatedBy && updatePendo) {
      pendoService.setUser(user);
    }
    this.isInitialized$.next(true);
    return user;
  }
}

export const userService = UserService.getInstance();
