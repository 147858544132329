import React, { FC, memo, ReactElement, Suspense } from 'react';
import { useRoute } from 'react-router5';
import { Router } from 'router5/dist/types/Router';
import { LoadingSuspend } from '@mydse/design-system';
import { withScroll } from '@mydse/react-ui';
import { RouteState } from '@router';
import {
  useConsentModal,
  useOpenModalParameterHandling,
  usePageTitle,
  usePermissionSubscription,
  useScrollUpdate
} from './hooks';

const Error = React.lazy(() =>
  import(/* webpackChunkName: "ErrorPage" */ '@pages/Error/ErrorPage/ErrorPage')
);

const Outlet: FC = (): ReactElement | null => {
  useConsentModal();

  const { pageTitle } = usePageTitle();

  const { router } = useRoute() as unknown as ({ router: Router; route: RouteState });

  const { route } = usePermissionSubscription({ router });

  useOpenModalParameterHandling({ route });
  useScrollUpdate({ route });

  if (!route) {
    return null;
  }
  if (route.error !== null) {
    return (
      <Suspense fallback={ <LoadingSuspend/> }>
        <Error
          { ...route.data }
          pageTitle={ pageTitle }
          errorCode={ route.error }
        />
      </Suspense>
    );
  }
  if (!route.component) {
    return null;
  }
  const Component = route.component;
  const key = route.meta.id;
  const data = {
    key,
    ...route.data,
    pageTitle
  };
  return (
    <Suspense fallback={ <LoadingSuspend/> }>
      <Component { ...data } />
    </Suspense>
  );
};

export default withScroll(memo(Outlet));
