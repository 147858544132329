import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITaskCount, TaskCountDictionary, TaskCountType } from '@services';
import { Loading } from '@mydse/design-system';
import { Tile } from '..';

import styles from './AssessmentTasks.styl';

interface IOwnProps {
  namespace: string | string[];
  assessmentTaskData: TaskCountDictionary<ITaskCount>;
  loading: boolean;
}

type Props = IOwnProps;

const AssessmentTasksWidget: FC<Props> = ({
  namespace,
  assessmentTaskData,
  loading
}) => {
  const { t } = useTranslation(namespace);


  const loadingElement = (
    <li className={styles.loading}>
      <Loading />
    </li>
  );

  const emptyElement = (
    <li className={styles.empty}>
      {t('empty')}
    </li>
  );

  const isEmpty = !Object.keys(assessmentTaskData).length;
  let sortedAssessmentTaskData = {};
  if (!isEmpty) {
    const {
      HR,
      IT,
      SALES,
      PURCHASE,
      FINANCE,
      SECURITY,
      THIRD_PARTY_PROCESSING,
      TOM
    } = assessmentTaskData;
    sortedAssessmentTaskData = {
      ...HR && { HR },
      ...IT && { IT },
      ...SALES && { SALES },
      ...PURCHASE && { PURCHASE },
      ...FINANCE && { FINANCE },
      ...SECURITY && { SECURITY },
      ...THIRD_PARTY_PROCESSING && { THIRD_PARTY_PROCESSING },
      ...TOM && { TOM }
    };
  }
  const assessmentTileList = loading
    ? loadingElement
    : isEmpty
      ? emptyElement
      : (Object.keys(sortedAssessmentTaskData) as TaskCountType[])
        .map(
          (key: TaskCountType) => (
            <Tile
              key={key}
              type={key}
              data={assessmentTaskData[key] as ITaskCount}
            />
          )
        );

  const className = loading || isEmpty
    ? styles.taskList
    : styles.tileList;
  return (
    <ul className={className}>
      {assessmentTileList}
    </ul>
  );
};

export default AssessmentTasksWidget;
