import { FunctionalArea, TranslationInfo, User } from '@mydse/typings';
import { IDictionaryResponse, TaskTypeType } from '@interfaces';
import { Observable } from 'rxjs';

export interface ISurveyUrl {
  id: string;
  type: TaskTypeType;
}

export interface ICompanyDataEntity {
  id: string;
  name: TranslationInfo;
  surveyUrl: ISurveyUrl;
}

export interface IClusterDataProcess extends ICompanyDataEntity {
  activeAssignment: boolean;
  assignee?: User;
  closedAudits: number;
  compliant: boolean;
  readonly: boolean;
  draft: boolean;
  status?: Status;
  subProcesses?: IClusterDataProcess[];
  audits?: IAuditConceptCompanyData[];
  totalAudits: number;
}

export interface ICustomProcessesClusterData {
  custom: boolean;
  processes: IClusterDataProcess[];
}
export interface IClusterData extends ICustomProcessesClusterData {
  clusterId: string;
  clusterName: TranslationInfo;
}

export type Cluster = ICustomProcessesClusterData | IClusterData;

export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
}

export interface IAuditConceptCompanyData extends ICompanyDataEntity {
  assignee: User;
  compliant: boolean;
  readonly: boolean;
  draft: boolean;
  status: Status;
  functionalArea: FunctionalArea;
  tomStatus: boolean;
  dpoAssigned: boolean;
  actions: IActionCompanyData[];
}

export interface IActionCompanyData extends ICompanyDataEntity {
  assignee: User;
  functionalArea: FunctionalArea;
  readonly: boolean;
  draft: boolean;
  dpoAssigned: boolean;
}

export interface ITomCompanyData extends ICompanyDataEntity {
  tomTopic: IDictionaryResponse<TranslationInfo>;
  closedAudits: number;
  totalAudits: number;
  compliant: boolean;
  audits: IAuditConceptCompanyData[];
}

export interface ITaskCount {
  companyOpen: number;
  companyTotal: number;
  currentUserOpen: number;
}
export type TaskCountType =
  | 'HR'
  | 'IT'
  | 'SALES'
  | 'PURCHASE'
  | 'FINANCE'
  | 'SECURITY'
  | 'THIRD_PARTY_PROCESSING'
  | 'TOM';
export type TaskCountDictionary<T> = {
  [key in TaskCountType]?: T;
};

export enum ActionChoice {
  CONFIRM = 'CONFIRM',
  SEND_BACK = 'SEND_BACK'
}

export interface ICompanyDataService {
  getProcesses: (functionalArea: FunctionalArea, showOnlyAssigned?: boolean) => Observable<null | Cluster[]>;
  getTom: (showOnlyAssigned?: boolean) => Observable<ITomCompanyData[]>;
  getCount: () => Observable<TaskCountDictionary<ITaskCount>>;
  getSelectableFunctionalArea: () => Observable<FunctionalArea[]>;
  deleteCustomProcess: (id: string) => Observable<boolean>;
  companyAction: (id: string, actionChoice: ActionChoice) => Observable<void>;
}
