import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { TranslationInfo } from '@mydse/typings';
import { IDictionaryResponse } from '@interfaces';
import { DictionaryType, IDictionaryService } from '@services';
import { dictionaryApiService, surveyApiService } from '@services/api';
import { kebabCaseToCamelCase } from '@mydse/design-system';

let dataCacheDictionary: Record<string, Array<IDictionaryResponse<TranslationInfo>>> = {};
let observableCacheDictionary: Record<string, Observable<Array<IDictionaryResponse<TranslationInfo>>>> = {};

const getData = (
  dictionaryString: string,
  isUseCache: boolean = true
): Observable<Array<IDictionaryResponse<TranslationInfo>>> => {
  const dictionaryName = dictionaryString.indexOf('-') > -1
    ? kebabCaseToCamelCase(dictionaryString) as DictionaryType
    : dictionaryString as DictionaryType;
  if (isObservableCacheExists(dictionaryName)) {
    return observableCacheDictionary[dictionaryName];
  } else if (isUseCache && isDataCacheExists(dictionaryName)) {
    return of(dataCacheDictionary[dictionaryName]);
  } else {
    const source = typeof dictionaryApiService[dictionaryName] !== 'undefined'
      ? dictionaryApiService[dictionaryName]
      : dictionaryApiService.getCustomDictionary(dictionaryString);
    observableCacheDictionary[dictionaryName] = source
      .pipe(
        tap((data: Array<IDictionaryResponse<TranslationInfo>>) => {
          delete observableCacheDictionary[dictionaryName];
          if (isUseCache) {
            dataCacheDictionary[dictionaryName] = data;
          }
        }),
        share()
      );
    return observableCacheDictionary[dictionaryName];
  }
};

const clearCache = (dictionaryName?: DictionaryType): void => {
  if (dictionaryName) {
    if (isDataCacheExists(dictionaryName)) {
      delete dataCacheDictionary[dictionaryName];
    }
    if (isObservableCacheExists(dictionaryName)) {
      delete observableCacheDictionary[dictionaryName];
    }
  } else {
    dataCacheDictionary = {};
    observableCacheDictionary = {};
  }
};

const isDataCacheExists = (dictionaryName: DictionaryType): boolean => typeof dataCacheDictionary[dictionaryName] !== 'undefined';

const isObservableCacheExists = (
  dictionaryName: DictionaryType
): boolean => typeof observableCacheDictionary[dictionaryName] !== 'undefined';

export const dictionaryService: IDictionaryService = {
  getData,
  clearCache,
  getApiData: dictionaryApiService.getApiData.bind(dictionaryApiService),
  apiRequest: dictionaryApiService.apiRequest.bind(surveyApiService)
};
