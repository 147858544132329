import React, { ComponentProps, FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { configurationService, Date, Ellipsis } from '@mydse/design-system';
import { IActivity } from '@services';
import { Locale } from '@mydse/typings';

import styles from './ActivitiesWidget.styl';

interface IOwnProps {
  data: IActivity[];
}

type Props = IOwnProps & ComponentProps<any>;

const ActivitiesWidget: FC<Props> = ({ data, ...props }): ReactElement => {
  const { t, i18n } = useTranslation('dashboard');

  if (props.demo) {
    return (
      <p className={styles.demoMessage}>{t('activitiesWidget.demo')}</p>
    );
  }

  return data && data.length ? (
    data.map((item: IActivity, index: number) => {
      const name = item.nameTranslations[i18n.language as Locale];
      const author = item.author?.name ? item.author.name : t('activitiesWidget.admin');
      const onBehalf = item.author?.onBehalf;
      const onBehalfElement = onBehalf
        ? (<span className={styles.onBehalf}>{t('activitiesWidget.onBehalf', { onBehalf })}</span>)
        : null;
      const date = item.createdAt;
      const { className, ...properties } = props;
      const classNameValue = typeof className === 'undefined'
        ? styles.activity
        : `${ styles.activity } ${ className }`;
      return (
        <div
          key={index}
          className={classNameValue}
          {...properties}
        >
          <div className={styles.activityData}>
            <div className={styles.activityUser}>{author}{onBehalfElement}</div>
            <Date
              value={date}
              format={`${ configurationService.getDateFormat() } HH:mm`}
              className={styles.activityDate}
            />
          </div>
          <Ellipsis
            className={`${ styles.activityName } 'ie-activitiesWidget'`}
            singleLine={true}
          >
            {name}
          </Ellipsis>
        </div>
      );
    })
  ) : (
    <p className={styles.activity}>{t('activitiesWidget.noData')}</p>
  );
};

export default ActivitiesWidget;
