import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { UseStateReturn } from '@mydse/design-system';
import { RadioButton } from '@mydse/design-system/v2';

interface Props {
  selectText?: string;
  localeState: UseStateReturn<string>;
}

const LocaleSelect: FC<Props> = ({
  selectText,
  localeState
}): null | ReactElement => {
  const { t } = useTranslation();

  const [ locale, setLocale ] = localeState;

  const supportedLocaleList = [ 'en', 'de' ];

  const onChangeHandler = (value: string) => (): void => {
    setLocale(value);
  };

  const localeRadioButtonList = supportedLocaleList
    .map((itemLocale: string) => (
      <RadioButton
        key={ itemLocale }
        name={ itemLocale }
        label={ t(`selectLanguage.${ itemLocale }`) }
        checked={ itemLocale === locale }
        value={ locale }
        onChange={ onChangeHandler(itemLocale) }
      />
    ));
  const text = selectText || t('selectLanguage.text');
  return (
    <>
      <p>{ text }</p>
      { localeRadioButtonList }
    </>
  );
};

export default LocaleSelect;
