import { Observable } from 'rxjs';
import { Collection } from '@mydse/typings';
import { ITableState } from '@mydse/design-system';

export enum Systems {
  BUSINESS_PREMISE = 'BUSINESS_PREMISE',
  ASSOCIATED_COMPANY = 'ASSOCIATED_COMPANY',
  WEBSITE = 'WEBSITE',
  FREELANCER = 'FREELANCER',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  PRINCIPAL = 'PRINCIPAL',
  DATA_STORAGE_SELF_HOSTED = 'DATA_STORAGE_SELF_HOSTED',
  DATA_STORAGE_THIRD_PARTY_HOSTED = 'DATA_STORAGE_THIRD_PARTY_HOSTED',
  DATA_STORAGE_CLOUD_HOSTED = 'DATA_STORAGE_CLOUD_HOSTED',
  SOFTWARE = 'SOFTWARE',
}

export type SystemsType = keyof typeof Systems;

export type SystemsStatistics = {
  [key in SystemsType]: number;
};

export interface BusinessPremise {
  id: string;
  title: null | string;
  name: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | string;
  city: null | string;
  country: null | string;
  main: boolean;
}
export interface AssociatedCompany {
  id: string;
  title: null | string;
  name: null | string;
  legalForm: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | number;
  city: null | string;
  country: null | string;
}
export interface Website {
  id: string;
  title: null | string;
  url: null | string;
  company: number;
  updatedAt: string;
}
export interface Software {
  id: string;
  title: null | string;
  name: null | string;
  manufacturer: null | string;
  updatedAt: string;
}
export interface ServiceProvider {
  id: string;
  title: null | string;
  name: null | string;
  legalForm: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | number;
  city: null | string;
  country: null | string;
  company: number;
  updatedAt: string;
}
export interface IPrincipal {
  id: string;
  title: null | string;
  name: null | string;
  legalForm: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | number;
  city: null | string;
  country: null | string;
  company: number;
  updatedAt: string;
}
export interface Freelancer {
  id: string;
  title: null | string;
  firstName: null | string;
  lastName: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | number;
  city: null | string;
  country: null | string;
}
export interface DataStorageSelfHosted {
  id: string;
  title: null | string;
  name: null | string;
  businessPremiseId: null | string;
  businessPremise: BusinessPremise;
  updatedAt: string;
}
export interface DataStorageThirdPartyHosted {
  id: string;
  title: null | string;
  name: null | string;
  serviceProviderId: null | string;
  serviceProvider: ServiceProvider;
  updatedAt: string;
}
export interface DataStorageCloudHosted {
  id: string;
  title: null | string;
  name: null | string;
  serviceProviderId: null | string;
  serviceProvider: ServiceProvider;
  updatedAt: string;
}
export type SystemsEntity =
  | BusinessPremise
  | AssociatedCompany
  | Website
  | Software
  | ServiceProvider
  | Freelancer
  | DataStorageSelfHosted
  | DataStorageThirdPartyHosted
  | DataStorageCloudHosted;

export type SystemsUrl =
  | 'business-premise'
  | 'associated-company'
  | 'website'
  | 'software'
  | 'service-provider'
  | 'principal'
  | 'freelancer'
  | 'data-storage-self-hosted'
  | 'data-storage-third-party-hosted'
  | 'data-storage-cloud-hosted';

export type SystemsApiUrl =
  | 'business_premise'
  | 'associated_company'
  | 'website'
  | 'software'
  | 'service_provider'
  | 'principal'
  | 'freelancer'
  | 'data_storage_self_hosted'
  | 'data_storage_third_party_hosted'
  | 'data_storage_cloud_hosted';

export interface ISystemsService {
  getSystemsStatistics: () => Observable<SystemsStatistics>;
  getCurrentTime: () => Observable<string>;
  getSystemsType: (value?: string) => null | Systems;
  getSystemsByType: (value: string, tableState?: Partial<ITableState<SystemsEntity>>) => Observable<null | Collection<SystemsEntity>>;
  getSystemsApiUrl: (value: string) => null | SystemsApiUrl;
  getSystemsUrl: (value: string) => null | SystemsUrl;
}
