import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router5';
import { FunctionalArea } from '@mydse/typings';
import { Case, snakeCaseToKebabCase } from '@mydse/utilities';
import { classNames, getCoordinates, getPoint } from '../utilities';
import { IAreaChartData, IAreaChartSettings } from '../AreaChart';

import styles from '../AreaChart.styl';

export enum Posiion {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
}

interface IOwnProps {
  index: number;
  item: IAreaChartData;
  settings: IAreaChartSettings;
}

type Props = IOwnProps;

const AxisLabel: FC<Props> = ({ index, item, settings }): ReactElement => {
  const { t } = useTranslation('dashboard');

  const getPosition = (itemIndex: number, total: number): Posiion => {
    if (!itemIndex) {
      return Posiion.top;
    } else if (itemIndex < total / 2) {
      return Posiion.right;
    } else if (itemIndex > total / 2) {
      return Posiion.left;
    } else {
      return Posiion.bottom;
    }
  };

  const splitLabel = (label: string): string => {
    if (label.indexOf('&') > -1) {
      return label.replace('&', ' &<br>');
    }

    const labelArray = label.split(' ');
    if (labelArray.length > 1) {
      return labelArray.reduce(
        (result: string, word: string, idx: number) => result + (idx === 1 ? '<br/>' : ' ') + word
      );
    }
    return label;
  };

  const position = getPosition(index, settings.parameters.axisCount);
  const isEmpty = item.total === 0;
  const axisValue = isEmpty ? '0' : `${ item.value }/${ item.total }`;
  const axisName =
    position === 'top' || position === 'bottom'
      ? { __html: t(`common:areaChart.${ item.label }`) }
      : { __html: splitLabel(t(`common:areaChart.${ item.label }`)) };

  let axisNameElement = <div className={styles.axisLabel} dangerouslySetInnerHTML={axisName} />;
  if (settings.axis.axisNameLink) {
    const isTom = item.label === 'TOM';
    const noParametersTypes = [ 'TOM', Object.keys(FunctionalArea)[0] ];
    const hasParameters = !noParametersTypes.includes(item.label);
    const routeName = isTom ? 'tom' : hasParameters ? 'processesTab' : 'processes';
    const urlParameters = hasParameters ? { routeTab: snakeCaseToKebabCase(item.label, Case.lower) } : {};
    const queryParameters = { switch: 'on' };
    const routeParams = { ...urlParameters, ...queryParameters };

    axisNameElement = (
      <Link
        data-link
        className={styles.axisLabel}
        routeName={routeName}
        routeParams={routeParams}
        dangerouslySetInnerHTML={axisName}
      />
    );
  }

  const className = classNames({
    [styles.axisWrap]: true,
    [styles[position]]: true,
    [styles.axisEmpty]: isEmpty
  });
  const coordinates = getCoordinates(settings.parameters.baseAngle * index, settings.parameters.axisLength);
  const width = 160;
  const height = 50;
  const padding = 10;
  const delta = {
    top: {
      x: -0.5 * width,
      y: -1 * height + padding
    },
    left: {
      x: -1 * width + padding,
      y: -1 * padding
    },
    right: {
      x: -1 * padding,
      y: -1 * padding
    },
    bottom: {
      x: -0.5 * width,
      y: -1 * padding
    }
  };

  return (
    <foreignObject
      width={width}
      height={height}
      x={getPoint(coordinates.x + delta[position].x)}
      y={getPoint(coordinates.y + delta[position].y)}
    >
      <div className={className}>
        {axisNameElement}
        <div className={styles.axisValue}>{axisValue}</div>
      </div>
    </foreignObject>
  );
};

export default AxisLabel;
