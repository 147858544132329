import React, { FC, HTMLAttributes, memo, ReactElement, useEffect, useState } from 'react';
import { classNameArrayToString } from '@mydse/design-system';

import styles from './Dots.styl';

interface IOwnProps {
  count: number;
  current: number;
  setCurrent: (value: number) => void;
  autoRotationTimeout?: number;
  titleList?: string[];
}

type Props = IOwnProps & HTMLAttributes<HTMLUListElement>;

const Dots: FC<Props> = ({
  count,
  current,
  setCurrent,
  autoRotationTimeout,
  titleList,
  ...props
}): null | ReactElement => {
  if (count < 2) {
    return null;
  }
  const { className, ...properties } = props;
  const [ isAutorotateEnabled, setIsAutorotateEnabled ] = useState(!!autoRotationTimeout);
  const classNameString = classNameArrayToString([
    styles.dots,
    isAutorotateEnabled && styles.auto,
    className
  ]);
  const onClick = (index: number) => () => {
    setCurrent(index);
    setIsAutorotateEnabled(false);
  };

  const dotList = Array.from(Array.from(Array(count).keys())
    .map((index: number) => {
      const isCurrentItem = index === current;
      const isAutoRotationItem: boolean = isAutorotateEnabled
        ? current === count - 1
          ? index === 0
          : index === current + 1
        : false;
      const dotClassNameString = classNameArrayToString([
        styles.dot,
        isCurrentItem && styles.active,
        isAutoRotationItem && styles.autoIn,
        isAutorotateEnabled && isCurrentItem && styles.autoOut
      ]);
      const title = typeof titleList !== 'undefined'
        ? titleList[index]
        : undefined;
      const style = isCurrentItem || isAutoRotationItem
        ? { animationDuration: `${ autoRotationTimeout }ms` }
        : undefined;
      return (
        <li
          key={index}
          className={dotClassNameString}
          title={title}
          style={style}
          onClick={onClick(index)}
        />
      );
    })
  );

  useEffect(
    () => {
      let timer = 0;
      if (isAutorotateEnabled) {
        const target = current === count - 1
          ? 0
          : current + 1;
        timer = window.setTimeout(() => setCurrent(target), autoRotationTimeout);
      }
      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    },
    [ current ]
  );

  return (
    <ul
      className={classNameString}
      {...properties}
    >
      {dotList}
    </ul>
  );
};

export default memo(Dots);
