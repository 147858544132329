import { BehaviorSubject, combineLatest } from 'rxjs';
import {
  ComponentPermissionData,
  FunctionalArea,
  GetMenuItemChildrenOptions,
  MenuDataType,
  MenuGroupData,
  MenuItemData,
  MenuSubItemData,
  ProductType
} from '@mydse/typings';
import { menuService } from '@mydse/react-ui';
import { kebabCaseToCamelCase } from '@mydse/utilities';
import { RouteListType } from '@router/configuration';
import {
  DataBreachMessage,
  dataBreachService,
  PagePermissionData,
  permissionService,
  productService,
  RiskCaseMessage,
  riskService,
  streamService
} from '@services';
import { getComponentPermission } from '@shared/components/ui/PermissionGuard/utilities';
import { getAvailableFunctionalAreaList } from '@pages/Processes/utilities';

import {
  faBooks,
  faBrowser,
  faChartMixed,
  faChartNetwork,
  faCogs,
  faCommentsAlt,
  faExclamationCircle,
  faFolders,
  faGraduationCap,
  faHomeAlt,
  faPoll,
  faShield,
  faUsers
} from '@fortawesome/pro-light-svg-icons';

const permission$ = new BehaviorSubject<null | PagePermissionData>(null);
permissionService.pagePermissionSubject$
  .subscribe((data: null | PagePermissionData) => permission$.next(data));

const componentPermission$ = new BehaviorSubject<null | ComponentPermissionData>(null);
permissionService.componentPermissionSubject$
  .subscribe((data: null | ComponentPermissionData) => componentPermission$.next(data));

const product$ = new BehaviorSubject<null | ProductType>(null);
productService.currentProduct$
  .subscribe((data: null | ProductType): void => {
    if (data) {
      product$.next(data);
    }
  });

const activeDataBreachCount$ = new BehaviorSubject<null | number>(null);
const activeRiskCount$ = new BehaviorSubject<null | number>(null);
permissionService.componentPermissionSubject$
  .subscribe((data: null | ComponentPermissionData) => {
    if (!!data?.dataBreach?.read && !data?.onboarding?.read) {
      dataBreachService
        .getActiveDataBreachCount()
        .subscribe((count: number) => {
          activeDataBreachCount$.next(count);
          streamService.subscribeDataBreachMessage()
            .subscribe((message: DataBreachMessage) => {
              activeDataBreachCount$.next(message.payload.count);
            });
        });
    }
    if (!!data?.risk?.read && !data?.onboarding?.read) {
      riskService
        .getActiveRiskCount()
        .subscribe((count: number) => {
          activeRiskCount$.next(count);
          streamService.subscribeRiskCaseMessage()
            .subscribe((message: RiskCaseMessage) => {
              activeRiskCount$.next(message.payload.count);
            });
        });
    }
  });

const getMenuItemChildren = (options: GetMenuItemChildrenOptions): MenuSubItemData => {
  const {
    routeName,
    parameterName = 'routeTab',
    parameterValue,
    menuItem,
    isFirst = false,
    isTab = true,
    additional = {}
  } = options;
  const routeNameValue: string = routeName instanceof Function
    ? routeName()
    : routeName;
  return isFirst
    ? {
      routeName: routeNameValue,
      translationKey: `menu.${ menuItem || routeNameValue }.${ kebabCaseToCamelCase(parameterValue) }`,
      permission: permission$.value
        ? permission$.value[routeNameValue as RouteListType]
        : false,
      isTab,
      ...additional
    }
    : {
      routeName: isTab
        ? `${ routeNameValue }Tab` as RouteListType
        : routeNameValue as RouteListType,
      translationKey: `menu.${ menuItem || routeNameValue }.${ kebabCaseToCamelCase(parameterValue) }`,
      routeParameters: { [parameterName]: parameterValue },
      permission: permission$.value
        ? permission$.value[routeNameValue as RouteListType]
        : !!permission$.value,
      isTab,
      ...additional
    };
};
const getDashboardMenuItem = (): MenuItemData => ({
  routeName: 'dashboard',
  childrenRouteList: [ 'dashboardTab' ],
  translationKey: 'menu.dashboard',
  icon: faHomeAlt,
  permission: permission$.value?.dashboard
});

const getCourseManagementMenuItem = (): MenuItemData => ({
  routeName: 'courseManagement',
  translationKey: 'menu.courseManagement',
  icon: faBooks,
  permission: permission$.value?.courseManagement
});

const getMyCoursesMenuItem = (): MenuItemData => ({
  routeName: 'myCourses',
  translationKey: 'menu.myCourses',
  icon: faGraduationCap,
  permission: permission$.value?.myCourses
});

const getAnalyticsMenuItem = (): MenuItemData => ({
  routeName: 'analytics',
  childrenRouteList: [ 'analyticsTab' ],
  translationKey: 'menu.analytics.self',
  icon: faChartMixed,
  permission: permission$.value?.analytics,
  childrenList: [
    getMenuItemChildren({ routeName: 'analytics', parameterValue: 'status', isFirst: true }),
    getMenuItemChildren({ routeName: 'analytics', parameterValue: 'activities' })
  ]
});

const getProcessesMenuItem = (): MenuItemData => {
  const availableFunctionalAreaList = getAvailableFunctionalAreaList();
  const childrenList = [
    availableFunctionalAreaList.includes(FunctionalArea.HR) && getMenuItemChildren({
      routeName: 'processes',
      parameterValue: 'hr',
      isFirst: FunctionalArea.HR === availableFunctionalAreaList[0]
    }),
    availableFunctionalAreaList.includes(FunctionalArea.IT) && getMenuItemChildren({
      routeName: 'processes',
      parameterValue: 'it',
      isFirst: FunctionalArea.IT === availableFunctionalAreaList[0]
    }),
    availableFunctionalAreaList.includes(FunctionalArea.SALES) && getMenuItemChildren({
      routeName: 'processes',
      parameterValue: 'sales',
      isFirst: FunctionalArea.SALES === availableFunctionalAreaList[0]
    }),
    availableFunctionalAreaList.includes(FunctionalArea.PURCHASE) && getMenuItemChildren({
      routeName: 'processes',
      parameterValue: 'purchase',
      isFirst: FunctionalArea.PURCHASE === availableFunctionalAreaList[0]
    }),
    availableFunctionalAreaList.includes(FunctionalArea.FINANCE) && getMenuItemChildren({
      routeName: 'processes',
      parameterValue: 'finance',
      isFirst: FunctionalArea.FINANCE === availableFunctionalAreaList[0]
    }),
    availableFunctionalAreaList.includes(FunctionalArea.SECURITY) && getMenuItemChildren({
      routeName: 'processes',
      parameterValue: 'security',
      isFirst: FunctionalArea.SECURITY === availableFunctionalAreaList[0]
    }),
    availableFunctionalAreaList.includes(FunctionalArea.THIRD_PARTY_PROCESSING) && getMenuItemChildren({
      routeName: 'processes',
      parameterValue: 'third-party-processing',
      isFirst: FunctionalArea.THIRD_PARTY_PROCESSING === availableFunctionalAreaList[0]
    })
  ]
    .filter<MenuItemData>((item: false | MenuItemData): item is MenuItemData => !!item);
  return {
    routeName: 'processes',
    translationKey: 'menu.processes.self',
    icon: faChartNetwork,
    permission: permission$.value?.processes,
    childrenList
  };
};

const getTomMenuItem = (): MenuItemData => ({
  routeName: 'tom',
  translationKey: 'menu.tom',
  icon: faShield,
  permission: permission$.value?.tom
});

const getRiskMenuItem = (): null | MenuItemData => {
  const routeName: RouteListType = 'risk';
  const riskCommonMenuItemData = {
    routeName,
    translationKey: 'menu.risk.self',
    icon: faPoll
  };

  const riskPermission = permission$.value?.risk;
  if (riskPermission) {
    return {
      ...riskCommonMenuItemData,
      childrenRouteList: [ 'riskTab', 'riskAdd', 'riskView', 'riskEdit', 'riskStatic' ],
      counter: activeRiskCount$.value,
      permission: riskPermission,
      childrenList: [
        getMenuItemChildren({ routeName: 'risk', parameterValue: 'active', isFirst: true }),
        getMenuItemChildren({ routeName: 'risk', parameterValue: 'closed' })
      ]
    };
  }

  const riskStaticPermission = permission$.value?.riskStatic;
  if (riskStaticPermission) {
    return {
      ...riskCommonMenuItemData,
      permission: riskStaticPermission
    };
  }

  return null;
};

const getDocumentMenuItem = (): MenuItemData => {
  const childrenList = [
    getComponentPermission('private', permissionService.componentPermissionSubject$.value!)?.read
    && getMenuItemChildren({
      routeName: 'document',
      parameterValue: 'documentation',
      isFirst: true
    }),
    getComponentPermission('shared', permissionService.componentPermissionSubject$.value!)?.read
    && getMenuItemChildren({
      routeName: 'document',
      parameterValue: 'shared'
    }),
    getComponentPermission('rpa', permissionService.componentPermissionSubject$.value!)?.read
    && getMenuItemChildren({
      routeName: 'document',
      parameterValue: 'rpa'
    }),
    getComponentPermission('dataBreach', permissionService.componentPermissionSubject$.value!)?.read
    && getMenuItemChildren({
      routeName: 'document',
      parameterValue: 'data-breach'
    }),
    getComponentPermission('risk', permissionService.componentPermissionSubject$.value!)?.read
    && getMenuItemChildren({
      routeName: 'document',
      parameterValue: 'risk'
    }),
    getComponentPermission('uploads', permissionService.componentPermissionSubject$.value!)?.read
    && getMenuItemChildren({
      routeName: 'document',
      parameterValue: 'uploads'
    })
  ]
    .filter<MenuItemData>((item?: false | MenuItemData): item is MenuItemData => !!item);
  return {
    routeName: 'document',
    childrenRouteList: [ 'documentTab' ],
    translationKey: 'menu.document.self',
    icon: faFolders,
    permission: permission$.value?.document,
    childrenList
  };
};

const getAssessmentGroup = (): MenuGroupData => ({
  name: 'assessment',
  group: [
    getProcessesMenuItem(),
    getTomMenuItem(),
    getRiskMenuItem(),
    getDocumentMenuItem()
  ]
    .filter<MenuItemData>((item: null | MenuItemData): item is MenuItemData => !!item)
});

const getWebsitesMenuItem = (): MenuItemData => ({
  routeName: 'websites',
  translationKey: 'menu.websites',
  icon: faBrowser,
  permission: permission$.value?.websites
});
const getWebsitesMenuGroup = (): MenuGroupData => ({
  name: 'websites',
  group: [ getWebsitesMenuItem() ]
});

const getDataSubjectRequestsMenuItem = (): MenuItemData => ({
  routeName: 'dataSubjectRequests',
  translationKey: 'menu.dataSubjectRequests',
  icon: faCommentsAlt,
  permission: permission$.value?.dataSubjectRequests
});

const getDataSubjectRequestsMenuGroup = (): MenuGroupData => ({
  name: 'dataSubjectRequests',
  group: [ getDataSubjectRequestsMenuItem() ]
});

const getDataBreachMenuItem = (): null | MenuItemData => {
  const routeName: RouteListType = 'dataBreach';
  const dataBreachCommonMenuItemData = {
    routeName,
    translationKey: 'menu.dataBreach.self',
    icon: faExclamationCircle
  };

  const dataBreachPermission = permission$.value?.dataBreach;
  if (dataBreachPermission) {
    return {
      ...dataBreachCommonMenuItemData,
      childrenRouteList: [ 'dataBreachAdd', 'dataBreachView', 'dataBreachEdit', 'dataBreachStaticDefault', 'dataBreachStaticCustom' ],
      counter: activeDataBreachCount$.value,
      permission: dataBreachPermission,
      childrenList: [
        getMenuItemChildren({ routeName: 'dataBreach', parameterValue: 'active', isFirst: true }),
        getMenuItemChildren({ routeName: 'dataBreach', parameterValue: 'archive' })
      ]
    };
  }

  const dataBreachStaticCustomPermission = permission$.value?.dataBreachStaticCustom;
  const dataBreachStaticDefaultPermission = permission$.value?.dataBreachStaticDefault;
  const dataBreachStaticPermission = dataBreachStaticCustomPermission || dataBreachStaticDefaultPermission;
  if (dataBreachStaticPermission) {
    return {
      ...dataBreachCommonMenuItemData,
      permission: dataBreachStaticPermission
    };
  }

  return null;
};
const getDataBreachMenuGroup = (): null | MenuGroupData => {
  const dataBreachMenuItem = getDataBreachMenuItem();
  return dataBreachMenuItem
    ? {
      name: 'dataBreach',
      group: [ dataBreachMenuItem ]
    }
    : null;
};

const getTeamMenuItem = (): MenuItemData => permission$.value?.teamDetails
  ? {
    routeName: 'team',
    childrenRouteList: [ 'dpoNomination', 'teamDetails' ],
    translationKey: 'menu.team.self',
    icon: faUsers,
    permission: permission$.value?.team,
    childrenList: [
      getMenuItemChildren({
        routeName: 'teamDetails',
        parameterName: 'teamArea',
        parameterValue: 'third-party-processing',
        menuItem: 'team',
        isTab: false
      }),
      getMenuItemChildren({
        routeName: componentPermission$.value?.dpoTeam?.read
          ? 'teamDetails'
          : 'dpoNomination',
        parameterName: componentPermission$.value?.dpoTeam?.read
          ? 'teamArea'
          : undefined,
        parameterValue: 'dpo',
        menuItem: 'team',
        isTab: false,
        additional: { childrenRouteList: [ 'dpoNomination' ] }
      }),
      getMenuItemChildren({
        routeName: 'teamDetails',
        parameterName: 'teamArea',
        parameterValue: 'hr',
        menuItem: 'team',
        isTab: false
      }),
      getMenuItemChildren({
        routeName: 'teamDetails',
        parameterName: 'teamArea',
        parameterValue: 'it',
        menuItem: 'team',
        isTab: false
      }),
      getMenuItemChildren({
        routeName: 'teamDetails',
        parameterName: 'teamArea',
        parameterValue: 'sales',
        menuItem: 'team',
        isTab: false
      }),
      getMenuItemChildren({
        routeName: 'teamDetails',
        parameterName: 'teamArea',
        parameterValue: 'purchase',
        menuItem: 'team',
        isTab: false
      }),
      getMenuItemChildren({
        routeName: 'teamDetails',
        parameterName: 'teamArea',
        parameterValue: 'finance',
        menuItem: 'team',
        isTab: false
      }),
      getMenuItemChildren({
        routeName: 'teamDetails',
        parameterName: 'teamArea',
        parameterValue: 'security',
        menuItem: 'team',
        isTab: false
      })
    ]
  }
  : {
    routeName: 'team',
    translationKey: 'menu.team.self',
    icon: faUsers,
    permission: permission$.value?.team
  };

const getSystemsMenuItem = (): MenuItemData => ({
  routeName: 'systems',
  childrenRouteList: [ 'systemsList', 'systemsAdd', 'systemsDetails' ],
  translationKey: 'menu.systems.self',
  icon: faCogs,
  permission: permission$.value?.systems,
  childrenList: [
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'business-premise',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'associated-company',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'website',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'freelancer',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'service-provider',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'principal',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'data-storage-self-hosted',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'data-storage-third-party-hosted',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'data-storage-cloud-hosted',
      menuItem: 'systems',
      isTab: false
    }),
    getMenuItemChildren({
      routeName: 'systemsList',
      parameterName: 'url',
      parameterValue: 'software',
      menuItem: 'systems',
      isTab: false
    })
  ]
});

const getAssetManagementMenuItem = (): MenuItemData => ({
  routeName: 'assetManagement',
  childrenRouteList: [ 'assetManagementTab' ],
  translationKey: 'menu.assetManagement.self',
  icon: faCogs,
  permission: permission$.value?.assetManagement,
  childrenList: [
    getMenuItemChildren({ routeName: 'assetManagement', parameterValue: 'software', isFirst: true }),
    getMenuItemChildren({ routeName: 'assetManagement', parameterValue: 'third-parties' }),
    getMenuItemChildren({ routeName: 'assetManagement', parameterValue: 'data-storages' })
  ]
});

const getTeamSystemsGroup = (): MenuGroupData => ({
  name: 'teamSystems',
  group: [
    getTeamMenuItem(),
    getSystemsMenuItem(),
    getAssetManagementMenuItem()
  ]
});

combineLatest([ product$, permission$, activeDataBreachCount$, activeRiskCount$ ])
  .subscribe(([ product ]) => {
    if (product === 'academy') {
      return menuService.menu$.next([
        getDashboardMenuItem(),
        getCourseManagementMenuItem(),
        getMyCoursesMenuItem()
      ]);
    }
    if (product === 'privacy') {
      return menuService.menu$
        .next(
          [
            getDashboardMenuItem(),
            getAnalyticsMenuItem(),
            getAssessmentGroup(),
            getWebsitesMenuGroup(),
            getDataSubjectRequestsMenuGroup(),
            getDataBreachMenuGroup(),
            getTeamSystemsGroup()
          ]
            .filter<MenuDataType>((item: null | MenuDataType): item is MenuDataType => !!item)
        );
    }
    menuService.menu$.next([]);
  });
