import React, { Dispatch, FC, memo, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { take } from 'rxjs/operators';
import { DataFormat, Options } from 'select2';
import { Feature, Locale } from '@mydse/typings';
import {
  Button,
  Dropdown,
  getSorter,
  loadingIndicatorService,
  SortDirection
} from '@mydse/design-system';
import { Textarea } from '@mydse/design-system/v2';
import {
  FileUpload,
  modalHandler,
  modalService
} from '@mydse/react-ui';
import { snakeCaseToCamelCase } from '@mydse/utilities';
import { ErrorFactory } from '@shared/form';
import { CustomerRequestFormData, customerRequestService, permissionService } from '@services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

import { schema } from './schema';
import styles from './CustomerRequestModalContent.styl';

interface IOwnProps {
  id: string;
  setForm: Dispatch<SetStateAction<null | HTMLFormElement>>;
}

type Props = IOwnProps;

const CustomerRequestModalContent: FC<Props> = ({
  id,
  setForm
}): null | ReactElement => {
  const { i18n, t } = useTranslation('customerRequest');
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    watch
  } = useForm<CustomerRequestFormData>({
    resolver: yupResolver(schema)
  });

  const sorter = getSorter({
    language: i18n.language as Locale,
    sortDirection: SortDirection.none
  });

  const getSettings = (): Options => ({
    placeholder: t('input.requestType.placeholder'),
    minimumInputLength: 0,
    minimumResultsForSearch: Infinity,
    dropdownAutoWidth: false,
    allowClear: false,
    multiple: false,
    sorter
  });

  const [ dropdownSettings, setDropdownSettings ] = useState<Options>(getSettings());

  const getDropdownData = (type: string, valueList: string[]): DataFormat[] => {
    return valueList
      .map((value: string) => ({
        id: value,
        text: t(`input.${ type }.options.${ snakeCaseToCamelCase(value) }`)
      }));
  };

  const requestTypeValueList: string[] = [
    'GENERAL_FEEDBACK_OR_SUGGESTIONS',
    'TECHNICAL_ISSUE',
    'OTHER'
  ];
  if (!!permissionService.permissionDataSubject$.value?.[Feature.CONSULTING_REQUEST]?.write) {
    requestTypeValueList.unshift('CONSULTING_REQUEST');
  }
  const [ requestTypeData, setRequestTypeData ] = useState<DataFormat[]>(getDropdownData('requestType', requestTypeValueList));

  const { onChange: requestTypeOnChange, onBlur: requestTypeOnBlur, name: requestTypeName } = register('type');
  const requestTypeDropdown = (
    <div className="inputWrap">
      <Dropdown
        name={ requestTypeName }
        label={ t('input.requestType.label') }
        settings={ dropdownSettings }
        data={ requestTypeData }
        value={ getValues('type') }
        onBlur={ requestTypeOnBlur }
        onChange={ requestTypeOnChange }
        useFormGroup={ false }
        dataAttributesDictionary={ {
          test: { business: 'requestType' },
          guide: { business: 'requestType' }
        } }
      />
      <ErrorMessage
        errors={ errors }
        name="type"
        render={ ErrorFactory(t, t('input.requestType.label')) }
      />
    </div>
  );

  const requestTopicValueList: string[] = [
    'CONTRACT_REVIEW',
    'PRIVACY_POLICY',
    'DATA_SUBJECT_REQUEST',
    'CONSENT',
    'TRANSFER_TO_THIRD_COUNTRIES',
    'TOM',
    'RECORD_OF_PROCESSING_ACTIVITIES',
    'WEBSITE',
    'VIDEO_SURVEILLANCE',
    'OTHER'
  ];
  const [ requestTopicData, setRequestTopicData ] = useState<DataFormat[]>(getDropdownData('requestTopic', requestTopicValueList));

  const { onChange: requestTopicOnChange, onBlur: requestTopicOnBlur, name: requestTopicName } = register('topic');
  const requestType = watch('type');
  const requestTopicDropdown = requestType === 'CONSULTING_REQUEST'
    ? (
      <div className="inputWrap">
        <Dropdown
          name={ requestTopicName }
          label={ t('input.requestTopic.label') }
          settings={ dropdownSettings }
          data={ requestTopicData }
          value={ getValues('topic') }
          onBlur={ requestTopicOnBlur }
          onChange={ requestTopicOnChange }
          useFormGroup={ false }
          dataAttributesDictionary={ {
            test: { business: 'requestTopic' },
            guide: { business: 'requestTopic' }
          } }
        />
        <ErrorMessage
          errors={ errors }
          name="topic"
          render={ ErrorFactory(t, t('input.requestTopic.name')) }
        />
      </div>
    )
    : null;

  useEffect(
    () => {
      setDropdownSettings(getSettings());
      setRequestTypeData(getDropdownData('requestType', requestTypeValueList));
      setRequestTopicData(getDropdownData('requestTopic', requestTopicValueList));
    },
    [ i18n.language ]
  );

  const text = !!requestType && i18n.exists(`customerRequest:text.${ snakeCaseToCamelCase(requestType) }`)
    ? <p>{ t(`text.${ snakeCaseToCamelCase(requestType) }`) }</p>
    : null;

  const messageTextarea = (
    <div className="inputWrap">
      <Textarea
        autoGrow={ true }
        slots={ [] }
        label={ t('input.message.label') }
        placeholder={ t('input.message.placeholder') }
        { ...register('message') }
      />
      <ErrorMessage
        errors={ errors }
        name="message"
        render={ ErrorFactory(t, t('input.message.label')) }
      />
    </div>
  );

  const uploadText = i18n.exists('customerRequest:input.upload.text')
    ? <p>{ t('input.upload.text') }</p>
    : null;

  const acceptedTypes = [ 'JP(E)G', 'PNG', 'WEBP', 'TXT', 'PDF', 'DOC(X)' ];
  const fileUploadError = errors.attachments
    ? t(`designSystem:fileUpload.errors.${ errors.attachments.message }`, { maxSize: 20 })
    : undefined;
  const fileUpload = (
    <div className={ `column ${ styles.fileUpload }` }>
      <FileUpload
        acceptedTypes={ acceptedTypes }
        multiple={ true }
        maxSize={ 20 }
        filterInvalidFiles={ false }
        error={ fileUploadError }
        { ...register('attachments') }
      />
    </div>
  );

  const showSuccess = () => {
    modalService
      .patchModalOptions({
        id,
        size: 'medium',
        content: {
          contentElement: (
            <div className={ styles.success }>
              <FontAwesomeIcon className={ styles.icon } icon={ faCheckCircle }/>
              <p className={ styles.successMessage }>{ t('message.success') }</p>
              <Button
                dataAttributesDictionary={ {
                  test: { 'customer-request': 'close' },
                  guide: { 'customer-request': 'close' }
                } }
                preset="primary"
                text={ t('button.close') }
                onClick={ modalHandler() }
              />
            </div>
          )
        },
        footer: undefined
      });
  };

  const onSubmit = (formData: CustomerRequestFormData): void => {
    loadingIndicatorService.show();
    customerRequestService.sendCustomerRequest({
      url: window.location.href,
      ...formData
    })
      .pipe(take(1))
      .subscribe((response: Response): void => {
        loadingIndicatorService.hide();
        if (response.ok) {
          showSuccess();
        }
      });
  };

  const onError = (error: FieldErrors<CustomerRequestFormData>): void => {
    console.error('error', error);
  };

  return (
    <form
      ref={ setForm }
      className={ `form ${ styles.form }` }
      onSubmit={ handleSubmit(onSubmit, onError) }
    >
      { requestTypeDropdown }
      { requestTopicDropdown }
      { text }
      { messageTextarea }
      { uploadText }
      { fileUpload }
    </form>
  );
};

export default memo(CustomerRequestModalContent);
