import { Observable } from 'rxjs';
import { Api } from '@services/api';
import { Collection, CollectionParameters } from '@mydse/typings';
import { DataBreachType, DataBreach, ISurveyData } from '@services';

const apiUrl = '/api/survey/data-breach';

class DataBreachApiService extends Api {
  public getDataBreachCollection(parameters: CollectionParameters): Observable<Collection<DataBreach>> {
    return this.get<Collection<DataBreach>>({ parameters });
  }

  public updateDataBreachStatus(id: string, status: DataBreachType): Observable<Observable<ISurveyData>> {
    const parameters = { status };
    return this.patch<Observable<ISurveyData>>({
      url: id,
      parameters
    });
  }

  public deleteDataBreach(id: string): Observable<void> {
    return this.delete({ url: id });
  }

  public getActiveDataBreachCount(): Observable<number> {
    return this.get<number>({ url: 'count' });
  }
}

export const dataBreachApiService = new DataBreachApiService(apiUrl);
